import { SET_LINKING_TARGET_TYPE } from './types';

// Define a type for the linking target type
export type LinkingTargetType = string;

// Define the action creator function with proper types
export const setLinkingTargetType = (linkingTargetType: LinkingTargetType) => ({
  type: SET_LINKING_TARGET_TYPE,
  payload: linkingTargetType,
});
