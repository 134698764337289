import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  Box,
  Pagination,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import theme from "../../../../theme";
import { ArticleReport } from "../../../../interfaces/core/ArticleReport";
import { getArticlesReport } from "../../../../services/core-api";

interface Column {
  id: "articleNumber" | "manufacturer" | "orderedNumber" | "visited";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "articleNumber", label: "Article Number", minWidth: 170 },
  { id: "manufacturer", label: "Manufacturer", minWidth: 100 },
  {
    id: "orderedNumber",
    label: "Ordered Number",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "visited",
    label: "Visited",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

const ProductsListReport = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 30;
  const [count, setCount] = React.useState(0);
  const [articlesRport, setArticlesReport] = React.useState<ArticleReport[] | []>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(
    null
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(
    null
  );

  const fetchArticlessReport = React.useCallback(async () => {
    try {
      const options = {
        keyword: searchValue,
        from_date: selectedFromDate
          ? selectedFromDate.format("YYYY-MM-DD")
          : "",
        to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "",
        page_number: page
      };
      const response = await getArticlesReport(options);
      if(response) {
        setArticlesReport(response.articles_report);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setArticlesReport([]);
      setPage(1);
      setCount(0);
    }
  }, [page, rowsPerPage, searchValue, selectedFromDate, selectedToDate]);

  React.useEffect(() => {
    fetchArticlessReport(); // Only call fetchUsers without checking any conditions
  }, [fetchArticlessReport]); // Only re-run when fetchUsers changes


  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
    setPage(1); // Reset to first page on date change
  };


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const handleExportExcel = () => {
    const url = `${baseURL}/export-articles-report`;
    window.open(url);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                display: "inline-flex",
                float: "left",
                alignItems: "center",
                fontSize: "18px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: theme.palette.text.primary,
                mb: 3,
              }}
            >
              Articles Search Report
              <Tooltip title="This report is generated by directly searching articles.
If the article has been visited and not ordered, there may be several reasons why it was not ordered. The part was not available, the price did not suit them, or any third reason.">
                <InfoIcon sx={{ marginLeft: 1 }} />
              </Tooltip>
            </Typography>
            <Button 
              variant="contained"
              sx={{
                float: {
                  xs: "none", 
                  md: "right",
                },
              }}
              onClick={handleExportExcel}
            >
              Export report
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="outlined-basic"
              placeholder="Search Products..."
              variant="outlined"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%", md: "20rem" },
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              InputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: "#d5d5d5 !important", fontSize: "17px" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <DatePicker
                format="MM/DD/YYYY"
                value={selectedFromDate}
                onChange={handleFromDateChange}
                sx={{
                  flex: 1,
                  bgcolor: "#fff",
                  mr: 1,
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    borderRadius: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                  },
                  "& .MuiInputBase-input": {
                    height: "30px",
                    padding: "0 14px",
                    fontSize: "13px",
                    fontFamily: "Inter",
                  },
                }}
              />
              <DatePicker
                format="MM/DD/YYYY"
                value={selectedToDate}
                onChange={handleToDateChange}
                sx={{
                  flex: 1,
                  bgcolor: "#fff",
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    borderRadius: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                  },
                  "& .MuiInputBase-input": {
                    height: "30px",
                    padding: "0 14px",
                    fontSize: "13px",
                    fontFamily: "Inter",
                  },
                }}
                defaultValue={dayjs("2024-06-12")}
              />
            </Box>
          </Grid>
        </Grid>
        
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer
            sx={{ maxHeight: 440, border: "1px solid #f1f1f1", p: 2 }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {articlesRport.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                       
                            <TableCell key={row.id} align={"left"}>
                              {row.articleNumber}
                            </TableCell>

                            <TableCell  align={"left"}>
                              {row.mfrName}
                            </TableCell>
                            <TableCell  align={"right"}>
                              {row.ordered_number}
                            </TableCell>
                            <TableCell  align={"right"}>
                              {row.visited}
                            </TableCell>
                         
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 1 }}>
            <Pagination page={page} count={count} variant="outlined" shape="rounded" onChange={handlePageChange} />
          </Box>
        </Stack>
      </LocalizationProvider>
    </>
  );
};
export default ProductsListReport;
