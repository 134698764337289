import * as React from "react";
import {
   alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from 'axios';
import theme from "../../theme";
import { Manufacturer } from "../../interfaces/Manufacturer";
import { ManufacturerModel } from "../../interfaces/ManufacturerModels";
import { VehicleDetail } from "../../interfaces/VehicleDetail";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchManufacturers,
  fetchModels,
  getLinkageTargets,
} from "../../services/tecdocService";
import { useSelector } from "react-redux";
import { LinkingTargetState } from "../../store/reducers/linkingTargetReducer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OfferItem } from "../../interfaces/core/OfferItem";
import { Offer } from "../../interfaces/core/Offer";
import { createOffer, deleteOfferItems, getOfferItems } from "../../services/core-api";
import Alert from '@mui/material/Alert';


interface Data {
   id: number;
   articleNumber: string;
   description: string;
   manufacturer: string;
   quantity: number;
   price: number;
   total: number;
 }
 
 type Order = "asc" | "desc";

 interface HeadCell {
   disablePadding: boolean;
   id: keyof Data;
   label: string;
   numeric: boolean;
 }
 
 const headCells: readonly HeadCell[] = [
   {
     id: "articleNumber",
     numeric: false,
     disablePadding: true,
     label: "Article Number",
   },
   {
    id: "manufacturer",
    numeric: false,
    disablePadding: false,
    label: "Manufacturer",
  },
   {
     id: "description",
     numeric: false,
     disablePadding: false,
     label: "Description",
   },
   {
     id: "quantity",
     numeric: false,
     disablePadding: false,
     label: "Quantity",
   },
   {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
    },
    {
       id: "total",
       numeric: false,
       disablePadding: false,
       label: "Total",
     }
 ];
 
 interface EnhancedTableProps {
   numSelected: number;
   onRequestSort: (
     event: React.MouseEvent<unknown>,
     property: keyof Data
   ) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
 }
 
 function EnhancedTableHead(props: EnhancedTableProps) {
 
   return (
     <TableHead sx={{bgcolor:"#f1f1f1"}}>
       <TableRow>
         <TableCell padding="checkbox"></TableCell>
         {headCells.map((headCell) => (
           <TableCell
             key={headCell.id}
             align={headCell.numeric ? "right" : "left"}
             sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 600, pb: '4px'}}
           >
             {headCell.label}
           </TableCell>
         ))}
       </TableRow>
     </TableHead>
   );
 }
 
 interface EnhancedTableToolbarProps {
   numSelected: number;
   offerItemsId: number[]
 }

 const handleDeleteOfferItems = async (offerItemsId: any) => {
  try {
    await deleteOfferItems(offerItemsId);
    window.location.reload();
  } catch (error) {
    console.log(error)
  }
 }
 
 function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
   const { numSelected, offerItemsId } = props;
 
   return (
     <Toolbar
       sx={{
         minHeight: "40px !important",
         pl: { sm: 0.5 },
         pr: { xs: 1, sm: 1 },
         ...(numSelected > 0 && {
           bgcolor: (theme) =>
             alpha(
               theme.palette.primary.main,
               theme.palette.action.activatedOpacity
             ),
         }),
       }}
     >
       {numSelected > 0 ? (
         <>
           <Tooltip title="Delete">
             <IconButton
              onClick={() => handleDeleteOfferItems(offerItemsId)}
             >
               <DeleteIcon />
             </IconButton>
           </Tooltip>
           <Typography
             sx={{ fontSize: "13px", fontFamily: "Inter" }}
             color="inherit"
             variant="subtitle1"
             component="div"
           >
             Delete {numSelected} items selected
           </Typography>
         </>
       ) : (
         <></>
       )}
     </Toolbar>
   );
 }

const CreateOffer = () => {
   const linkingTargetType = useSelector((state: { linkingTarget: LinkingTargetState}) => state.linkingTarget.linkingTargetType);
  const [openMarker, setOpenMarker] = React.useState(false);

  const [fullName, setFullName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [vin, setVin] = React.useState('');
  const [comment, setComment] = React.useState('');

  const [manufacturers, setManufacturers] = React.useState<
    readonly Manufacturer[]
  >([]);
  const [selectedManufacturer, setSelectedManufacturer] =
    React.useState<Manufacturer | null>(null);
  const loadingMarker = openMarker && manufacturers.length === 0;
  const [errorManufacturer, setErrorManufacturer] = React.useState<
    string | null
  >(null);

  const [manufacturerModels, setManufacturerModels] = React.useState<
    ManufacturerModel[]
  >([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [isLoadingModel, setIsLoadingModel] = React.useState(false);
  const [selectedManufacturerModel, setSelectedManufacturerModel] =
    React.useState<ManufacturerModel | null>(null);
  const loadingModel = openModel && manufacturerModels.length === 0;
  const modelInputRef = React.useRef<HTMLInputElement>(null);

  const [openMotor, setOpenMotor] = React.useState(false);
  const [motors, setMotors] = React.useState<VehicleDetail[]>([]);
  const [selectedMotor, setSelectedMotor] =
    React.useState<VehicleDetail | null>(null);
  const [isLoadingCar, setIsLoadingCar] = React.useState(false);
  const loadingMotor = openMotor && motors.length === 0;
  const motorInputRef = React.useRef<HTMLInputElement>(null);

  const [offerItems, setOfferItems] = React.useState<OfferItem[] | null>([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const [offerItemsId, setOfferItemsId] = React.useState<number[]>([]);
  const [offerError, setOfferError] = React.useState('');
  const [totalPrice, setTotalPrice] = React.useState(0);

  const [order, setOrder] = React.useState<Order>("asc");
   const [orderBy, setOrderBy] = React.useState<keyof Data>("articleNumber");
   const [selected, setSelected] = React.useState<readonly number[]>([]);

  // Autocomplete for Manufacture
  React.useEffect(() => {
    let activeMarker = true;

    if (!loadingMarker) {
      return undefined;
    }

    (async () => {
      try {
        if (activeMarker) {
          const data = await fetchManufacturers(linkingTargetType);
          setManufacturers([...data]);
          setErrorManufacturer(null);
        }
      } catch (error) {
        console.error("Error fetching manufacturers:", error);
        setErrorManufacturer(
          "Not able to fetch manufacturers. Please try again."
        );
      }
    })();

    return () => {
      activeMarker = false;
    };
  }, [linkingTargetType, loadingMarker, manufacturers]);

  React.useEffect(() => {
    if (!openMarker) {
      setManufacturers([]);
    }
  }, [openMarker]);

  const handleFullNameChange = (event: any) => {
    setFullName(event.target.value);
  };

  const handleAddressChange = (event: any) => {
    setAddress(event.target.value);
  };

  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };

  const handleVinChange = (event: any) => {
    setVin(event.target.value);
  };

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  // Inside the Autocomplete component for Manufacturer
  const handleManufacturerChange = async (
    event: React.ChangeEvent<{}>,
    manufacturer: Manufacturer | null
  ) => {
    setSelectedManufacturer(manufacturer);
    setOpenModel(true);

    if (manufacturer) {
      try {
        setIsLoadingModel(true);
        const models = await fetchModels(
          manufacturer.manuId,
          linkingTargetType
        );
        setManufacturerModels(models);
      } catch (error) {
        console.error("Error fetching models:", error);
      } finally {
        setIsLoadingModel(false);
      }
    }
  };

  const handleModelChange = async (
    event: React.ChangeEvent<{}>,
    model: ManufacturerModel | null
  ) => {
    setSelectedManufacturerModel(model);
    setOpenMotor(true);

    if (model && selectedManufacturer) {
      try {
        const manufacturerId = selectedManufacturer.manuId;
        setIsLoadingCar(true);
        const motors = await getLinkageTargets(
          manufacturerId,
          model.modelId,
          linkingTargetType
        );
        setMotors(motors);
      } catch (error) {
      } finally {
        setIsLoadingCar(false);
      }
    }
  };

  const handleMotorChange = async (
    event: React.ChangeEvent<{}>,
    motor: VehicleDetail | null
  ) => {
    event.stopPropagation();
    setSelectedMotor(motor);
  };

  // Add useEffect hook to focus on the model input when a manufacturer is selected
  React.useEffect(() => {
    if (selectedManufacturer && modelInputRef.current) {
      modelInputRef.current.focus();
    }

    if (selectedManufacturerModel && motorInputRef.current) {
      motorInputRef.current.focus();
    }
  }, [selectedManufacturer, selectedManufacturerModel]);

  const handleSubmitCarData = async () => {
    setIsLoadingCar(true);

    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const user_id = parsedUser.id;

      try {
        const offer: Offer = {
          user_id: user_id,
          full_name: fullName,
          address: address,
          phone: phone,
          mfrName: selectedManufacturer? selectedManufacturer.manuName: '',
          model: selectedManufacturerModel? selectedManufacturerModel.modelname: '',
          motor: selectedMotor? selectedMotor.description + " " + selectedMotor.kiloWattsFrom +" kW / " + selectedMotor.horsePowerFrom +" hp " + selectedMotor.beginYearMonth +" " +
          selectedMotor.endYearMonth : '',
          vin: vin,
          comment: comment,
          total: totalPrice,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }
        await createOffer(offer);
        window.location.reload();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data && error.response.data.detail) {
            setOfferError(error.response.data.detail);
          } else {
            setOfferError('An unexpected error occurred.');
          }
        } else {
          setOfferError('An unexpected error occurred.');
        }
      }
    }

  };

  const handleRequestSort = (
   event: React.MouseEvent<unknown>,
   property: keyof Data
 ) => {
   const isAsc = orderBy === property && order === "asc";
   setOrder(isAsc ? "desc" : "asc");
   setOrderBy(property);
 };

 const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
   if (event.target.checked && offerItems) {
     const newSelected = offerItems.map((n) => n.id);
     setSelected(newSelected);
     return;
   }
   setSelected([]);
 };

 const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
   const selectedIndex = selected.indexOf(id);
   let newSelected: number[] = [];

   if (selectedIndex === -1) {
     newSelected = newSelected.concat(selected, id);
   } else if (selectedIndex === 0) {
     newSelected = newSelected.concat(selected.slice(1));
   } else if (selectedIndex === selected.length - 1) {
     newSelected = newSelected.concat(selected.slice(0, -1));
   } else if (selectedIndex > 0) {
     newSelected = newSelected.concat(
       selected.slice(0, selectedIndex),
       selected.slice(selectedIndex + 1)
     );
   }
   setOfferItemsId(newSelected)
   setSelected(newSelected);
 };

 React.useEffect(() => {
  let activeOfferItems = true;
  if (activeOfferItems) {
    (async () => {
      try {
        const offerItemsResponse = await getOfferItems();
        if (offerItemsResponse) {
          setOfferItems(offerItemsResponse.items);
          setTotalItems(offerItemsResponse.total);
          setTotalPrice(offerItemsResponse.total_price);
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }
 },[])

 const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Accordion sx={{boxShadow: "none", border: "1px solid #f1f1f1"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{bgcolor: "#f1f1f1"}}
        >
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: "15px",
              fontFamily: "Inter",
              fontWeight: 500,
              textAlign: "left",
            }}
          >
            New Offer
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Full Name"
                    variant="outlined"
                    value={fullName}
                    onChange={handleFullNameChange}
                    sx={{
                      width: "100%",
                      background: "#fff",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove the bottom border before hover
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove the bottom border after clicking
                      },
                    }}
                    InputProps={{
                      sx: {
                        textAlign: "left",
                        height: "36px",
                        fontSize: "13px",
                        borderRadius: 0,
                        fontFamily: "Inter",
                        paddingLeft: ".3rem",
                        width: "100%",
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none", // Remove the bottom border on hover
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none", // Remove the bottom border after clicking
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Address"
                    variant="outlined"
                    value={address}
                    onChange={handleAddressChange}
                    sx={{
                      width: "100%",
                      background: "#fff",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove the bottom border before hover
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove the bottom border after clicking
                      },
                    }}
                    InputProps={{
                      sx: {
                        textAlign: "left",
                        height: "36px",
                        fontSize: "13px",
                        borderRadius: 0,
                        fontFamily: "Inter",
                        paddingLeft: ".3rem",
                        width: "100%",
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none", // Remove the bottom border on hover
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none", // Remove the bottom border after clicking
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    required
                    placeholder="Phone no"
                    variant="outlined"
                    value={phone}
                    onChange={handlePhoneChange}
                    sx={{
                      width: "100%",
                      background: "#fff",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove the bottom border before hover
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove the bottom border after clicking
                      },
                    }}
                    InputProps={{
                      sx: {
                        textAlign: "left",
                        borderRadius: 0,
                        height: "36px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        paddingLeft: ".3rem",
                        width: "100%",
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none", // Remove the bottom border on hover
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none", // Remove the bottom border after clicking
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="marker"
                    open={openMarker}
                    onOpen={() => {
                      setOpenMarker(true);
                    }}
                    onClose={() => {
                      setOpenMarker(false);
                    }}
                    ListboxProps={{
                      style: { maxHeight: 200, overflowY: "auto" },
                    }}
                    PaperComponent={(props) => {
                      const anchorEl = document.getElementById("marker");
                      const width = anchorEl
                        ? `${anchorEl.offsetWidth + 45}px`
                        : "auto";

                      return (
                        <Popper
                          {...props}
                          open={openMarker}
                          anchorEl={anchorEl}
                          placement="bottom-start"
                          modifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [-7, -10], // Adjust this value to move the Popper up or down
                              },
                            },
                          ]}
                          style={{
                            zIndex: 9999,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            border: "1px solid #f1f1f1",
                            boxShadow: "2px 3px 3px #ccc",
                            position: "relative",
                            background: "#fff",
                            width: width,
                          }}
                        />
                      );
                    }}
                    value={selectedManufacturer}
                    isOptionEqualToValue={(option, value) =>
                      option.manuName === value.manuName
                    }
                    getOptionLabel={(option) => option.manuName}
                    options={manufacturers}
                    loading={loadingMarker}
                    sx={{
                      width: "100%",
                      height: "36px",
                      backgroundColor: "#fff",
                      ".MuiInputBase-input": {
                        height: "36px",
                        marginTop: "-5px",
                        marginBottom: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                    onChange={handleManufacturerChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Manufacturer"
                        autoFocus
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          sx: {
                            color: theme.palette.text.primary,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            height: "100%",
                            top: "-6px",
                            transform:
                              "translate(24px, 12px), scale(1) !important",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: 0, // Adjust the height as needed
                            height: "36px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                          endAdornment: (
                            <React.Fragment>
                              {loadingMarker ? (
                                <CircularProgress
                                  color="inherit"
                                  size={13}
                                  sx={{ marginTop: "-8px" }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="model"
                    open={openModel && !!selectedManufacturer}
                    onOpen={() => {
                      setOpenModel(true);
                    }}
                    onClose={() => {
                      setOpenModel(false);
                    }}
                    ListboxProps={{
                      style: { maxHeight: 200, overflowY: "auto" },
                    }}
                    PaperComponent={(props) => {
                      const anchorEl = document.getElementById("model");
                      const width = anchorEl
                        ? `${anchorEl.offsetWidth + 45}px`
                        : "auto";

                      return (
                        <Popper
                          {...props}
                          open={openModel}
                          anchorEl={anchorEl}
                          placement="bottom-start"
                          modifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [-7, -10], // Adjust this value to move the Popper up or down
                              },
                            },
                          ]}
                          style={{
                            zIndex: 9,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            border: "1px solid #f1f1f1",
                            boxShadow: "2px 3px 3px #ccc",
                            position: "relative",
                            background: "#fff",
                            width: width,
                          }}
                        />
                      );
                    }}
                    value={selectedManufacturerModel}
                    isOptionEqualToValue={(option, value) =>
                      option.modelname === value.modelname
                    }
                    renderOption={(props, option) => {
                      const fromYear = option.yearOfConstrFrom
                        ? `${option.yearOfConstrFrom}`.slice(4) + `.` + `${option.yearOfConstrFrom}`.slice(0, 4)
                        : "";
                      const toYear = option.yearOfConstrTo
                        ? `${option.yearOfConstrTo}`.slice(4) + `.` + `${option.yearOfConstrTo}`.slice(0, 4)
                        : "";
                    
                      // Do not destructure `key` from `props`
                      return (
                        <li
                          {...props}  // Spread all props directly onto the <li> element
                          key={option.modelId}  // Set the key prop separately
                        >
                          {option.modelname} {fromYear} - {toYear}
                        </li>
                      );
                    }}
                    
                    getOptionLabel={(option) => {
                      // Format the label as desired
                      const fromYear = option.yearOfConstrFrom
                        ? `${option.yearOfConstrFrom}`.slice(4) +
                          `.` +
                          `${option.yearOfConstrFrom}`.slice(0, 4)
                        : "";
                      const toYear = option.yearOfConstrTo
                        ? `${option.yearOfConstrTo}`.slice(4) +
                          `.` +
                          `${option.yearOfConstrTo}`.slice(0, 4)
                        : "";
                      return `${option.modelname} ${fromYear} - ${toYear}`;
                    }}
                    options={manufacturerModels}
                    loading={isLoadingModel}
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                      ".MuiInputBase-input": {
                        height: "36px",
                        marginTop: "-5px",
                        marginBottom: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                    onChange={handleModelChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={modelInputRef}
                        placeholder="Models"
                        autoFocus={!!selectedManufacturer}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          sx: {
                            color: theme.palette.text.primary,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            height: "100%",
                            top: "-6px",
                            transform:
                              "translate(24px, 12px), scale(1) !important",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: 0, // Adjust the height as needed
                            height: "36px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingModel ? (
                                <CircularProgress
                                  color="inherit"
                                  size={15}
                                  style={{ marginTop: "-15px" }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} onClick={(e) => e.stopPropagation()}>
                  <Autocomplete
                    disablePortal
                    id="motor"
                    open={openMotor && !!selectedManufacturerModel}
                    onOpen={() => {
                      setOpenMotor(true);
                    }}
                    onClose={() => {
                      setOpenMotor(false);
                    }}
                    blurOnSelect={true}
                    ListboxProps={{
                      style: { maxHeight: 200, overflowY: "auto" },
                    }}
                    PaperComponent={(props) => {
                      const anchorEl = document.getElementById("motor");
                      const width = anchorEl
                        ? `${anchorEl.offsetWidth + 45}px`
                        : "auto";

                      return (
                        <Popper
                          {...props}
                          open={openMotor}
                          anchorEl={anchorEl}
                          placement="bottom-start"
                          modifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [-7, -10], // Adjust this value to move the Popper up or down
                              },
                            },
                          ]}
                          style={{
                            zIndex: 9999,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            border: "1px solid #f1f1f1",
                            boxShadow: "2px 3px 3px #ccc",
                            position: "relative",
                            background: "#fff",
                            width: width,
                          }}
                        />
                      );
                    }}
                    renderOption={(props, option) => {
                      // Parse the year strings and format them as "MM.YYYY"
                      const beginYearMonth = option.beginYearMonth
                        ? `${option.beginYearMonth.slice(
                            5
                          )}.${option.beginYearMonth.slice(0, 4)}`
                        : "";
                      const endYearMonth = option.endYearMonth
                        ? `${option.endYearMonth.slice(
                            5
                          )}.${option.endYearMonth.slice(0, 4)}`
                        : "";

                      return (
                        <li {...props} key={option.linkageTargetId}>
                          {option.description} ({option.kiloWattsFrom} kW /{" "}
                          {option.horsePowerFrom} hp) ({beginYearMonth} -{" "}
                          {endYearMonth})
                        </li>
                      );
                    }}
                    value={selectedMotor}
                    isOptionEqualToValue={(option, value) =>
                      option.linkageTargetId === value.linkageTargetId
                    }
                    getOptionLabel={(option) => {
                      const beginYearMonth = option.beginYearMonth
                        ? `${option.beginYearMonth.slice(
                            5
                          )}.${option.beginYearMonth.slice(0, 4)}`
                        : "";
                      const endYearMonth = option.endYearMonth
                        ? `${option.endYearMonth.slice(
                            5
                          )}.${option.endYearMonth.slice(0, 4)}`
                        : "";

                      // Return the formatted string
                      return `${option.description} (${option.kiloWattsFrom} kW / ${option.horsePowerFrom} hp) (${beginYearMonth} - ${endYearMonth})`;
                    }}
                    options={motors.map((motor) => ({
                      ...motor,
                      key: motor.linkageTargetId,
                    }))}
                    loading={loadingMotor}
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                      ".MuiInputBase-input": {
                        height: "36px",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                    onChange={handleMotorChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={motorInputRef}
                        autoFocus={!!selectedManufacturerModel}
                        placeholder="Motors"
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          sx: {
                            color: theme.palette.text.primary,
                            fontSize: "13px",
                            fontFamily: "Inter",
                            height: "100%",
                            top: "-6px",
                            transform:
                              "translate(24px, 12px), scale(1) !important",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: 0, // Adjust the height as needed
                            height: "36px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                          endAdornment: (
                            <React.Fragment>
                              {loadingMotor ? (
                                <CircularProgress
                                  color="inherit"
                                  size={15}
                                  style={{ marginTop: "-15px" }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder="VIN"
                    variant="outlined"
                    value={vin}
                    onChange={handleVinChange}
                    sx={{
                      width: "100%",
                      background: "#fff",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove the bottom border before hover
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove the bottom border after clicking
                      },
                    }}
                    InputProps={{
                      sx: {
                        textAlign: "left",
                        height: "36px",
                        fontSize: "13px",
                        borderRadius: 0,
                        fontFamily: "Inter",
                        paddingLeft: ".3rem",
                        width: "100%",
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none", // Remove the bottom border on hover
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none", // Remove the bottom border after clicking
                        },
                      },
                    }}
                  />
                </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Box>
        <Paper sx={{ width: "100%", mb: 2, mt:2, boxShadow: "none" }}>
                <TableContainer sx={{border: "1px solid #f1f1f1"}}>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={offerItems? offerItems.length:0}

                    />
                    <TableBody>
                      {offerItems && offerItems.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                sx={{
                                  color: theme.palette.secondary.main,
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                    sx={{
                                    textAlign: 'left',
                                    fontSize: '13px',
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    }}
                                 >
                                    {row.articleNumber}
                                 </Typography>
                            </TableCell>
                            <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.mfrName}
                           </TableCell>
                            <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.description}
                           </TableCell>
                           <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.quantity}
                           </TableCell>
                           <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.price} LEK
                           </TableCell>
                           <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.total_price} LEK
                           </TableCell>
                          </TableRow>
                        );
                      })}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
                <EnhancedTableToolbar offerItemsId={offerItemsId} numSelected={selected.length} />
              </Paper>
              <Box sx={{display:"flex", justifyContent:"flex-end"}}>
               <Typography
                     sx={{ fontSize: "13px", fontFamily: "Inter" }}
                     color="inherit"
                     variant="subtitle1"
                     component="div"
                  >
                     Wholesale price sum incl. VAT <b>{totalPrice} LEK</b>
                  </Typography>
              </Box>
              <Grid container spacing={2} sx={{mt:1}}>
                  <Grid item xs={12}>
                     <TextField
                        id="outlined-multiline-flexible"
                        fullWidth
                        placeholder="Comments"
                        multiline
                        rows={7}
                        maxRows={7}
                        value={comment}
                        onChange={handleCommentChange}
                        sx={{
                           fontSize: "13px",
                           fontFamily: "Inter",
                           fontWeight: 400,
                           color: theme.palette.text.primary,
                           borderRadius: 0
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Button 
                        sx={{
                           width: "10rem",
                           bgcolor: theme.palette.primary.dark,
                           color: theme.palette.text.secondary,
                           fontSize: "13px",
                           fontFamily: "Inter",
                           textTransform: "capitalize",
                           borderRadius: 0,
                           float: "left",
                           ":hover":{
                              bgcolor: theme.palette.primary.dark,
                           }
                        }}
                        onClick={handleSubmitCarData}
                     >
                        Create New Offer
                     </Button>
                  </Grid>
                  <Grid item xs={12}>
                  { offerError && (
                    <Alert severity="error" sx={{fontFamily:"Inter"}}>
                      {offerError}
                    </Alert>
                  )}
                    
                  </Grid>
               </Grid>
        </AccordionDetails>
      </Accordion>
        
      </Box>
    </>
  );
};
export default CreateOffer;
