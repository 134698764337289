import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import theme from "../../../theme";
import { Badge, Typography } from "@mui/material";
import { MainNotification } from "../../../interfaces/core/MainNotification";
import { getNotifications } from "../../../services/core-api";
const notificationIcon = "/notification-icon-big.svg";

const DiscountNotificationBanner = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showNotification, setShowNotification] = React.useState(false);
  const [lastMessage, setLastMessage] = React.useState<MessageEvent | null>(
    null
  );
  const [dailyNotificationCount, setDailyNotificationCount] = React.useState(
    () => {
      return parseInt(localStorage.getItem("notificationCount") || "0", 10);
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // Set notifications as viewed, hide the badge
    setShowNotification(false);
    localStorage.setItem("badge", "false");
  };

  const [mainNotifications, setMainNotifications] = React.useState<
    MainNotification[]
  >([]);

  React.useEffect(() => {
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_APP_URL;

    if (websocketUrl) {
      const ws = new WebSocket(websocketUrl);

      ws.onmessage = (event) => {
        setLastMessage(event);
      };

      return () => {
        ws.close();
      };
    }
  }, []);

  React.useEffect(() => {
    const fetchMainNotification = async () => {
      try {
        const response = await getNotifications();
        if (response) {
          setMainNotifications(response);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          setMainNotifications([]); 
        } else {
          console.error(
            "An error occurred while fetching notifications:",
            error
          );
        }
      }
    };

    const notificationStatus = localStorage.getItem("badge");
    if (notificationStatus === "true" || notificationStatus === null) {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }

    fetchMainNotification();
  }, []);

  React.useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsedMessage = JSON.parse(lastMessage.data);
        const { type, message, zone } = parsedMessage;
  
        if (Notification.permission === "granted") {
          let notificationTitle = "New Notification";
          if (type === "new_daily" && zone === "Daily") {
            notificationTitle = "Daily notification";
            const newNotificationCount = dailyNotificationCount + 1;
            setDailyNotificationCount(newNotificationCount);
            localStorage.setItem("dailyNotificationCount", JSON.stringify(newNotificationCount));
  
            const newNotification: MainNotification = {
              id: Date.now(),
              description: message,
              from_date: new Date().toISOString(),
              to_date: new Date().toISOString(),   
            };
  
            setMainNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
            setShowNotification(true);
          }
  
          new Notification(notificationTitle, {
            body: message,
            icon: notificationIcon,
          });
        }
      } catch (error) {
        console.error("Error processing message:", error);
      }
    }
  }, [lastMessage]);

  const handleClose = () => {
    setAnchorEl(null);
    setDailyNotificationCount(0);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {mainNotifications.length > 0 && showNotification ? (
              <Badge
              badgeContent={dailyNotificationCount}
              color="error"
              invisible={dailyNotificationCount === 0}
            >
                <CircleNotificationsIcon
                  sx={{
                    fill: theme.palette.secondary.light,
                    opacity: 1,
                    width: 32,
                    height: 32,
                  }}
                />
              </Badge>
            ) : (
              <CircleNotificationsIcon
                sx={{
                  fill: theme.palette.secondary.light,
                  opacity: 1,
                  width: 32,
                  height: 32,
                }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>

      {mainNotifications.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxWidth: "300px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {mainNotifications.map((row, index) => (
            <Box key={index}>
              <MenuItem
                onClick={handleClose}
                sx={{
                  width: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>
                  {row.description}
                </Typography>
              </MenuItem>
              {index < mainNotifications.length - 1 && <Divider />}
            </Box>
          ))}
        </Menu>
      )}
    </>
  );
};

export default DiscountNotificationBanner;
