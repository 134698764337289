import * as React from "react";
import Box from "@mui/material/Box";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Alert } from "@mui/material";
import { VehicleProductGroup } from "../../../interfaces/VehicleProductGroup";
import { getAssemblyGroupFacets } from "../../../services/tecdocService";
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";

interface AssemblyTreeProps {
  vehicleProductGroupId: number;
}

const ChildAssemblyTree: React.FC<AssemblyTreeProps> = ({
  vehicleProductGroupId,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [childVehicleProductGroups, setChildVehicleProductGroups] =
    React.useState<VehicleProductGroup[] | null>(null);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(
    null
  );
  const [linkageTargetType, setLinkageTargetType] = React.useState<
    string | null
  >(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const linkageTargetType = urlParams.get("linkageTargetType");
    const linkageTargetId = urlParams.get("linkageTargetId");
    const brandId = urlParams.get("brand");

    if (linkageTargetId) {
      const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
      setLinkageTargetId(linkageTargetIdUrlParam);
    }

    if (linkageTargetType) {
      setLinkageTargetType(linkageTargetType);
    }

    if (brandId) {
      const brandIdUrlParam = parseInt(brandId, 10);
      setBrandId(brandIdUrlParam);
    }
  }, []);

  React.useEffect(() => {
    if (linkageTargetId !== null && linkageTargetType !== null) {
      fetchAssemblyGroups(linkageTargetType, linkageTargetId);
    }
  }, [linkageTargetId, linkageTargetType, vehicleProductGroupId]);

  const fetchAssemblyGroups = async (
    linkageTargetType: string,
    linkageTargetId: number
  ) => {
    try {
      const options = {
        brand: brandId,
        assemblyGroupNodeIds: vehicleProductGroupId,
      };
      const response = await getAssemblyGroupFacets(
        linkageTargetType,
        linkageTargetId,
        options
      );
      if (response) {
        const filteredAssemblyGroupFacets =
          response.assemblyGroupFacets.counts.filter(
            (facet: { parentNodeId: number }) =>
              facet.parentNodeId === vehicleProductGroupId
          );
        setChildVehicleProductGroups(filteredAssemblyGroupFacets);
      }
    } catch (error) {
      console.error("Error fetching assemblies:", error);
    }
  };

  React.useEffect(() => {
    if (childVehicleProductGroups && childVehicleProductGroups.length > 0) {
      setLoading(false);
    }
  }, [childVehicleProductGroups]);

  const generateUniqueKey = (baseKey: string) => {
    return `${baseKey}-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <Box sx={{ minWidth: 150, pl: 2 }}>
      <>
        {loading ? (
          <>
            <Skeleton animation="wave" sx={{ width: "20rem" }} />
            <Skeleton animation="wave" sx={{ width: "20rem" }} />
          </>
        ) : (
          <>
            {childVehicleProductGroups &&
            childVehicleProductGroups.length > 0 ? (
              childVehicleProductGroups.map(
                (vehicleProductGroup: VehicleProductGroup, index) => {
                  const baseKey =
                    vehicleProductGroup.assemblyGroupNodeId.toString();
                  const uniqueKey = generateUniqueKey(baseKey);
                  const dynamicUrl = `/search-products?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${
                    brandId ? `&brand=${brandId}` : ""
                  }&assemblyGroupNodeIds=${
                    vehicleProductGroup.assemblyGroupNodeId
                  }`;

                  return (
                    <div key={generateUniqueKey(baseKey)}>
                      {vehicleProductGroup.children ? (
                        <TreeItem
                          key={uniqueKey}
                          itemId={uniqueKey}
                          label={
                            <Link href={dynamicUrl}>
                              <span
                                style={{
                                  textAlign: "left",
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  fontSize: "13px",
                                }}
                              >
                                {vehicleProductGroup.assemblyGroupName}
                              </span>
                            </Link>
                          }
                          style={{
                            textAlign: "left",
                            borderBottom: "1px solid #f1f1f1",
                          }}
                        >
                          <ChildAssemblyTree
                            key={generateUniqueKey(
                              vehicleProductGroup.assemblyGroupNodeId.toString()
                            )}
                            vehicleProductGroupId={
                              vehicleProductGroup.assemblyGroupNodeId
                            }
                          />
                        </TreeItem>
                      ) : (
                        <TreeItem
                          key={generateUniqueKey(
                            `${vehicleProductGroup.assemblyGroupNodeId}-${index}-picker`
                          )}
                          itemId={generateUniqueKey(
                            `${vehicleProductGroup.assemblyGroupNodeId}-${index}-picker`
                          )}
                          label={
                            <Link href={dynamicUrl}>
                              <span
                                style={{
                                  textAlign: "left",
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  fontSize: "13px",
                                }}
                              >
                                {vehicleProductGroup.assemblyGroupName}
                              </span>
                            </Link>
                          }
                          style={{
                            textAlign: "left",
                            borderBottom: "1px solid #f1f1f1",
                          }}
                        />
                      )}
                    </div>
                  );
                }
              )
            ) : (
              <Alert severity="info">
                This category does not have child categories
              </Alert>
            )}
          </>
        )}
      </>
    </Box>
  );
};

export default ChildAssemblyTree;
