import {
  Alert,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import theme from "../../../theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { VehicleProductGroup } from "../../../interfaces/VehicleProductGroup";
import { getAssemblyGroupFacets } from "../../../services/tecdocService";
import Link from "@mui/material/Link";
import { GenericArticle } from "../../../interfaces/core/GenericArticle";

const AssemblyBox = (props: any) => {
  const { group } = props;
  const [loading, setLoading] = React.useState(true);
  const [vehicleProductGroups, setVehicleProductGroups] = React.useState<
    VehicleProductGroup[] | null
  >([]);
  const [genericArticleFacets, setGenericArticleFacets] = React.useState<GenericArticle[]>([]);
  const [vehicleProductGroupLength, setVehicleProductGroupLength] =
    React.useState(-1);
  const [showMoreButton, setShowMoreButton] = React.useState(false);
  const [linkageTargetId, setLinkageTargetId] = React.useState(0);
  const [linkageTargetType, setLinkageTargetType] = React.useState("");
  const [brandId, setBrandId] = React.useState<number | null>(null);

  const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get("linkageTargetType");
      const linkageTargetId = urlParams.get("linkageTargetId");
      const brandId = urlParams.get("brand");

      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }

      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }

      if (brandId) {
        const brandIdUrlParam = parseInt(brandId, 10);
        setBrandId(brandIdUrlParam);
      }
    }
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const fetchAssemblyGroups = async () => {
        try {
          const options = {
            brand: brandId,
            assemblyGroupNodeIds: group.assemblyGroupNodeId,
          };
          const response = await getAssemblyGroupFacets(
            linkageTargetType,
            linkageTargetId,
            options
          );
          if (response) {
            setGenericArticleFacets(response.genericArticleFacets.counts);
            const filteredAssemblyGroupFacets =
              response.assemblyGroupFacets.counts.filter(
                (facet: { parentNodeId: number }) =>
                  facet.parentNodeId === group.assemblyGroupNodeId
              );
            setVehicleProductGroups(filteredAssemblyGroupFacets);
            if (vehicleProductGroups)
              setVehicleProductGroupLength(filteredAssemblyGroupFacets.length);
            if (filteredAssemblyGroupFacets.length > 6) {
              setShowMoreButton(true);
            }
          }
        } catch (error) {}
      };

      if (linkageTargetId && linkageTargetType) {
        fetchAssemblyGroups();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [brandId, group.assemblyGroupNodeId, linkageTargetId, linkageTargetType]);

  React.useEffect(() => {
    if (vehicleProductGroups && vehicleProductGroupLength >= 0) {
      setLoading(false);
    }
  }, [vehicleProductGroupLength, vehicleProductGroups]);

  const showMore = (assemblyGroupNodeId: number) => {
    const dynamicUrl = `/assembliesTree?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${
      brandId ? `&brand=${brandId}` : ""
    }&assemblyGroupNodeIds=${assemblyGroupNodeId}`;
    window.location.href = dynamicUrl;
  };

  return (
    <Box
      sx={{
        minHeight: "285px",
        border: "1px solid #f1f1f1",
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderTop: `2px solid ${theme.palette.primary.dark}`,
        }}
      >
        <Stack spacing={2} sx={{ p: 1 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: "15px",
              textAlign: "left",
            }}
          >
            {group.assemblyGroupName}
          </Typography>
        </Stack>
        <Paper sx={{ width: "100%", boxShadow: "none" }}>
          <MenuList sx={{ p: 1 }}>
            <div>
              {loading ? (
                <>
                  <Skeleton animation="wave" sx={{ width: "13rem" }} />
                  <Skeleton animation="wave" sx={{ width: "10rem", mt: 1 }} />
                  <Skeleton animation="wave" sx={{ width: "15rem", mt: 1 }} />
                  <Skeleton animation="wave" sx={{ width: "5rem", mt: 1 }} />
                  <Skeleton animation="wave" sx={{ width: "12rem", mt: 1 }} />
                  <Skeleton animation="wave" sx={{ width: "8rem", mt: 1 }} />
                  <Skeleton
                    animation="wave"
                    sx={{ width: "60px", height: "30px", mt: 5, ml: 16 }}
                  />
                </>
              ) : (
                <>
                  {vehicleProductGroups && vehicleProductGroups.length > 0 ? (
                    vehicleProductGroups
                      .slice(0, 6)
                      .map((vehicleProductGroup) => {
                        const category = genericArticleFacets.find(item => item.genericArticleDescription === vehicleProductGroup.assemblyGroupName);
                        const dynamicUrl = `/search-products?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${
                          brandId ? `&brand=${brandId}` : ""
                        }&assemblyGroupNodeIds=${
                          vehicleProductGroup.assemblyGroupNodeId
                        }${category ? `&category=${category.genericArticleId}` : ""}`;
                        return (
                          <MenuItem
                            key={vehicleProductGroup.assemblyGroupNodeId}
                          >
                            <ListItemIcon sx={{ minWidth: "25px !important" }}>
                              <ArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              <Link href={dynamicUrl}>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 400,
                                    fontFamily: "Inter",
                                    fontSize: "13px",
                                    textAlign: "left",
                                  }}
                                >
                                  {vehicleProductGroup.assemblyGroupName}
                                </Typography>
                              </Link>
                            </ListItemText>
                          </MenuItem>
                        );
                      })
                  ) : (
                    <Alert
                      severity="info"
                      sx={{
                        fontWeight: 400,
                        fontFamily: "Inter",
                        fontSize: "13px",
                      }}
                    >
                      This category does not have child categories
                    </Alert>
                  )}
                </>
              )}
            </div>
          </MenuList>
          {showMoreButton && (
            <Box sx={{ mb: 1 }}>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 400,
                  fontFamily: "Inter",
                  fontSize: "10px",
                  textTransform: "capitalize",
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                }}
                onClick={() => showMore(group.assemblyGroupNodeId)}
              >
                Show more
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default AssemblyBox;
