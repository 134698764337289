import React from 'react';
// import './style.scss';
import './App.css';
import RoutesComponent from './routes';

const App = () => {
  return (
    <div className='App'>
      <RoutesComponent />
    </div>
  );
}

export default App;
