// routes.tsx
import React from 'react';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductSearchPage from './pages/ProductSearchPage';
import AssembilesPage from './pages/AssembliesPage';
import AssemblyTreePage from './pages/AssemblyTreePage';
import OrdersPage from './pages/Customer/OrdersPage';
import BasketPage from './pages/Customer/BasketPage';
import BalancePage from './pages/Customer/BalancePage';
import ReturnsAndClaimsPage from './pages/Customer/ReturnsAndClaimsPage';
import PromotionsPage from './pages/Customer/PromotionsPage';
import FavoriteProductsPrage from './pages/Customer/FavoriteProductsPage';
import OffersPage from './pages/Customer/OffersPage';
import AdminSideMenu from './components/common/header/admin/SideMenu';
import Login from './pages/LoginPage';
import AuthProvider from './components/auth/authProvider';
import OilSearchPage from './pages/OilSearchPage';
import TireProductSearchPage from './pages/TireProductSearchPage';
import LocalProductSearchPage from './pages/LocalProductSearchPage';
import BatterySearchPage from './pages/BatterySearchPage';
import ConvertedProductSearchPage from './pages/ConvertedProductSearchPage';

const RoutesComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <AuthProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/search-products" element={<ProductSearchPage />} />
                <Route path="/search-converted-articles" element={<ConvertedProductSearchPage />} />
                <Route path="/search-oil" element={<OilSearchPage />} />
                <Route path='/search-tyres' element={<TireProductSearchPage/>}/>
                <Route path='/search-internal' element={<LocalProductSearchPage/>}/>
                <Route path='/search-battery' element={<BatterySearchPage/>}/>
                <Route path="/assemblies" element={<AssembilesPage />} />
                <Route path="/assembliesTree" element={<AssemblyTreePage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/basket" element={<BasketPage />} />
                <Route path="/balance" element={<BalancePage />} />
                <Route path="/return-requests" element={<ReturnsAndClaimsPage />} />
                <Route path="/promotions" element={<PromotionsPage />} />
                <Route path="/favorite" element={<FavoriteProductsPrage />} />
                <Route path="/offers" element={<OffersPage />} />
                <Route path="/admin/*" element={<AdminSideMenu />} />
              </Routes>
            </AuthProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
