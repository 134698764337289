import { LinkingTargetType } from '../actions/linkingTargetActions';
import { SET_LINKING_TARGET_TYPE } from '../actions/types';

// Define the type for the state
export interface LinkingTargetState {
  linkingTargetType: LinkingTargetType;
}

// Define the initial state
const initialState: LinkingTargetState = {
  linkingTargetType: 'P',
};

const linkingTargetReducer = (state: LinkingTargetState = initialState, action: any) => {
  switch (action.type) {
    case SET_LINKING_TARGET_TYPE:
      return { ...state, linkingTargetType: action.payload };

    default:
      return state;
  }
};

export default linkingTargetReducer;
