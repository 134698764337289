import * as React from "react";
import {
  Button,
  Grid,
  Autocomplete,
  TextField,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Slide,
  SlideProps,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import { OENumber } from "../../../interfaces/OENumber";
import theme from "../../../theme";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';

interface GroupedData {
  [key: string]: OENumber[];
}

interface ProductOENumbersProps {
  oeNumbers: OENumber[];
}

interface State {
  openNotification: boolean;
  copiedArticleNumber: string | null;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const defaultSnackbarOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

// Custom styled component for autocomplete options
const StyledOption = styled("li")(({ theme }) => ({
  display: "flex",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: 400,
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProductOENumbers: React.FC<ProductOENumbersProps> = ({ oeNumbers }) => {
  const [value, setValue] = React.useState<string | null>(null);
  const [groupedData, setGroupedData] = React.useState<GroupedData>({});
  const [hasData, setHasData] = React.useState(false);
  const [state, setState] = React.useState<State>({
    openNotification: false,
    copiedArticleNumber: null,
  });
  const [snackbarOrigin, setSnackbarOrigin] = React.useState<SnackbarOrigin>(
    defaultSnackbarOrigin
  );

  React.useEffect(() => {
    if (oeNumbers.length > 0) {
      setHasData(true);
      const updatedGroupedData: GroupedData = groupData(oeNumbers);
      setGroupedData(updatedGroupedData);
    } else {
      setHasData(false);
    }
  }, [oeNumbers]);

  const groupData = (data: OENumber[]): GroupedData => {
    const groupedData: GroupedData = {};

    data.forEach((item) => {
      if (!groupedData[item.mfrName]) {
        groupedData[item.mfrName] = [];
      }
      groupedData[item.mfrName].push(item);
    });

    return groupedData;
  };

  const handleClick = (articleNumber: string) => () => {
    // Copy to clipboard
    navigator.clipboard.writeText(articleNumber).then(() => {
      setState({
        ...state,
        openNotification: true,
        copiedArticleNumber: articleNumber,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const searchOENumbers = (articleNumber: string) => () => {
      const url = `/search-products?keyword=${articleNumber}`;
      window.location.href = url;
  }

  const handleCloseSnackBar = () => {
    setState({
      ...state,
      openNotification: false,
    });
  };

  return (
    <>
      {hasData ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2 }}>
            <Autocomplete
              value={value}
              sx={{
                width: "12rem",
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "30px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              options={Object.keys(groupedData)}
              renderOption={(props, option) => (
                <StyledOption {...props}>{option}</StyledOption>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Manufacturer"
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      height: "100%",
                      top: "-10px",
                      transform: "translate(24px, 12px), scale(1) !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: 0,
                      height: "30px",
                      fontSize: "13px",
                      fontFamily: "Inter",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Divider />
          {value && (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={0.2}>
                {groupedData[value].map((item, index) => (
                  <Grid key={index} item xs={6}  sm={6}  md={2} lg={2}>


                    <Box display="flex" alignItems="center">
                      <IconButton onClick={handleClick(item.articleNumber)}>
                        <ContentCopyIcon />
                      </IconButton>
                      <Button
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Inter",
                          fontWeight: 400,
                        }}

                        onClick={searchOENumbers(item.articleNumber)}
                      >
                        {item.articleNumber}
                      </Button>
                    </Box>
                    {/* Snackbar when user copy OE number notification */}
                    <Snackbar
                      open={
                        state.openNotification &&
                        state.copiedArticleNumber === item.articleNumber
                      }
                      onClose={handleCloseSnackBar}
                      TransitionComponent={SlideTransition}
                      key={SlideTransition.name}
                      autoHideDuration={1200}
                      anchorOrigin={snackbarOrigin}
                    >
                      <Alert
                        onClose={handleCloseSnackBar}
                        severity="info"
                        sx={{ width: "100%" }}
                      >
                        {item.articleNumber} copied!
                      </Alert>
                    </Snackbar>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      ) : (
        <Typography variant="body1" color="info" align="left" 
            sx={{ 
               mt:1,
               ml:2,
               fontSize: "13px",
               fontFamily: "Inter",
               fontWeight: 400,
            }}
         >
            No OE Numbers found
         </Typography>
      )}
    </>
  );
};

export default ProductOENumbers;
