import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import * as React from 'react';

const RACItems = () => {
    return (
        <>
        <TableContainer
                    component={Paper}
                    sx={{ boxShadow: "none", m: 1 }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ bgcolor: "#f1f1f1" }}>
                        <TableRow>
                          <TableCell sx={{ pb: 0, fontWeight: 600 }}>
                            Article Number
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pb: 0, fontWeight: 600 }}
                          >
                            Manufacturer
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pb: 0, fontWeight: 600 }}
                          >
                            Document No
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pb: 0, fontWeight: 600 }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pb: 0, fontWeight: 600 }}
                          >
                            Price
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="1">
                          <TableCell component="th" scope="row">
                            530 0201 10
                          </TableCell>
                          <TableCell align="left">ATE</TableCell>
                          <TableCell align="left">000002342</TableCell>
                          <TableCell align="left">12</TableCell>
                          <TableCell align="left">12.50 EUR</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
        </>
    )
}
export default RACItems;