import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Item from './Items';
import slider from '../../../helpers/slider.json';
import theme from '../../../theme';
import { getBanners } from '../../../services/core-api';
import { ReadBanner } from '../../../interfaces/core/ReadBanner';

function CarouselHome()
{

    const [banners, setBanners] = React.useState<ReadBanner[]>([])

    React.useEffect(() => {
        let activeSlider = true;

        if (activeSlider) {
            (async () => {
                try {
                    const banners = await getBanners();
                    if (banners) {
                        setBanners(banners)
                    }
                } catch (error) {
                }
            })();
        }

        return () => {
            activeSlider = false;
        }
    },[])

    return (
        <Carousel
        indicatorIconButtonProps={{
            style: {
                width: '3px',
                height: '3px',
                borderRadius: '0',
                padding: '10px',    // 1
                color: theme.palette.secondary.main       // 3
            }
        }}
         activeIndicatorIconButtonProps={{
            style: {
                backgroundColor: theme.palette.secondary.main // 2
            }
        }}
        indicatorContainerProps={{
            style: {
                position: 'absolute',
                bottom: '5px', // 5
                zIndex: 99,
                textAlign: 'center' // 4
            }
 
        }}
        >
            {
               banners.map( item => <Item key={item.id} image={item.image} id={item.id} /> )
            }
        </Carousel>
    )
}

export default CarouselHome;