import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Pagination,
  Grid,
  Typography,
  TextField,
  Button,
  Drawer,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import theme from "../../../theme";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { createVoucher, getVouchers, updateVoucher, deleteVoucher } from "../../../services/core-api";
import { Voucher } from "../../../interfaces/core/Voucher";
import { useSnackbar } from "../../../components/common/SnackbarContext";

const VouchersList = () => {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const [count, setCount] = React.useState(0);
  const [vouchers, setVouchers] = React.useState<Voucher[] | []>([]);
  const [searchValue, setSearchValue] = React.useState('');

  const fetchVouchers = React.useCallback(async () => {
    try {
      const options = {
        keyword: searchValue,
        page_number: page
      };
      const response = await getVouchers(options);
      if(response) {
        console.log(response)
        setVouchers(response.vouchers);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setVouchers([]);
      setPage(1);
      setCount(0);
    }
  }, [page, rowsPerPage, searchValue]);

  React.useEffect(() => {
    fetchVouchers();
  }, [fetchVouchers]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [code, setCode] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const usage_count = 0;
  const [max_usage, setMax_usage] = React.useState('');
  const [isActive, setIsActive] = React.useState(true);
  const { showSnackbar } = useSnackbar();

  const handleCreateVoucher = async () => {
   const voucherData = {
     code,
     discount_percentage: parseFloat(discount),
     usage_count,
     max_usage,
     created_at: new Date().toISOString(),  
     updated_at: new Date().toISOString(),
     is_active: true,
   };

   try {
     const response = await createVoucher(voucherData);
     if (response) {
       window.location.reload();
     }
   } catch (error) {
     showSnackbar('Failed to create voucher.', 'error');
   }
 };

  const handleStatusToggle = async (voucher: Voucher) => {
    try {
      voucher.is_active = !voucher.is_active;
      const response = await updateVoucher(voucher);
      if (response.status === 200) {
        showSnackbar('Voucher updated successfully!', 'success');
      }
    } catch (error) {
      showSnackbar('Failed to update voucher status.', 'error');
    }
  };

  const handleDeleteVoucher = async (code: string) => {
    try {
      await deleteVoucher(code);
      window.location.reload();
      showSnackbar('Voucher deleted successfully!', 'success');
    } catch (error) {
      showSnackbar('Failed to delete voucher.', 'error');
    }
  };

  const DrawerList = (
    <Box sx={{ width: "100%", p: 3, mt: 4, bgcolor: "#f5f5f5" }} role="presentation">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={1}
          sx={{
            maxWidth: "600px",
            display: "flex",
            bgcolor: "#fff",
            border: "1px solid #f1f1f1",
            p: 2,
            mt: 2,
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Voucher Code"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Discount (%)"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Max usage"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={max_usage}
              onChange={(e) => setMax_usage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e: any) => setIsActive(e.target.checked)}
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                width: "100%",
                mt: 1,
                bgcolor: theme.palette.primary.dark,
                color: theme.palette.text.secondary,
                fontSize: "13px",
                "&:hover": {
                  bgcolor: theme.palette.primary.dark,
                  color: theme.palette.text.secondary,
                },
              }}
              onClick={handleCreateVoucher}
            >
              Create Voucher
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              float: "left",
              fontFamily: "Inter",
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 3,
            }}
          >
            Vouchers List
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={8}>
          <TextField
            placeholder="Search"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={toggleDrawer(true)}
          >
            Add Voucher
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Usage Count</TableCell>
                <TableCell>Max Usage</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers.map((voucher) => (
                <TableRow key={voucher.id}>
                  <TableCell>{voucher.code}</TableCell>
                  <TableCell>{voucher.discount_percentage}%</TableCell>
                  <TableCell>{voucher.usage_count}</TableCell>
                  <TableCell>{voucher.max_usage}</TableCell>
                  <TableCell>
                    <Switch
                      checked={voucher.is_active}
                      onChange={() => handleStatusToggle(voucher)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteVoucher(voucher.code)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Paper>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
};

export default VouchersList;
