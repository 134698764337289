import * as React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Divider,
  styled,
  
} from "@mui/material";
import { getlinkingVehicles } from "../../../services/tecdocService";
import { LinkageVehicleDetails } from "../../../interfaces/LinkageVehicleDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useSelector  } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import theme from "../../../theme";
import { LinkingTargetState } from "../../../store/reducers/linkingTargetReducer";


// Custom styled component for autocomplete options
const StyledOption = styled("li")(({ theme }) => ({
  display: "flex",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: 400,
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProductLinkingVehicle = (props: any) => {
  const { genericArticles, article } = props;
  const legacyArticleId = genericArticles[0]?.legacyArticleId;
  const linkingTargetType = useSelector((state: { linkingTarget: LinkingTargetState}) => state.linkingTarget.linkingTargetType);

  const [response, setResponse] = React.useState<any>(null); 
  const [openMarker, setOpenMarker] = React.useState(false);
  const [manufacturers, setManufacturers] = React.useState<string[]>([]); 
  const [vehiclesByManufacturer, setVehiclesByManufacturer] = React.useState<
    LinkageVehicleDetails[]
  >([]); 
  const [selectedManufacturer, setSelectedManufacturer] = React.useState<
    string | null
  >(null); 
  const [value, setValue] = React.useState<string | null>(null); 

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getlinkingVehicles(
          linkingTargetType,
          legacyArticleId
        ); // Fetch data
        setResponse(response);
        const manufacturersData = Object.keys(response); 
        setManufacturers(manufacturersData);
      } catch (error) {}
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup if necessary
    };
  }, [linkingTargetType, legacyArticleId]);

  const handleManufacturerChange = async (
    event: React.ChangeEvent<{}>,
    manufacturerName: string | null
  ) => {
    if (manufacturerName) {
      setSelectedManufacturer(manufacturerName);
      setValue(manufacturerName);

      if (response) {
        const vehicles = response[manufacturerName] || []; 
        setVehiclesByManufacturer(vehicles);
      }
    }
  };

  const handleRowClick = (carId: number) => {
    const url = `search-products?linkageTargetType=${linkingTargetType}&linkageTargetId=${carId}&keyword=${article.articleNumber}`;
    window.location.href = url
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ p: 2 }}>
        <Autocomplete
          id="manufacturer-autocomplete"
          value={value}
          onChange={handleManufacturerChange}
          open={openMarker}
          onOpen={() => setOpenMarker(true)}
          onClose={() => setOpenMarker(false)}
          options={manufacturers}
          renderOption={(props, option) => (
            <StyledOption {...props}>{option}</StyledOption>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Manufacturer"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-10px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0,
                  height: "30px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
              }}
            />
          )}
          sx={{
            width: "12rem",
            backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "30px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
          }}
        />
      </Box>
      <Divider />
      {selectedManufacturer && (
        <Box sx={{ p: 2,  maxHeight: '300px', overflowY: 'auto' }}>
          <TableContainer component={Paper} sx={{boxShadow: 'none'}}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead sx={{bgcolor: "#f1f1f1"}}>
                <TableRow>
                  <TableCell sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Model Name</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Type</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Motor Type</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Power Hp</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Construction From</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Construction To</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Impulsion Type</TableCell>
                  <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 500}}>Construction Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehiclesByManufacturer.map((vehicle) => (
                  <TableRow
                    hover 
                    key={vehicle.carId}
                    onClick={() => handleRowClick(vehicle.carId)}
                    sx={{cursor: "pointer"}}
                  >
                    <TableCell component="th" scope="row" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>
                      {vehicle.modelName}
                    </TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>{vehicle.typeName}</TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>{vehicle.fuelType}</TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>{vehicle.powerHpTo}</TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>
                      {vehicle.yearOfConstrFrom && typeof vehicle.yearOfConstrFrom === 'number'
                        ? `${vehicle.yearOfConstrFrom.toString().substring(4, 6)}/${vehicle.yearOfConstrFrom.toString().substring(0, 4)}`
                        : ''}
                    </TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>
                      {vehicle.yearOfConstrTo && typeof vehicle.yearOfConstrTo === 'number'
                        ? `${vehicle.yearOfConstrTo.toString().substring(4, 6)}/${vehicle.yearOfConstrTo.toString().substring(0, 4)}`
                        : ''}
                    </TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>{vehicle.impulsionType}</TableCell>
                    <TableCell align="right" sx={{fontSize: "13px ",fontFamily: "Inter", fontWeight: 400}}>
                      {vehicle.constructionType}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ProductLinkingVehicle;
