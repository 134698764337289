import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import theme from '../../../theme';
import { setLinkingTargetType } from "../../../store/actions/linkingTargetActions";
import { changeRetailPrice, getUser } from '../../../services/core-api';
import { toggleRetailPrice } from '../../../store/actions/priceReducerAction';
import { FormControlLabel, Switch } from '@mui/material';


const mobilePages = [  
{
   'icon': <ShoppingBagOutlinedIcon />,
   'text': 'My Orders',
   'link': '/orders'
},
{
   'icon': <AccountBalanceOutlinedIcon />,
   'text': 'Balance',
   'link': '/balance'
},
{
   'icon': <RepeatOutlinedIcon />,
   'text': 'Returns and Claims',
   'link': '/return-requests'
},
{
   'icon': <FavoriteOutlinedIcon />,
   'text': 'Favorite Products',
   'link': '/favorite'
},
{
   'icon': <DiscountOutlinedIcon />,
   'text': 'Promotions',
   'link': '/promotions'
},
{
   'icon': <TurnedInNotIcon />,
   'text': 'Offers',
   'link': '/offers'
}
];


const engineTypes = [
   {
      'icon': <DirectionsCarOutlinedIcon />,
      'text': 'Passenger Car',
      'linkingTargetType': 'V'
    },
    {
      'icon': <AirportShuttleOutlinedIcon />,
      'text': 'Van',
      'linkingTargetType': 'L'
    },
    {
      'icon': <LocalShippingOutlinedIcon />,
      'text': 'Truck',
      'linkingTargetType': 'C'
    },
    {
      'icon': <MopedOutlinedIcon />,
      'text': 'Motorcycle',
      'linkingTargetType': 'B'
    },
];

const logoutPage = [ 
   {
      'icon': <LogoutIcon />,
      'text': 'Logout'
   }
];

const MobileSideMenu = () => {
   const [retailPrice, setRetailPrice] = React.useState(false);
   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
 
   const [activeIndex, setActiveIndex] = useState(0);

   React.useEffect(() => {
      (async () => {
        try {
          const user = localStorage.getItem("user");
          if (user) {
            const userData = JSON.parse(user);
            const response = await getUser(userData.id);
            if (response) {
              setRetailPrice(response.retailPrice);
              dispatch(toggleRetailPrice(response.retailPrice));
            }
          }
        }catch (error){}
      })()
    },[dispatch])
 
   useEffect(() => {
     const params = new URLSearchParams(location.search);
     const targetTypeParam = params.get('linkingTargetType');
     const activeIndex = engineTypes.findIndex(page => page.linkingTargetType === targetTypeParam);
     if (activeIndex !== -1) {
       setActiveIndex(activeIndex);
     }
   }, [location.search]);
 
   const handleButtonClick = (index: number) => {
     setActiveIndex(index);
     const newLinkingTargetType = engineTypes[index].linkingTargetType;
     dispatch(setLinkingTargetType(newLinkingTargetType));
     navigate(`/?linkingTargetType=${newLinkingTargetType}`);
   };

   const handleLogout = () => {
      localStorage.removeItem("token"); 
      localStorage.removeItem("user"); 
      navigate("/login");
    };

    const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
  
      try{
        const response = await changeRetailPrice();
        if (response ) {
          const newRetailPriceStatus = !retailPrice; 
          setRetailPrice(newRetailPriceStatus);
          localStorage.setItem("user", JSON.stringify(response.data));
          dispatch(toggleRetailPrice(newRetailPriceStatus));
        }
      }catch (error){}
    };
    

  return (
   <div>
      <List>
         {mobilePages.map((page, index) => (
            <ListItem key={index} disablePadding component={Link} to={page.link}>
               <ListItemButton>
                  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '5px' }}>
                     {page.icon}
                  </ListItemIcon>
                  <ListItemText primary={page.text} sx={{fontSize: "13px", fontFamily: "Inter", color: theme.palette.text.primary}} />
               </ListItemButton>
            </ListItem>
         ))}
      </List>
      <Divider />
      <List>
         {engineTypes.map((page, index) => (
            <ListItem key={index} disablePadding>
               <ListItemButton onClick={() => handleButtonClick(index)}>
                  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '5px' }}>
                  {page.icon}
                  </ListItemIcon>
                  <ListItemText primary={page.text}  sx={{fontSize: "13px", fontFamily: "Inter", color: theme.palette.text.primary}}/>
               </ListItemButton>
            </ListItem>
         ))}
      </List>
      <Divider />
      <List>
         <ListItem>
            <FormControlLabel 
               control={
               <Switch 
                  size="small" 
                  checked={retailPrice}
                  onChange={handleSwitchChange}
               />
               } 
               label="Retail price"
               sx={{ 
               '& .MuiFormControlLabel-label': { fontSize: '15px', fontFamily: "Inter" }
               }}
            />
         </ListItem>
         {logoutPage.map((page, index) => (
            <ListItem key={index} disablePadding>
               <ListItemButton
                  onClick={handleLogout}
               >
                  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '5px' }}>
                  {page.icon}
                  </ListItemIcon>
                  <ListItemText primary={page.text} />
               </ListItemButton>
            </ListItem>
         ))}
      </List>
   </div>
  );
}

export default MobileSideMenu;