import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { Article } from '../../interfaces/Article';
import { ArticleCriteria } from '../../interfaces/ArticleCriteria';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const CompareProducts =(props: any) => {
  const {compareArticles} = props;
  const defaultImage = './no-image.png';
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {  
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
    }

    // Check if the clicked element has the class 'MuiBackdrop-root'
    const target = event.target as HTMLElement;
    if (target.classList.contains('MuiBackdrop-root') || target.classList.contains('MuiButtonBase-root')) {
      setState({ ...state, [anchor]: open });
    } else {
      return;
    }
  };

  const liStyle = {
    fontSize: '12px',
    borderTop: '1px solid #f1f1f1',
    borderBottom: '1px solid #f1f1f1',
    fontFamily: 'Inter, sans-serif',
    paddingTop: '3px',
    paddingBottom: '3px',
    textDecoration: 'none',
    listStyle: 'none'
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container spacing={2} sx={{p:2}}>
            
            {compareArticles.map((article: Article, index:number) => ( 
               <Grid key={index} item xs={2.5}>
                  <Card sx={{ width:'100%' }}>
                     <CardHeader
                     sx={{
                        fontWeight: 'bold',
                        fontSize: "15px",
                        fontFamily: "Inter",
                     }}
                     avatar={article.articleNumber}
                    //  action={}
                     />
                     <CardMedia
                     component="img"
                     height="194"
                     image={(article.images && article.images[0]?.imageURL3200) || defaultImage}
                     alt="Article"
                     />
                     <CardContent>
                     <Typography variant="body2">
                        {article.mfrName}
                     </Typography>
                        <ul style={{paddingLeft: 0, maxHeight: '200px', overflowY: 'auto'}}>
                            <li style={liStyle}><b>Article Number:</b> {article.articleNumber}</li>
                            <li style={liStyle}><b>Gtins:</b> {article.gtins}</li>
                            <li style={liStyle}><b>Status:</b> {article.misc?.articleStatusDescription || 'No status available'}</li>
                            {article.articleCriteria ? (
                                article.articleCriteria.map((criteria: ArticleCriteria, index: number) => (
                                  <li style={liStyle} key={index}>
                                    <b>{criteria.criteriaDescription}:</b> {criteria.formattedValue}
                                  </li>
                                ))
                              ) : (
                                <li style={liStyle}>No criteria available</li>
                              )}
                        </ul>
                     </CardContent>
                  </Card>
               </Grid>
            ))}
      </Grid>
    </Box>
  );

  return (
    <div>
      {(['bottom'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button 
            sx={{
               fontSize: "13px",
               padding: '2px 6px !important',
               borderRadius: 0,
               fontFamily: "Inter",
               textTransform: 'capitalize',
               display: {
                xs: 'none',
                sm: 'block',
               }
            }}
            
            onClick={toggleDrawer(anchor, true)} 
            variant="outlined">
               Compare Products
            </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default CompareProducts;
