import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";
import theme from "../../../theme";
import Switch from "@mui/material/Switch";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const UpdateUser = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Box
        sx={{ width: "100%", p: 3, bgcolor: "#f5f5f5", height: "100vh" }}
        role="presentation"
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "20px",
            fontFamily: "Inter",
            textTransform: "capitalize",
            color: theme.palette.text.primary,
          }}
        >
          Edit User Form
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            spacing={1}
            sx={{
              maxWidth: "600px",
              display: "flex",
              bgcolor: "#fff",
              border: "1px solid #f1f1f1",
              p: 2,
              mt: 2,
            }}
          >
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ float: "left !important" }}
              >
                <FormGroup>
                  <FormControlLabel
                    label={
                      <Typography
                        variant="h5"
                        sx={{ fontSize: "13px", fontFamily: "Inter" }}
                      >
                        User Status:
                      </Typography>
                    }
                    labelPlacement="start"
                    control={
                      <Switch {...label} defaultChecked color="warning" />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Name"
                variant="outlined"
                sx={{
                  width: "100%",
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Surname"
                variant="outlined"
                sx={{
                  width: "100%",
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Client ERP ID"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Company"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="City"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Country"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="Address"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Phone"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                disabled
                placeholder="Username"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                placeholder="Email"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  sx={{height: '40px', mt:1, fontSize: '13px', fontFamily: "Inter"}}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Password"
                />
              </FormControl>

              {/* <TextField
                id="outlined-basic"
                placeholder="Password"
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  float: "left",
                  bgcolor: "#fff",
                  color: theme.palette.text.primary,
                  border: 0,
                }}
                inputProps={{
                  sx: {
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px 14px",
                  },
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{
                  width: "100%",
                  mt: 1,
                  bgcolor: theme.palette.primary.dark,
                  color: theme.palette.text.secondary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  textTransform: "capitalize",
                  ":hover": {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default UpdateUser;
