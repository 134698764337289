import * as React from 'react';
import Header from '../components/common/header/Header';
import Footer from '../components/common/Footer/Footer';
import VehicleDetail from '../components/common/VehicleDetails/VehicleDetail';
import { Box, Grid } from '@mui/material';
import Assemblies from '../components/common/Assemblies/Assemblies';

const AssembliesPage = () => {
   return (
      <>
         <Header/>
         <Box sx={{m: 2}}>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2.5}>
               <VehicleDetail/>
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
            <Assemblies/>
            </Grid>
         </Grid>
         </Box>

         <Footer/>
      </>
   )
}

export default AssembliesPage;