import * as React from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Logout } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ArticleIcon from "@mui/icons-material/Article";
import DashboardPage from "../../../../pages/admin/DashboardPage";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import OrdersList from "../../../../pages/admin/orders/OrdersList";
import UsersList from "../../../../pages/admin/users/UsersList";
import UpdateUser from "../../../../pages/admin/users/UpdateUser";
import ReturnsAndClaims from "../../../../pages/admin/return-claims/ReturnsAndClaims";
import RACItems from "../../../../pages/admin/return-claims/RACItems";
import UsersListReport from "../../../../pages/admin/reports/users/UsersListReport";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import ProductsListReport from "../../../../pages/admin/reports/products/ProductsListReport";
import Banner from "../../../../pages/admin/banners/Banner";
import { useNavigate } from "react-router-dom";
import Vouchers from "../../../../pages/admin/voucher/voucherPage";
import BannerNotifications from "../../../../pages/admin/notifications/BannerNotification";
import MainNotifications from "../../../../pages/admin/notifications/MainNotifications";
const notificationIcon = "/notification-icon-big.svg";
// Side menu style

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSideMenu = () => {
  const theme = useTheme();
  const location = useLocation(); // Get the current location
  const [lastMessage, setLastMessage] = React.useState<MessageEvent | null>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(true);
  const [openNestedMenu, setOpenNestedMenu] = React.useState(true);
  const [openNestedMenuNotification, setOpenNestedMenuNotification] =
    React.useState(true);
  const [notificationCount, setNotificationCount] = React.useState(() => {
    return parseInt(localStorage.getItem("notificationCount") || "0", 10);
  });
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);

  const [returnNotificationCount, setReturnNotificationCount] = React.useState(
    () => {
      return parseInt(
        localStorage.getItem("returnNotificationCount") || "0",
        10
      );
    }
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_APP_URL;

    if (websocketUrl) {
      const ws = new WebSocket(websocketUrl);

      ws.onmessage = (event) => {
        setLastMessage(event);
      };

      const pingInterval = setInterval(() => {
        console.log("ping");
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "ping" }));
        }
      }, 3000);

      return () => {
        clearInterval(pingInterval);
        ws.close();
      };
    }
  }, []);

  React.useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsedMessage = JSON.parse(lastMessage.data);
        const { type, message, zone } = parsedMessage;

        const userData = localStorage.getItem("user");
        let userZone = null;

        if (userData) {
          try {
            const parsedUserData = JSON.parse(userData);
            userZone = parsedUserData.zone;
            console.log("Check super admin: ", parsedUserData.is_super_admin);
            setIsSuperAdmin(parsedUserData.is_super_admin);
          } catch (error) {}

          if (Notification.permission === "granted") {
            if (zone === userZone) {
              let notificationTitle = "New Notification";
              if (type === "new_order") {
                notificationTitle = "New Order";
                const newNotificationCount = notificationCount + 1;
                setNotificationCount(newNotificationCount);
                localStorage.setItem(
                  "notificationCount",
                  JSON.stringify(newNotificationCount)
                );
              } else if (type === "return_request") {
                notificationTitle = "New Return Request";
                const newReturnNotificationCount = returnNotificationCount + 1;
                setReturnNotificationCount(newReturnNotificationCount);
                localStorage.setItem(
                  "returnNotificationCount",
                  JSON.stringify(newReturnNotificationCount)
                );
              }

              new Notification(notificationTitle, {
                body: message,
                icon: notificationIcon,
              });
            }
          }
        }
      } catch (error) {}
    }
  }, [lastMessage]);

  React.useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData) {
      const parsedUserData = JSON.parse(userData);
      console.log("Check super admin: ", parsedUserData.is_super_admin);
      setIsSuperAdmin(parsedUserData.is_super_admin);
    }
  }, []);

  const handleClickOpenNestedMenu = () => {
    setOpenNestedMenu(!openNestedMenu);
  };

  const handleClickOpenNestedMenuNotification = () => {
    setOpenNestedMenuNotification(!openNestedMenuNotification);
  };

  const handleHideNotification = () => {
    setNotificationCount(0);
    localStorage.removeItem("notificationCount");
  };

  const handleHideReturnNotification = () => {
    setReturnNotificationCount(0);
    localStorage.removeItem("returnNotificationCount");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleLogout}
        >
          <Logout fontSize="small" />
          Log out
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Kallugjeri
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleLogout}
              color="inherit"
            >
              <Logout fontSize="small" />
              <Typography variant="body1" noWrap component="div">
                Log out
              </Typography>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={"Dashboard"} disablePadding sx={{ display: "block" }}>
            <Link
              to="/admin/dashboard"
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/admin/dashboard"
                      ? theme.palette.primary.dark
                      : "transparent",
                  ":hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "#fff",
                    "& .MuiListItemIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiListItemText-root": {
                      color: "#fff",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardIcon
                    sx={{
                      color:
                        location.pathname === "/admin/dashboard" ? "#fff" : "",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      location.pathname === "/admin/dashboard" ? "#fff" : "",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={"Orders"} disablePadding sx={{ display: "block" }}>
            <Link
              to="/admin/orders"
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/admin/orders"
                      ? theme.palette.primary.dark
                      : "transparent",
                  ":hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "#fff",
                    "& .MuiListItemIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiListItemText-root": {
                      color: "#fff",
                    },
                  },
                }}
                onClick={handleHideNotification}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    badgeContent={notificationCount}
                    color="error"
                    invisible={notificationCount === 0}
                  >
                    <ShoppingBasketIcon
                      sx={{
                        color:
                          location.pathname === "/admin/orders" ? "#fff" : "",
                      }}
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  primary={"Orders"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: location.pathname === "/admin/orders" ? "#fff" : "",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={"Clients"} disablePadding sx={{ display: "block" }}>
            <Link
              to="/admin/clients"
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/admin/clients"
                      ? theme.palette.primary.dark
                      : "transparent",
                  ":hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "#fff",
                    "& .MuiListItemIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiListItemText-root": {
                      color: "#fff",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GroupIcon
                    sx={{
                      color:
                        location.pathname === "/admin/clients" ? "#fff" : "",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Clients"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: location.pathname === "/admin/clients" ? "#fff" : "",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={"Return"} disablePadding sx={{ display: "block" }}>
            <Link
              to="/admin/return-claims-requests"
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/admin/return-claims-requests"
                      ? theme.palette.primary.dark
                      : "transparent",
                  ":hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "#fff",
                    "& .MuiListItemIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiListItemText-root": {
                      color: "#fff",
                    },
                  },
                }}
                onClick={handleHideReturnNotification}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    badgeContent={returnNotificationCount}
                    color="error"
                    invisible={returnNotificationCount === 0}
                  >
                    <KeyboardReturnIcon
                      sx={{
                        color:
                          location.pathname === "/admin/return-claims-requests"
                            ? "#fff"
                            : "",
                      }}
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  primary={"Return and Claims"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      location.pathname === "/admin/return-claims-requests"
                        ? "#fff"
                        : "",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={"Reports"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor:
                  location.pathname === "/admin/reports"
                    ? theme.palette.primary.dark
                    : "transparent",
                ":hover": {
                  backgroundColor: theme.palette.primary.dark,
                  color: "#fff",
                  "& .MuiListItemIcon-root": {
                    color: "#fff",
                  },
                  "& .MuiListItemText-root": {
                    color: "#fff",
                  },
                },
              }}
              onClick={handleClickOpenNestedMenu}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QueryStatsIcon
                  sx={{
                    color: location.pathname === "/admin/reports" ? "#fff" : "",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Reports"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: location.pathname === "/admin/reports" ? "#fff" : "",
                }}
              />
              {openNestedMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openNestedMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link
                  to="/admin/users-report"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      pl: 4,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        location.pathname === "/admin/users-report"
                          ? theme.palette.primary.dark
                          : "transparent",
                      ":hover": {
                        backgroundColor: theme.palette.primary.dark,
                        color: "#fff",
                        "& .MuiListItemIcon-root": {
                          color: "#fff",
                        },
                        "& .MuiListItemText-root": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "45px", marginLeft: "20px" }}>
                      <PersonSearchIcon
                        sx={{
                          color:
                            location.pathname === "/admin/users-report"
                              ? "#fff"
                              : "",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Users report"
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          location.pathname === "/admin/users-report"
                            ? "#fff"
                            : "",
                      }}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  to="/admin/products-report"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      pl: 4,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        location.pathname === "/admin/products-report"
                          ? theme.palette.primary.dark
                          : "transparent",
                      ":hover": {
                        backgroundColor: theme.palette.primary.dark,
                        color: "#fff",
                        "& .MuiListItemIcon-root": {
                          color: "#fff",
                        },
                        "& .MuiListItemText-root": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "45px", marginLeft: "20px" }}>
                      <ArticleIcon
                        sx={{
                          color:
                            location.pathname === "/admin/products-report"
                              ? "#fff"
                              : "",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Articles report"
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          location.pathname === "/admin/products-report"
                            ? "#fff"
                            : "",
                      }}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
          {isSuperAdmin && (
            <>
              <ListItem
                key={"Notifications"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor:
                      location.pathname === "/admin/notification"
                        ? theme.palette.primary.dark
                        : "transparent",
                    ":hover": {
                      backgroundColor: theme.palette.primary.dark,
                      color: "#fff",
                      "& .MuiListItemIcon-root": {
                        color: "#fff",
                      },
                      "& .MuiListItemText-root": {
                        color: "#fff",
                      },
                    },
                  }}
                  onClick={handleClickOpenNestedMenuNotification}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <NotificationsIcon
                      sx={{
                        color:
                          location.pathname === "/admin/notification"
                            ? "#fff"
                            : "",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Notifications"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color:
                        location.pathname === "/admin/notification"
                          ? "#fff"
                          : "",
                    }}
                  />
                  {openNestedMenuNotification ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={openNestedMenuNotification}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/admin/notifications"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          pl: 4,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          backgroundColor:
                            location.pathname === "/admin/notifications"
                              ? theme.palette.primary.dark
                              : "transparent",
                          ":hover": {
                            backgroundColor: theme.palette.primary.dark,
                            color: "#fff",
                            "& .MuiListItemIcon-root": {
                              color: "#fff",
                            },
                            "& .MuiListItemText-root": {
                              color: "#fff",
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "45px", marginLeft: "20px" }}
                        >
                          <NotificationsIcon
                            sx={{
                              color:
                                location.pathname === "/admin/notifications"
                                  ? "#fff"
                                  : "",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Daily notifications"
                          sx={{
                            opacity: open ? 1 : 0,
                            color:
                              location.pathname === "/admin/notifications"
                                ? "#fff"
                                : "",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                    <Link
                      to="/admin/anouncments"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          pl: 4,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          backgroundColor:
                            location.pathname === "/admin/anouncments"
                              ? theme.palette.primary.dark
                              : "transparent",
                          ":hover": {
                            backgroundColor: theme.palette.primary.dark,
                            color: "#fff",
                            "& .MuiListItemIcon-root": {
                              color: "#fff",
                            },
                            "& .MuiListItemText-root": {
                              color: "#fff",
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "45px", marginLeft: "20px" }}
                        >
                          <ArticleIcon
                            sx={{
                              color:
                                location.pathname === "/admin/anouncments"
                                  ? "#fff"
                                  : "",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Anouncments"
                          sx={{
                            opacity: open ? 1 : 0,
                            color:
                              location.pathname === "/admin/anouncments"
                                ? "#fff"
                                : "",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
              </ListItem>
              <ListItem
                key={"Banners"}
                disablePadding
                sx={{ display: "block" }}
              >
                <Link
                  to="/admin/banners"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        location.pathname === "/admin/banners"
                          ? theme.palette.primary.dark
                          : "transparent",
                      ":hover": {
                        backgroundColor: theme.palette.primary.dark,
                        color: "#fff",
                        "& .MuiListItemIcon-root": {
                          color: "#fff",
                        },
                        "& .MuiListItemText-root": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <BurstModeIcon
                        sx={{
                          color:
                            location.pathname === "/admin/banners"
                              ? "#fff"
                              : "",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Banners"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          location.pathname === "/admin/banners" ? "#fff" : "",
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                key={"Vouchers"}
                disablePadding
                sx={{ display: "block" }}
              >
                <Link
                  to="/admin/vouchers"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        location.pathname === "/admin/vouchers"
                          ? theme.palette.primary.dark
                          : "transparent",
                      ":hover": {
                        backgroundColor: theme.palette.primary.dark,
                        color: "#fff",
                        "& .MuiListItemIcon-root": {
                          color: "#fff",
                        },
                        "& .MuiListItemText-root": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <BurstModeIcon
                        sx={{
                          color:
                            location.pathname === "/admin/vouchers"
                              ? "#fff"
                              : "",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Vouchers"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          location.pathname === "/admin/vouchers" ? "#fff" : "",
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, bgcolor: "#fafafa", minHeight: "100vh" }}
      >
        <DrawerHeader />
        <Routes>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="orders" element={<OrdersList />} />
          <Route path="clients" element={<UsersList />} />
          <Route path="/edit-user/:id" element={<UpdateUser />} />
          <Route path="return-claims-requests" element={<ReturnsAndClaims />} />
          <Route path="/return-claims-requests/:id" element={<RACItems />} />
          <Route path="/users-report" element={<UsersListReport />} />
          <Route path="/products-report" element={<ProductsListReport />} />
          <Route path="/notifications" element={<MainNotifications />} />
          <Route path="/anouncments" element={<BannerNotifications />} />
          <Route path="/banners" element={<Banner />} />
          <Route path="/vouchers" element={<Vouchers />} />
          {/* Add other admin routes here */}
        </Routes>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminSideMenu;
