import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../theme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import DiscountNotificationBanner from './DiscountNotificationBanner';
import { setLinkingTargetType } from "../../../store/actions/linkingTargetActions";

const pages = [
  {
    'icon': <DirectionsCarOutlinedIcon />,
    'text': 'Passenger Car',
    'linkingTargetType': 'P'
  },
  {
    'icon': <AirportShuttleOutlinedIcon />,
    'text': 'Van',
    'linkingTargetType': 'L'
  },
  {
    'icon': <LocalShippingOutlinedIcon />,
    'text': 'Truck',
    'linkingTargetType': 'C'
  },
  {
    'icon': <MopedOutlinedIcon />,
    'text': 'Motorcycle',
    'linkingTargetType': 'B'
  },
];

const MainMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const targetTypeParam = params.get('linkingTargetType');
    const activeIndex = pages.findIndex(page => page.linkingTargetType === targetTypeParam);
    if (activeIndex !== -1) {
      setActiveIndex(activeIndex);
    }
  }, [location.search]);

  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
    const newLinkingTargetType = pages[index].linkingTargetType;
    dispatch(setLinkingTargetType(newLinkingTargetType));
    navigate(`/?linkingTargetType=${newLinkingTargetType}`);
  };

  return (
    <AppBar position="static" sx={{ boxShadow: 'none', paddingTop: 1, paddingBottom: 1}}>
      <Container maxWidth="xl" sx={{ padding: '0px !important', marginBottom: {xs: '10px', sm: 0}, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ height: '30px', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page, index) => (
            <Button
              key={index}
              sx={{
                my: 0,
                opacity:0.7,
                color: 'white',
                display: 'flex',
                borderRadius: "0",
                alignItems: 'center',
                fontSize: '12px',
                padding: '8px 12px', // Adjust padding to reduce space between icon and text
                ...(index === activeIndex && { backgroundColor: theme.palette.secondary.main,  opacity:1 }),
                '&:hover': {
                  opacity:1,
                  backgroundColor: theme.palette.secondary.main, // Change background color on hover, consider active state
              }
              }}
              onClick={() => handleButtonClick(index)}
            >
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '5px', color: 'white' }}>
                {page.icon}
              </ListItemIcon>
              <Typography variant="inherit">
                {page.text}
              </Typography>
            </Button>
          ))}
        </Box>
        <Box sx={{display: { xs: 'none', md: 'flex' }}}>
          <DiscountNotificationBanner/>
        </Box>
      </Container>
    </AppBar>
  );
}

export default MainMenu;
