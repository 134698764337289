import { Box, Grid, Typography, Paper, MenuList, MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import ReorderIcon from '@mui/icons-material/Reorder';
import BalanceIcon from '@mui/icons-material/Balance';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';

const ClientSideMenu = () => {
  const location = useLocation();

  return (
    <>
      <Box sx={{ width: "100%", border: "4px solid #f1f1f1" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", boxShadow: 'none', textAlign: 'left' }}>
              <MenuList sx={{ ml: 1, mr: 1 }}>
              <MenuItem component={Link} to="/basket" selected={location.pathname === "/basket"}>
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Basket</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/orders" selected={location.pathname === "/orders"}>
                  <ListItemIcon>
                    <ReorderIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>My Orders</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/favorite" selected={location.pathname === "/favorite"}>
                  <ListItemIcon>
                    <GradeOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Favorite</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/balance" selected={location.pathname === "/balance"}>
                  <ListItemIcon>
                    <BalanceIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Balance</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/return-requests" selected={location.pathname === "/return-requests"}>
                  <ListItemIcon>
                    <AssignmentReturnIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Returns and Claims</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/promotions" selected={location.pathname === "/promotions"}>
                  <ListItemIcon>
                    <PostAddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Promotions</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/offers" selected={location.pathname === "/offers"}>
                  <ListItemIcon>
                    <LocalOfferIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 500 }}>Offers</Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ClientSideMenu;
