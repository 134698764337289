import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  Box,
  Pagination,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../../../theme";
import { UsersReport } from "../../../../interfaces/core/UsersReport";
import { getUsersReport } from "../../../../services/core-api";

interface Column {
  id:
    | "name"
    | "surname"
    | "companyName"
    | "city"
    | "totalOrders"
    | "totalAmount"
    | "loginTime";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "surname", label: "Surname", minWidth: 100 },
  {
    id: "companyName",
    label: "Company Name",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "city",
    label: "City",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "totalOrders",
    label: "Total Orders",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "totalAmount",
    label: "Total Amount",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "loginTime",
    label: "Login Time",
    minWidth: 170,
    align: "right",
  },
];

const UsersListReport = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 30;
  const [count, setCount] = React.useState(0);
  const [usersRport, setUsersReport] = React.useState<UsersReport[] | []>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(
    null
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(
    null
  );

  const fetchUsersReport = React.useCallback(async () => {
    try {
      const options = {
        keyword: searchValue,
        from_date: selectedFromDate
          ? selectedFromDate.format("YYYY-MM-DD")
          : "",
        to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "",
        page_number: page
      };
      const response = await getUsersReport(options);
      if(response) {
        console.log('users: ', response);
        setUsersReport(response.users_report);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setUsersReport([]);
      setPage(1);
      setCount(0);
    }
  }, [page, rowsPerPage, searchValue, selectedFromDate, selectedToDate]);

  React.useEffect(() => {
    fetchUsersReport(); // Only call fetchUsers without checking any conditions
  }, [fetchUsersReport]); // Only re-run when fetchUsers changes


  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
    setPage(1); // Reset to first page on date change
  };


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const handleExportExcel = () => {
    const url = `${baseURL}/export-users-report`;
    window.open(url);
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                fontSize: "18px",
                float: "left",
                fontFamily: "Inter",
                fontWeight: 600,
                color: theme.palette.text.primary,
                mb: 3,
              }}
            >
              Users Report List
            </Typography>
            <Button 
              variant="contained"
              sx={{
                float: {
                  xs: "none", 
                  md: "right",
                },
              }}
              onClick={handleExportExcel}
            >
              Export report
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="outlined-basic"
              placeholder="Search Customers Report..."
              variant="outlined"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%", md: "20rem" },
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              InputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: "#d5d5d5 !important", fontSize: "17px" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{display:"flex", justifyContent: "flex-end"}}>
            <DatePicker
              format="MM/DD/YYYY"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              sx={{
                flex: 1,
                bgcolor: "#fff",
                mr: 1,
                "& .MuiOutlinedInput-root": {
                  height: "50px",
                  borderRadius: 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 0,
                },
                "& .MuiInputBase-input": {
                  height: "30px",
                  padding: "0 14px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
              }}
            />
            <DatePicker
              format="MM/DD/YYYY"
              value={selectedToDate}
              onChange={handleToDateChange}
              sx={{
                flex: 1,
                bgcolor: "#fff",
                "& .MuiOutlinedInput-root": {
                  height: "50px",
                  borderRadius: 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 0,
                },
                "& .MuiInputBase-input": {
                  height: "30px",
                  padding: "0 14px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
              }}
            />
            </Box>
          </Grid>
        </Grid>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer
            sx={{ maxHeight: 440, border: "1px solid #f1f1f1", p: 2 }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersRport.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                       
                            <TableCell key={row.id} align={"left"}>
                             {row.name}
                            </TableCell>

                            <TableCell align={"left"}>
                            {row.surname}
                            </TableCell>
                            <TableCell align={"right"}>
                             {row.company}
                            </TableCell>
                            <TableCell align={"right"}>
                             {row.city}
                            </TableCell>
                            <TableCell align={"right"}>
                             {row.count_total_orders}
                            </TableCell>
                            <TableCell align={"right"}>
                            {row.count_total_amount} EUR
                            </TableCell>
                            <TableCell align={"right"}>
                             {row.count_total_login}
                            </TableCell>
                       
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 1 }}>
            <Pagination page={page} count={count} variant="outlined" shape="rounded" onChange={handlePageChange} />
          </Box>
        </Stack>
      </LocalizationProvider>
    </>
  );
};
export default UsersListReport;
