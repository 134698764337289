import React from 'react';
import { Button as MuiButton } from '@mui/material';
import theme from '../../theme';

const Button = (props: any) => {
  const { sx: customSx, hoverDarkColor, ...rest } = props;
  return <MuiButton 
      {...rest}
      sx={{
        bgcolor: theme.palette.primary.main,
        boxShadow: 'none',
        height: '100%',
        '&:hover': {
          boxShadow: 'none',
          bgcolor: hoverDarkColor || theme.palette.primary.dark,
        },
        ...customSx // Merge custom sx prop with default styles
      }}
    />;
};

export default Button;