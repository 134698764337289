import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box,Slide, SlideProps, SnackbarOrigin, Typography } from "@mui/material";
import { PartlistDetailsResponse } from "../../../../interfaces/PartlistDetailsResponse";
import { fetchPartList } from "../../../../services/tecdocService";
import ProductItemPartList from "./ProductItemPartList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.primary,
    padding: "5px !important",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "5px !important",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
}));


interface State extends SnackbarOrigin {
   openNotification: boolean;
 }

const ProductPartList = (props: any) => {
  const { genericArticles } = props;
  const legacyArticleId = genericArticles[0]?.legacyArticleId;
  const linkageTargetType = genericArticles[0]?.linkageTargetTypes[0];
  const [partListDetails, setPartListDetails] = React.useState<
    PartlistDetailsResponse[]
  >([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [hasData, setHasData] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [error, setError] = React.useState<string | null>(null);
 

  React.useEffect(() => {
    setHasData(false);
    let isMounted = true;
    if (isMounted && legacyArticleId && linkageTargetType) {
      (async () => {
        try {
         const response = await fetchPartList(
            linkageTargetType,
            legacyArticleId
          );
          if (!response) return;
          if (response.length === 0) {
            setError('This articles does not have part list.')
          } else {
            setHasData(true);
            setPartListDetails(response);
          }
          
        } catch (error) {
         setError('Not able to fetch partlist for this article. Please try again later.')
        }
      })();
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {hasData && (
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
               <StyledTableCell align="left">Article number</StyledTableCell>
               <StyledTableCell align="left">Article image</StyledTableCell>
              <StyledTableCell align="left">Description of product</StyledTableCell>
              <StyledTableCell align="left">Units</StyledTableCell>
              <StyledTableCell align="right">Price/Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partListDetails.map((row, index) => (
              <ProductItemPartList key={index} articleNumber={row.partlistDetails.articleNo} dataSupplierId={row.partlistDetails.brandNo}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
      {!hasData && (
         <Typography variant="body1" color="info" align="left" 
            sx={{ 
               mt:1,
               ml:2,
               fontSize: "13px",
               fontFamily: "Inter",
               fontWeight: 400,
            }}
         >
            {error}
         </Typography>
      )}
    </Box>
  );
};

export default ProductPartList;
