import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import theme from "../../../theme";

const ShortcutAssemblies = () => {
  const [value, setValue] = React.useState(0);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
  const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null); 
  const [brandId, setBrandId] = React.useState<number | null>(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get('linkageTargetType');
      const linkageTargetId = urlParams.get('linkageTargetId');
      const brandIdParam = urlParams.get("brand");

      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }
  
      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }

      if (brandIdParam) {
        const brandIdUrlParam = parseInt(brandIdParam, 10);
        setBrandId(brandIdUrlParam);
      }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (key: number) => {
    const dynamicUrl = `/search-products?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${brandId ? `&brand=${brandId}` : ''}&assemblyGroupNodeIds=${key}`;
    console.log(`Tab clicked: ${dynamicUrl}`);
    window.location.href = dynamicUrl;
    // Additional functionality can be added here
  };

  const tabs = [
    { key: 100001, label: "Body", icon: "./car_icon.svg" },
    { key: 100002, label: "Engine", icon: "./engine_icon.svg" },
    { key: 100400, label: "Axle Drive", icon: "./axle_drive.png" },
    { key: 100012, label: "Steering", icon: "./steering_icon.svg" },
    { key: 100006, label: "Braking System", icon: "./12213530.png" },
    { key: 100243, label: "Air Conditioning", icon: "./air_condition_icon.png" },
    { key: 100005, label: "Filters", icon: "./car_filter_icon.png" },
    { key: 100016, label: "Belt Drive", icon: "./belt_drive.png" },
    { key: 100007, label: "Cooling System", icon: "./cooling_system.png" },
    { key: 103099, label: "Wheels", icon: "./wheels.svg" },
    { key: 100010, label: "Electrics", icon: "./electrics.png" },
  ];

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="icon label tabs example"
        sx={{
          height: "87px",
          mt: 2,
          mb: 1.3,
          ".MuiTab-wrapper": {
            textTransform: "capitalize !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          ".MuiTabs-indicator": {
            backgroundColor: 'transparent',
          },
          ".MuiButtonBase-root": {
            color: theme.palette.primary.main,
            textTransform: "capitalize !important",
            fontSize: "13px",
            fontFamily: "Inter",
            fontWeight: "normal",
            minHeight: "40px",
            padding: "5px",
            boxShadow: '6px 3px 6px #f1f1f1',
            margin: '4px',
            minWidth: "100px",  // Ensure consistent width
            maxWidth: "120px",  // Limit the maximum width
            flex: "1 1 0",  // Allow flexibility in width
          },
          ".Mui-selected": {
            color: `${theme.palette.secondary.main} !important`,
            border: `1px solid ${theme.palette.secondary.main} !important`,
            fontWeight: 500,
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.key}
            icon={<img style={{width: '30px'}} src={tab.icon} alt={tab.label} />}
            label={tab.label}
            onClick={() => handleTabClick(tab.key)}
          />
        ))}
      </Tabs>
    </>
  );
};

export default ShortcutAssemblies;
