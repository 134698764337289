import * as React from 'react';
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import theme from "../../theme";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import axios, { AxiosError } from 'axios';
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { deleteOffers, getOffers } from '../../services/core-api';
import { OfferRead } from '../../interfaces/core/Offer';
import Alert from '@mui/material/Alert';


interface Data {
   id: number;
   documentNo: string;
   date: string;
   fullName: string;
   exportPdf: string;
 }
 
 function createData(
   id: number,
   documentNo: string,
   date: string,
   fullName: string,
   exportPdf: string
 ): Data {
   return {
     id,
     documentNo,
     date,
     fullName,
     exportPdf
   };
 }
 
 const rows = [
   createData(1, "000003434534", "01.02.2024", "Astrit Kermeni", ''),
   createData(2, "000003434534", "01.02.2024", "Arxhent Haxholli", ''),
   createData(3, "000003434534", "01.02.2024", "Astrit Kermeni", ''),
   createData(4, "000003434534", "01.02.2024", "Arxhent Haxholli", ''),
   createData(5, "000003434534", "01.02.2024", "Astrit Kermeni", ''),
   createData(6, "000003434534", "01.02.2024","Arxhent Haxholli", ''),
   createData(7, "000003434534", "01.02.2024", "Astrit Kermeni", '')
 ];

 
 type Order = "asc" | "desc";
 
 interface HeadCell {
   disablePadding: boolean;
   id: keyof Data;
   label: string;
   numeric: boolean;
 }
 
 const headCells: readonly HeadCell[] = [
   {
     id: "documentNo",
     numeric: false,
     disablePadding: true,
     label: "Document No",
   },
   {
     id: "date",
     numeric: false,
     disablePadding: false,
     label: "Date",
   },
   {
     id: "fullName",
     numeric: false,
     disablePadding: false,
     label: "Full Name",
   },
   {
      id: "exportPdf",
      numeric: false,
      disablePadding: false,
      label: "Export",
    }
 ];
 
 interface EnhancedTableProps {
   numSelected: number;
   onRequestSort: (
     event: React.MouseEvent<unknown>,
     property: keyof Data
   ) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
 }
 
 function EnhancedTableHead(props: EnhancedTableProps) {
   const {
     onRequestSort,
   } = props;
 
   return (
     <TableHead sx={{bgcolor:"#f1f1f1"}}>
       <TableRow>
         <TableCell padding="checkbox"></TableCell>
         {headCells.map((headCell) => (
           <TableCell
             key={headCell.id}
             align={headCell.numeric ? "right" : "left"}
             sx={{ fontSize: "15px", fontFamily: "Inter", fontWeight: 600, pb: '4px'}}
           >
             {headCell.label}
           </TableCell>
         ))}
       </TableRow>
     </TableHead>
   );
 }
 
 interface EnhancedTableToolbarProps {
   numSelected: number;
   offerIds: number[]
 }

 const handleDeleteOffers = async (offerIds: any) => {
  try {
    await deleteOffers(offerIds);
    window.location.reload();
  } catch (error) {
    console.log(error)
  }
 }
 
 function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
   const { numSelected, offerIds } = props;
 
   return (
     <Toolbar
       sx={{
         minHeight: "40px !important",
         pl: { sm: 0.5 },
         pr: { xs: 1, sm: 1 },
         ...(numSelected > 0 && {
           bgcolor: (theme) =>
             alpha(
               theme.palette.primary.main,
               theme.palette.action.activatedOpacity
             ),
         }),
       }}
     >
       {numSelected > 0 ? (
         <>
           <Tooltip title="Delete">
             <IconButton
              onClick={() => handleDeleteOffers(offerIds)}
             >
               <DeleteIcon />
             </IconButton>
           </Tooltip>
           <Typography
             sx={{ fontSize: "13px", fontFamily: "Inter" }}
             color="inherit"
             variant="subtitle1"
             component="div"
           >
             Delete {numSelected} items selected
           </Typography>
         </>
       ) : (
         <></>
       )}
     </Toolbar>
   );
 }

const OfferList = () => {
   const baseURL = process.env.REACT_APP_API_URL;
   const frontendURL = process.env.REACT_APP_FRONTEND_URL;
   const [offers, setOffers] = React.useState<OfferRead[] | null>([]);
   const [totalItems, setTotalItems] = React.useState(0)
   const [error, setError] = React.useState('');
   const [offerIds, setOfferIds] = React.useState<number[]>([]);
   const [fullName, setFullName] = React.useState('');
   const [order, setOrder] = React.useState<Order>("asc");
   const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
   const [selected, setSelected] = React.useState<readonly number[]>([]);
   const [page, setPage] = React.useState(1);
   const [dense, setDense] = React.useState(false);
   const [count, setCount] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);

   React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      (async () => {
        try {
          const options = {
            full_name: fullName,
            page_number: page
          }
          const data = await getOffers(options);
          if (data) {
            setOffers(data.offers);
            setPage(data.page_number);
            setTotalItems(data.total_offers);
            setCount( Math.ceil(data.total_offers / rowsPerPage));
            setError('');
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.data && error.response.data.detail) {
              setError(error.response.data.detail);
            } else {
              setError('An unexpected error occurred.');
            }
          } else {
            setError('An unexpected error occurred.');
          }
        }
      })()
    }
    return () => {
      isMounted = false;
    };
  },[fullName, page])
 
   const handleRequestSort = (
     event: React.MouseEvent<unknown>,
     property: keyof Data
   ) => {
     const isAsc = orderBy === property && order === "asc";
     setOrder(isAsc ? "desc" : "asc");
     setOrderBy(property);
   };
 
   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
     if (event.target.checked) {
       const newSelected = rows.map((n) => n.id);
       setSelected(newSelected);
       return;
     }
     setSelected([]);
   };
 
   const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
     const selectedIndex = selected.indexOf(id);
     let newSelected: number[] = [];
 
     if (selectedIndex === -1) {
       newSelected = newSelected.concat(selected, id);
     } else if (selectedIndex === 0) {
       newSelected = newSelected.concat(selected.slice(1));
     } else if (selectedIndex === selected.length - 1) {
       newSelected = newSelected.concat(selected.slice(0, -1));
     } else if (selectedIndex > 0) {
       newSelected = newSelected.concat(
         selected.slice(0, selectedIndex),
         selected.slice(selectedIndex + 1)
       );
     }
     setOfferIds(newSelected);
     setSelected(newSelected);
   };
 
   const handleChangePage = (event: unknown, newPage: number) => {
     setPage(newPage);
   };

   const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };
 
   const isSelected = (id: number) => selected.indexOf(id) !== -1;

   const handleExportPdf = (id: number) => {
    const url = `${baseURL}/offer/${id}/export`;
    window.open(url);
   }

   return (
      <>
      <Box sx={{mt:1, border: "1px solid #f1f1f1"}}>
              <Box sx={{bgcolor:"#f1f1f1", p:2}}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "20px", pb: 0 }}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Offers List
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: 1,
                      }}
                    >
                      <Box>
                        <TextField 
                           id="outlined-basic" 
                           placeholder="Search by Full Name" 
                           value={fullName}
                           onChange={handleFullNameChange}
                           variant="outlined"
                           sx={{
                              bgcolor: "#fff",
                              mr: 1,
                              width: "160px",
                              "& .MuiOutlinedInput-root": {
                                height: "30px",
                                borderRadius: 0,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: 0,
                              },
                              "& .MuiInputBase-input": {
                                height: "30px",
                                padding: "0 14px",
                                fontSize: "13px",
                                fontFamily: "Inter",
                              },
                            }}
                        />
                      </Box>
                    </Box>
                  </Grid>
              </Grid>
              </Box>
              <Box sx={{p:2, pb:0}}>
              <Paper sx={{ width: "100%", mb: 2, mt:2, boxShadow: "none" }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {offers && offers.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onClick={(event) => handleClick(event, row.id)}
                                sx={{
                                  color: theme.palette.secondary.main,
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                    sx={{
                                    textAlign: 'left',
                                    fontSize: '13px',
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    }}
                                 >
                                    {row.id}
                                 </Typography>
                            </TableCell>
                            <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.created_at.slice(8,10)}/{row.created_at.slice(5,7)}/{row.created_at.slice(0,4)} {row.created_at.slice(11,13)}:{row.created_at.slice(14,16)}
                           </TableCell>
                           <TableCell align="left"
                              sx={{
                                 textAlign: 'left',
                                 fontSize: '13px',
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 }}
                            >
                              {row.full_name}
                           </TableCell>
                           <TableCell align="left">
                                <Button
                                  sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    border: "1px solid #f1f1f1",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => handleExportPdf(row.id)}
                                >
                                  Export PDF
                                </Button>
                              </TableCell>
                          </TableRow>
                        );
                      })}
                      
                    </TableBody>
                  </Table>
                  { error && (
                    <Alert severity="error" sx={{fontFamily:"Inter"}}>
                      {error}
                    </Alert>
                  )}
                </TableContainer>
                <EnhancedTableToolbar offerIds={offerIds} numSelected={selected.length} />
              </Paper>
              </Box>

            </Box>
              
            <Box sx={{display:"flex", justifyContent: "flex-end", mt:2}}>
              <Stack spacing={2}>
                <Pagination 
                  count={count} 
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined" 
                  shape="rounded" 
                />
              </Stack>
            </Box>
      </>
   )
}
export default OfferList;