import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Skeleton } from '@mui/material';
import { GenericArticleFacetCounts } from '../../interfaces/GenericArticleFacetCounts';
import theme from '../../theme';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ProductCategories = (props: any) => {
   const { genericArticles, onCategoryChange } = props;
   const [checkedItems, setCheckedItems] = React.useState<Record<number, boolean>>({});
   const [disabled, setDisabled] = React.useState(false);
   const [searchTerm, setSearchTerm] = React.useState('');
 
   // Ensure genericArticles is defined and filter articles based on search term
   const filteredArticles = genericArticles 
     ? genericArticles.filter((article: GenericArticleFacetCounts) =>
         article.genericArticleDescription.toLowerCase().includes(searchTerm.toLowerCase())
       )
     : [];
 
   React.useEffect(() => {
     const urlParams = new URLSearchParams(window.location.search);
     const categoryIdParam = urlParams.get('category');
 
     if (categoryIdParam) {
       const categoryId = parseInt(categoryIdParam, 10);
       setCheckedItems({ [categoryId]: true });
       setDisabled(true);
     }
   }, [genericArticles]);
 
   const handleChange = (article: GenericArticleFacetCounts) => {
     const categoryId = article.genericArticleId;
     const updatedCheckedItems = { ...checkedItems };
     updatedCheckedItems[categoryId] = !updatedCheckedItems[categoryId];
 
     if (!updatedCheckedItems[categoryId]) {
       const urlParams = new URLSearchParams(window.location.search);
       urlParams.delete('category');
       onCategoryChange(null);
       const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
 
       window.history.replaceState({}, '', newUrl);
       setDisabled(false);
     } else {
       const urlParams = new URLSearchParams(window.location.search);
       urlParams.set('category', categoryId.toString());
       onCategoryChange(categoryId);
       const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
 
       window.history.replaceState({}, '', newUrl);
       setDisabled(true);
     }
 
     setCheckedItems(updatedCheckedItems);
   };

  return (
    <>
      {genericArticles ? (
        <Box sx={{ minHeight: '300px', width: '100%', border: '4px solid #f1f1f1' }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Item sx={{ bgcolor: '#f1f1f1', borderRadius: 0 }}>
                <Typography
                  variant="body1"
                  align="left"
                  sx={{
                    color: theme.palette.text.primary,
                    marginTop: '4px',
                    marginBottom: '4px',
                    fontSize: '15px',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                  }}
                >
                  Filter articles by category
                </Typography>
              </Item>

              {/* Search input */}
              <Item>
                <TextField
                  placeholder="Search categories"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                     sx: {
                       height: '40px',  // Adjust height
                       padding: '0 8px', // Adjust padding inside the input
                     },
                   }}
                   sx={{
                     '& .MuiOutlinedInput-root': {
                       fontSize: '14px', // Adjust font size if needed
                       '& input': {
                         padding: '5px 8px', // Adjust padding inside the input
                       },
                     },
                   }}
                />
              </Item>

              {/* Always visible checkboxes */}
              <Item sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="flex-start"
                  sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '300px' }}
                >
                  {filteredArticles.map((article: GenericArticleFacetCounts, index: number) => (
                    <Grid item xs={12} key={index}>
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontFamily: 'Inter',
                              fontWeight: 'normal',
                            }}
                          >
                            {article.genericArticleDescription} ({article.count})
                          </Typography>
                        }
                        labelPlacement="end"
                        control={
                          <Checkbox
                            checked={checkedItems[article.genericArticleId] || false}
                            onChange={() => handleChange(article)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={disabled && !checkedItems[article.genericArticleId]}
                            sx={{
                              textTransform: 'capitalize',
                              color: theme.palette.secondary.main,
                              '&.Mui-checked': {
                                color: theme.palette.secondary.main,
                              },
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          />
                        }
                        sx={{
                          color: theme.palette.text.primary,
                          width: '100%',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <SkeletonLoading />
      )}
    </>
  );
};

const SkeletonLoading = () => (
  <Box sx={{ width: '100%', border: '4px solid #f1f1f1' }}>
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Item sx={{ bgcolor: '#f1f1f1', borderRadius: 0 }}>
          <Skeleton animation="wave" sx={{ width: '80px' }} />
        </Item>
        <Item sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Grid container spacing={0} justifyContent="flex-start" sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '300px' }}>
            {[...Array(5)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  label={<Skeleton animation="wave" sx={{ width: '80px' }} />}
                  control={<Skeleton animation="wave" sx={{ width: '15px', height: '20px', ml: 2, mr: 1 }} />}
                  sx={{
                    color: theme.palette.text.primary,
                    width: '100%',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Item>
      </Grid>
    </Grid>
  </Box>
);

export default ProductCategories;
