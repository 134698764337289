import { Box, Grid, Stack, Breadcrumbs, Typography, Link} from "@mui/material";
import * as React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Header from "../../components/common/header/Header";
import theme from "../../theme";
import ClientSideMenu from "./ClientSideMenu";
import OfferList from "../../components/offers/OfferList";
import CreateOffer from "../../components/offers/CreateOffer";
import Footer from "../../components/common/Footer/Footer";


const OffersPage = () => {

  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
          minHeight: '71vh'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ClientSideMenu />
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box
              sx={{
                bgcolor: "#f1f1f1",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: 1,
                borderTop: `2px solid ${theme.palette.primary.dark}`,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "13px", pb: "4px" }}
                  >
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          sx={{ color: "black" }}
                          fontSize="small"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          ml: 1,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >
                        Home
                      </Link>
                      ,
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Offers
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{width: "100%"}}>
              <CreateOffer/>
            </Box>
            <Box sx={{ width: "100%" }}>
              <OfferList />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </>
  );
};
export default OffersPage;
