import * as React from "react";
import { styled } from "@mui/material/styles";
import { useSelector  } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { CircularProgress, Popper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../../theme";
import Button from "../../ui/Button";
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getlinkingVehiclesByEngineCode, getlinkingVehiclesByVIN } from "../../../services/tecdocService";
import {LinkingTargetState } from "../../../store/reducers/linkingTargetReducer";
import { LinkageVehicle } from "../../../interfaces/LinkageVehicle";
import { VehicleDetail } from "../../../interfaces/VehicleDetail";

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '300px',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #f1f1f1',
  boxShadow: 24,
  p: 4,
  overflow: 'hidden',
};

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none !important",
  borderRadius: 0,
  backgroundColor: "#F1F1F1 !important",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

interface SearchType {
  label: string;
  number: number;
}

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const VehicleIdentification = () => {
  const [openSearchType, setOpenSearchType] = React.useState(false);
  const linkingTargetType = useSelector((state: { linkingTarget: LinkingTargetState}) => state.linkingTarget.linkingTargetType);
  const [selectedSearchType, setSelectedSearchType] = React.useState<SearchType>({label: 'VIN', number: 1});
  const [input, setInput] = React.useState("");
  const [linkageVehicles, setLinkageVehicles] = React.useState<LinkageVehicle[] | null>([]);
  const [linkageVehiclesByCode, setLinkageVehiclesByCode] = React.useState<VehicleDetail[] | null>([]);
  const [openSearchByCountry, setOpenSearchByCountry] = React.useState(false);

  const [options, setOptions] = React.useState<readonly SearchType[]>([]);
  const loadingSearchType = openSearchType && options.length === 0;
  const loadingSearchByCountry = openSearchByCountry && options.length === 0;

  const [errorVin, setErrorVin] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // Autocomplete for Search by VIN number, Engine code
  React.useEffect(() => {
    let activeSearchType = true;

    if (!loadingSearchType) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (activeSearchType) {
        setOptions([...searchTypes]);
      }
    })();

    return () => {
      activeSearchType = false;
    };
  }, [loadingSearchType]);

  React.useEffect(() => {
    if (!openSearchType) {
      setOptions([]);
    }
  }, [openSearchType]);

  // Autocomplete for Search by Country
  React.useEffect(() => {
    let activeSearchCountry = true;

    if (!loadingSearchByCountry) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (activeSearchCountry) {
        setOptions([...searchTypes]);
      }
    })();

    return () => {
      activeSearchCountry = false;
    };
  }, [loadingSearchByCountry]);

  React.useEffect(() => {
    if (!openSearchByCountry) {
      setOptions([]);
    }
  }, [openSearchByCountry]);

  const hanldeSearchTypeChange = (event: React.ChangeEvent<{}>, searchType: SearchType | null) => {
    if (searchType){
      setSelectedSearchType(searchType);
    }
  }

  const handleSearchType = async () => {
   if (input && selectedSearchType && selectedSearchType.number === 1) {
    try {
      const linkageVehicles = await getlinkingVehiclesByVIN(input);
      if (linkageVehicles) {
        setLinkageVehicles(linkageVehicles);
        setOpen(true);
        setErrorVin('');
      }
    } catch (error) {
      if (error instanceof Error) {
          setErrorVin('VIN not found!'); 
      } else {
        setErrorVin('VIN not found!'); 
      }
    }
    
   } else if (input && selectedSearchType && selectedSearchType.number === 2) {
    try {
      const linkageVehicles = await getlinkingVehiclesByEngineCode(input);
      if (linkageVehicles) {
        console.log(linkageVehicles);
        setLinkageVehiclesByCode(linkageVehicles);
        setOpen(true);
        setErrorVin('');
      }
    }catch (error){}
   }
  }

  const handleRowClick = (vehicle: any) => {
    if (vehicle.carId){
    const url = `/assemblies?linkageTargetId=${vehicle.carId}&linkageTargetType=${linkingTargetType}`;
      window.location.href = url;
    } else {
      const url = `/assemblies?linkageTargetId=${vehicle.linkageTargetId}&linkageTargetType=${vehicle.linkageTargetType}`;
      window.location.href = url;
    }
  };

  return (
    <div>
      <Item
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <img
          src="/search_plus.svg"
          alt="Search car icon"
          style={{ width: "30px", marginRight: "8px" }} // Added margin for spacing
        />
        <Typography variant="body1" align="left" sx={{ fontSize: "13px" }}>
          Search by VIN or Engine Code
        </Typography>
      </Item>
      <Box sx={{display: "flex", justifyContent:"flex-start", padding: {xs: 0, sm: 0, md: 1}}}>
        <Box flex={1}>
        <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                width: "40px",
                fontSize: "1.2rem",
                height: "40px",
                display: "flex",
                float: "left",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              1
            </Box>
            <TextField
              id="outlined-basic"
              label={"Enter " + selectedSearchType.label}
              value={input}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInput(event.target.value);
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 0,
                height: "100%",
                width: { xs: "70%", sm: "60%", md: "75%" },
                float: "left",
                top: "0px",
                transform: "translate(17px, 5px), scale(1) !important",
              }}
              InputLabelProps={{
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  top: '-6px',
                  transform: "translate(17px, 5px), scale(1) !important",
                },
              }}
              InputProps={{
                sx: {
                  color: theme.palette.text.primary,
                  borderRadius: 0,
                  fontSize: "13px",
                  height: "40px",
                  top: "0px",
                  transform: "translate(17px, 5px), scale(1) !important",
                },
              }}
            />
        </Box>
        <Box flex={1}>
        <Autocomplete
              disablePortal
              id="searchType"
              open={openSearchType}
              onOpen={() => {
                setOpenSearchType(true);
              }}
              onClose={() => {
                setOpenSearchType(false);
              }}
              ListboxProps={{
                style: { maxHeight: 200, overflowY: "auto" },
              }}
              PaperComponent={(props) => {
                const anchorEl = document.getElementById("searchType");
                const width = anchorEl
                  ? `${anchorEl.offsetWidth + 60}px`
                  : "auto";

                return (
                  <Popper
                    {...props}
                    open={openSearchType}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [-7, -10], // Adjust this value to move the Popper up or down
                        },
                      },
                    ]}
                    style={{
                      zIndex: 9999,
                      border: "1px solid #f1f1f1",
                      boxShadow: "2px 3px 3px #ccc",
                      position: "relative",
                      background: "#fff",
                      width: width,
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                // Do not destructure `key` from `props`
                return (
                  <li
                    {...props}  // Spread all props directly onto the <li> element
                    key={`${option.number}`}  // Set the key prop separately
                    style={{
                      fontSize: "13px",
                      fontFamily: "Inter"
                    }}
                  >
                    {option.label}
                  </li>
                );
              }}
              
              value={selectedSearchType}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              getOptionLabel={(option) => option.label}
              options={options}
              loading={loadingSearchType}
              sx={{
                width: '100%',
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "40px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              onChange={hanldeSearchTypeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"By " + selectedSearchType.label}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      height: "100%",
                      top: "-6px",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: 0, // Adjust the height as needed
                      fontSize: "13px",
                      fontFamily: "Inter",
                      height: '40px'
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loadingSearchType ? (
                          <CircularProgress color="inherit" size={15} sx={{marginTop: '-15px'}} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            ></Autocomplete>
        </Box>
      </Box>
      <Box flex={1}>
        <Typography sx={{textAlign: "left", fontSize: "13px", fontFamily: "Inter", color:"red", ml:1}}>
          {errorVin}
        </Typography>
      </Box>
      <Box flex={1}>
        <Button
          sx={{
            width: "100%",
            mt:1,
            borderRadius: 0,
            textTransform: 'capitalize',
            backgroundColor: "secondary.main",
            color: "text.secondary",
            "&:hover": {
              backgroundColor: "secondary.main",
            }
          }}
          onClick={handleSearchType}
        >
          Search
        </Button>

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>TecdDoc type no.</TableCell>
                    <TableCell align="left" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>Description</TableCell>
                    <TableCell align="right" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>Year</TableCell>
                    <TableCell align="right" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>kW</TableCell>
                    <TableCell align="right" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>HP</TableCell>
                    <TableCell align="right" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>Capacity</TableCell>
                    <TableCell align="right" sx={{pb:1, fontFamily: "Inter", fontWeight: 500}}>Design</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                sx={{overflowY: 'auto', height: '200px'}}>
                  {linkageVehicles && linkageVehicles.map((vehicle) => (
                    <TableRow
                      hover
                      key={vehicle.carId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 },  cursor: 'pointer' }}
                      onClick={() => handleRowClick(vehicle)}
                    >
                      <TableCell align="left" sx={{ fontFamily: "Inter", fontSize: '13px' }}>
                        {vehicle?.carId ? vehicle.carId : 'N/A'}
                      </TableCell>

                      <TableCell align="left" sx={{ fontFamily: "Inter", fontSize: '13px' }}>
                        {vehicle?.vehicleDetails?.manuName ? vehicle.vehicleDetails.manuName : ''} 
                        {vehicle?.vehicleDetails?.modelName ? vehicle.vehicleDetails.modelName : ''} 
                        {vehicle?.vehicleDetails?.typeName ? vehicle.vehicleDetails.typeName : ''}
                      </TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>
                        {vehicle.vehicleDetails?.yearOfConstrFrom
                          ? `${vehicle.vehicleDetails.yearOfConstrFrom.toString().slice(4)}.${vehicle.vehicleDetails.yearOfConstrFrom.toString().slice(0, 4)}`
                          : 'N/A'
                        }
                        -
                        {vehicle.vehicleDetails?.yearOfConstrTo
                          ? `${vehicle.vehicleDetails.yearOfConstrTo.toString().slice(4)}.${vehicle.vehicleDetails.yearOfConstrTo.toString().slice(0, 4)}`
                          : 'N/A'
                        }
                      </TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.vehicleDetails?.powerKwTo ? vehicle.vehicleDetails.powerKwTo : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.vehicleDetails?.powerHpTo ? vehicle.vehicleDetails.powerHpTo : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.vehicleDetails?.cylinderCapacityCcm ? vehicle.vehicleDetails.powerHpTo : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.vehicleDetails?.constructionType ? vehicle.vehicleDetails.powerHpTo : 'N/A'}</TableCell>
                    </TableRow>
                  ))}

                  {linkageVehiclesByCode && linkageVehiclesByCode.map((vehicle) => (
                    <TableRow
                      hover
                      key={vehicle.linkageTargetId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 },  cursor: 'pointer' }}
                      onClick={() => handleRowClick(vehicle)}
                    >
                      <TableCell align="left" sx={{ fontFamily: "Inter", fontSize: '13px' }}>
                        {vehicle?.linkageTargetId ? vehicle.linkageTargetId : 'N/A'}
                      </TableCell>

                      <TableCell align="left" sx={{ fontFamily: "Inter", fontSize: '13px' }}>
                        {vehicle?.mfrName ? vehicle.mfrName : ''} {''} 
                        {vehicle?.vehicleModelSeriesName ? vehicle.vehicleModelSeriesName : ''} {''}
                        {vehicle?.description ? vehicle.description : ''}
                      </TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>
                        {vehicle.beginYearMonth ? vehicle.beginYearMonth : 'N/A'}
                        -
                        {vehicle.endYearMonth ? vehicle.endYearMonth : 'N/A'}
                      </TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.kiloWattsTo ? vehicle.kiloWattsTo : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.horsePowerTo ? vehicle.horsePowerTo : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.cylinders ? vehicle.cylinders : 'N/A'}</TableCell>
                      <TableCell align="right" sx={{fontFamily: "Inter", fontSize: '13px'}}>{vehicle.bodyStyle ? vehicle.bodyStyle : 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
          </Modal>
        </div>
      </Box>
      <Item
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <img
          src="/search_plus.svg"
          alt="Search car icon"
          style={{ width: "30px", marginRight: "8px" }} // Added margin for spacing
        />
        <Typography variant="body1" align="left" sx={{ fontSize: "13px" }}>
          Select country code or enter IT number plate
        </Typography>
      </Item>
      <Box sx={{display: "flex", justifyContent:"flex-start", padding: {xs: 0, sm: 0, md: 1}}}>
        <Box flex={1}>
            <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                width: "40px",
                fontSize: "1.2rem",
                height: "40px",
                display: "flex",
                float: "left",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              2
            </Box>
            <Autocomplete
              disabled
              id="searchCountry"
              open={openSearchByCountry}
              onOpen={() => {
                setOpenSearchByCountry(true);
              }}
              onClose={() => {
                setOpenSearchByCountry(false);
              }}
              ListboxProps={{
                style: { maxHeight: 200, overflowY: "auto" },
              }}
              PaperComponent={(props) => {
                const anchorEl = document.getElementById("searchCountry");
                const width = anchorEl
                  ? `${anchorEl.offsetWidth + 60}px`
                  : "auto";

                return (
                  <Popper
                    {...props}
                    open={openSearchByCountry}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [-7, -10],
                        },
                      },
                    ]}
                    style={{
                      zIndex: 9999,
                      border: "1px solid #f1f1f1",
                      boxShadow: "2px 3px 3px #ccc",
                      position: "relative",
                      background: "#fff",
                      width: width,
                    }}
                  />
                );
              }}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              getOptionLabel={(option) => option.label}
              options={options}
              loading={loadingSearchByCountry}
              sx={{
                width: { xs: "70%", sm: "60%", md: "75%" },
                float: "left",
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "40px",
                  marginTop: "-15px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      height: "100%",
                      top: "-6px",
                      transform: "translate(24px, 12px), scale(1) !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: 0, // Adjust the height as needed
                      height: '40px'
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loadingSearchByCountry ? (
                          <CircularProgress color="inherit" size={15} sx={{marginTop: '-15px'}} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            ></Autocomplete>
          </Box>
          <Box flex={1}>
            <TextField
              disabled
              id="outlined-basic"
              label="Enter IT number plate"
              sx={{
                backgroundColor: "#fff",
                borderRadius: 0,
                height: "100%",
                width: { xs: "90%", sm: "90%", md: "100%" },
                float: 'right',
                top: "0px",
                transform: "translate(17px, 5px), scale(1) !important",
              }}
              InputLabelProps={{
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  top: '-6px',
                  transform: "translate(17px, 5px), scale(1) !important",
                },
              }}
              InputProps={{
                sx: {
                  color: theme.palette.text.primary,
                  borderRadius: 0,
                  fontSize: "13px",
                  height: "40px",
                  top: "0px",
                  transform: "translate(17px, 5px), scale(1) !important",
                },
              }}
            />
            
          </Box>
        </Box>
        <Box flex={1}>
        <Button
        disabled
          sx={{
            width: "100%",
            mt:1,
            borderRadius: 0,
            textTransform: 'capitalize',
            backgroundColor: "secondary.main",
            color: "text.secondary",
            ":hover": {
              backgroundColor: "secondary.main",
            }
          }}
        >
          Search
        </Button>
      </Box>
    </div>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const searchTypes = [
  { label: " VIN", number: 1 },
  { label: " Engine Code", number: 2 },
];

export default VehicleIdentification;
