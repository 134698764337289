import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";
import { AssemblyGroup } from "../../../interfaces/AssemblyGroup";
import { getAssemblyGroupFacets } from "../../../services/tecdocService";

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const SearchLiveAssemblyGroups = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [assemblyGroupFacets, setAssemblyGroupFacets] = React.useState<
    AssemblyGroup[]
  >([]);
  const loading = open && assemblyGroupFacets.length === 0;
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(
    null
  );
  const [linkageTargetType, setLinkageTargetType] = React.useState<
    string | null
  >(null);
  const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<
    number | null
  >(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const linkageTargetType = urlParams.get("linkageTargetType");
    const linkageTargetId = urlParams.get("linkageTargetId");
    const assemblyGroupNodeIds = urlParams.get("assemblyGroupNodeIds");
    const brandId = urlParams.get("brand");

    if (linkageTargetId) {
      const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
      setLinkageTargetId(linkageTargetIdUrlParam);
    }

    if (linkageTargetType) {
      setLinkageTargetType(linkageTargetType);
    }

    if (brandId) {
      const brandIdUrlParam = parseInt(brandId, 10);
      setBrandId(brandIdUrlParam);
    }

    if (assemblyGroupNodeIds) {
      const assemblyGroupNodeIdsUrlParam = parseInt(assemblyGroupNodeIds, 10);
      setAssemblyGroupNodeIds(assemblyGroupNodeIdsUrlParam);
    }
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!loading || linkageTargetType === null || linkageTargetId === null) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        try {
          const options = {
            brand: brandId,
            maxDepth: 10,
            assemblyGroupNodeIds: assemblyGroupNodeIds,
          };
          const response = await getAssemblyGroupFacets(
            linkageTargetType,
            linkageTargetId,
            options
          );
          if (response) {
            const assemblyGroupFacets: AssemblyGroup[] =
              response.assemblyGroupFacets.counts;
            if (assemblyGroupNodeIds) {
              const filteredAssemblyGroupFacets = assemblyGroupFacets.filter(
                (facet: { parentNodeId: number }) =>
                  facet.parentNodeId === assemblyGroupNodeIds
              );

              // Extract nodeIds from the filtered objects
              const parentNodeIds = filteredAssemblyGroupFacets.map(
                (facet: { assemblyGroupNodeId: number }) => facet.assemblyGroupNodeId
              );

              // Second filter: objects with parentNodeId in the parentNodeIds array
              const additionalFilteredFacets = assemblyGroupFacets.filter(
                (facet: { parentNodeId: number }) => parentNodeIds.includes(facet.parentNodeId)
              );

              const parentNodeIds2 = additionalFilteredFacets.map(
                (facet: { assemblyGroupNodeId: number }) => facet.assemblyGroupNodeId
              );

              // third filter: objects with parentNodeId in the parentNodeIds array
              const additionalFilteredFacets2 = assemblyGroupFacets.filter(
                (facet: { parentNodeId: number }) => parentNodeIds2.includes(facet.parentNodeId)
              );

              // Combine both sets of filtered objects
              const combinedFilteredFacets = [...filteredAssemblyGroupFacets, ...additionalFilteredFacets, ...additionalFilteredFacets2];

              setAssemblyGroupFacets(combinedFilteredFacets);
            } else {
              setAssemblyGroupFacets(assemblyGroupFacets);
            }
          }
        } catch (error) {}
      }
    })();

    return () => {
      active = false;
    };
  }, [
    assemblyGroupNodeIds,
    brandId,
    linkageTargetId,
    linkageTargetType,
    loading,
  ]);

  React.useEffect(() => {
    if (!open) {
      setAssemblyGroupFacets([]);
    }
  }, [open]);

  const handleClickCategory = async (category: any) => {
    if (category) {
      const dynamicUrl = `/search-products?linkageTargetType=${category.assemblyGroupType}&linkageTargetId=${linkageTargetId}&assemblyGroupNodeIds=${category.assemblyGroupNodeId}`;
      window.location.href = dynamicUrl;
    }
  }

  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: 200 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) =>
          option.assemblyGroupName === value.assemblyGroupName
        }
        getOptionKey={(option) => option.assemblyGroupNodeId}
        getOptionLabel={(option) => option.assemblyGroupName}
        options={assemblyGroupFacets}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ color: "black", fontSize: "13px", fontFamily: "Inter" }}
            {...props}
            onClick={() => handleClickCategory(option)}
          >
            {option.assemblyGroupName}
          </Box>
        )}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select product groups"
            InputLabelProps={{
              ...params.InputLabelProps,
              sx: {
                color: theme.palette.text.primary,
                borderRadius: 0,
                fontSize: "13px",
                fontFamily: "Inter",
                height: "100%",
                top: "-6px",
                transform: "translate(14px, 1px), scale(1) !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                height: "30px",
                padding: "0px !important",
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 400,
                borderRadius: 0,
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={15}
                      style={{ marginTop: "-6px", marginRight: "40px" }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default SearchLiveAssemblyGroups;
