import { Box, Grid, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import * as React from "react";
import theme from "../../../theme";

const Footer = () => {
  return (
    <footer>
        <Box
          sx={{
            marginTop: "auto",
            background: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12} sm={6} md={7}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  &copy; {new Date().getFullYear()} All rights reserved |
                  <Link
                    href="https://www.tecmotion.co"
                    target="_blank"
                    rel="noopener"
                    sx={{ ml: 1, color: "#fff" }}
                  >
                    <img
                  src="./tecmotion_white_logo.png"
                  alt="Tecm Logo"
                  style={{
                    width: '100px'
                  }}
                />
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} sx={{textAlign: {sm: 'left', md: 'right'}}}>
                <img 
                  src="./TecDoc-white-logo.png"
                  alt="TecDoc Logo"
                  style={{
                    width: '100px'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </footer>
  );
};

export default Footer;
