import * as React from "react";
import { useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Logout } from "@mui/icons-material";
import Button from "../../ui/Button";
import { Divider } from "@mui/material";
import theme from "../../../theme";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link, useNavigate } from 'react-router-dom';
import { changeRetailPrice, getUser } from "../../../services/core-api";
import { toggleRetailPrice } from "../../../store/actions/priceReducerAction";


const UserDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [retailPrice, setRetailPrice] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          const userData = JSON.parse(user);
          const response = await getUser(userData.id);
          if (response) {
            setRetailPrice(response.retailPrice);
            dispatch(toggleRetailPrice(response.retailPrice));
          }
        }
      }catch (error){}
    })()
  },[dispatch])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token"); 
    localStorage.removeItem("user"); 
    navigate("/login");
  };

 
  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    try{
      const response = await changeRetailPrice();
      if (response ) {
        const newRetailPriceStatus = !retailPrice; 
        setRetailPrice(newRetailPriceStatus);
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(toggleRetailPrice(newRetailPriceStatus));
      }
    }catch (error){}
  };

  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "block" } }}
      >
        <AppBar position="static" sx={{ boxShadow: "none", height: "47px" }}>
          <Toolbar sx={{ minHeight: "45px !important", padding: '0 !important' }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            <Button
              color="inherit"
              onClick={handleClick}
              size="small"
              sx={{ 
                ml: 2, 
                display: "flex", 
                borderRadius:0,
                alignItems: "center",
                '&:hover': {
                  background: theme.palette.secondary.main
                }
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <PermIdentityIcon />
              <Typography
                variant="caption"
                color="text.secondary"
                component="div"
                sx={{ marginLeft: 1 }}
              >
                My Aftermarket
              </Typography>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose} component={Link} to="/orders" sx={{ fontSize: "13px", fontFamily: "Inter" }}>My Orders</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/balance" sx={{ fontSize: "13px", fontFamily: "Inter" }}>Balance</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/return-requests" sx={{ fontSize: "13px", fontFamily: "Inter" }}>Returns and Claims</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/favorite" sx={{ fontSize: "13px", fontFamily: "Inter" }}>Favorite Products</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/promotions" sx={{ fontSize: "13px", fontFamily: "Inter" }}>Promotions</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/offers" sx={{ fontSize: "13px", fontFamily: "Inter" }}>Offers</MenuItem>
        <MenuItem>
          <FormControlLabel 
            control={
              <Switch 
                size="small" 
                checked={retailPrice}
                onChange={handleSwitchChange}
              />
            } 
            label="Retail price"
            sx={{ 
              '& .MuiFormControlLabel-label': { fontSize: '13px', fontFamily: "Inter" }
            }}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ fontSize: "13px", fontFamily: "Inter" }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
