import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import theme from "../../../theme";
import { Box, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchVehicleDetail } from "../../../services/tecdocService";
import { VehicleDetail } from "../../../interfaces/VehicleDetail";

function createData(title: string, description: string) {
  return { title, description };
}

const VehicleDetailComponent = () => {
  const [vehicleDetail, setVehicleDetail] =
    React.useState<VehicleDetail | null>(null);
  const [linkageTargetId, setLinkageTargetId] = React.useState(0);
  const [linkageTargetType, setLinkageTargetType] = React.useState("");

  const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get("linkageTargetType");
      const linkageTargetId = urlParams.get("linkageTargetId");

      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }

      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }
    }
  }, []);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetchVehicleDetail(
          linkageTargetType,
          linkageTargetId
        );
        if (response && isMounted) {
          setVehicleDetail(response);
          console.log(response);
        }
      } catch (error) {
        console.error("Not able to fetch vehicle data");
      }
    };

    if (linkageTargetId && linkageTargetType) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [linkageTargetId, linkageTargetType]);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#f1f1f1",
          p: 2,
          borderTop: `2px solid ${theme.palette.primary.dark}`
        }}
      >
        <Stack spacing={2} sx={{ paddingTop: "3px", paddingLeft: "5px" }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Inter", fontSize: "15px", textAlign: "left" }}
          >
            <span style={{ fontWeight: 500 }}>
              {vehicleDetail?.mfrName} {vehicleDetail?.vehicleModelSeriesName} 
            </span>{" "}
            {vehicleDetail?.description}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{borderBottom: `2px solid #f1f1f1`, pb: 2}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md ={12}>
            <img
              style={{ maxHeight: "100px" }}
              src={vehicleDetail?.vehicleImages?.[0]?.imageURL800 || ""}
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={12} md ={12}>
            <Box sx={{ bgcolor: "#f1f1f1", p: 1, mt: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "15px",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Basic Data
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Make
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail?.mfrName : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Model
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail
                              ? vehicleDetail?.vehicleModelSeriesName
                              : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Description
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail
                              ? vehicleDetail?.description
                              : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Productions years
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail?.beginYearMonth : ""}{" "}
                            - {vehicleDetail ? vehicleDetail?.endYearMonth : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Engine power [hp]
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail?.horsePowerTo : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "2px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Engine power [kw]
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail?.kiloWattsTo : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Cylinders
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail.cylinders : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Capacity
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail.capacityCC : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Engine Code
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          {vehicleDetail?.engines?.map(
                            (engine: any, index: number) => (
                              <span
                                key={index}
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                }}
                              >
                                {engine.code} {" "}
                              </span>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Dve System
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail.driveType : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Fuel Type
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail.fuelType : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: "5px",
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Body Type
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{padding: '5px !important'}}>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {vehicleDetail ? vehicleDetail.bodyStyle : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VehicleDetailComponent;
