import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import theme from '../../../theme';
import { VehicleProductGroup } from '../../../interfaces/VehicleProductGroup';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const CustomBreadcrumbs = (props: any) => {
  const {assemblyGroupFacets} = props;
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
  const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null);
  const [articleNumber, setArticleNumber] = React.useState('');
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<number | null>(null);
  const [activeCategory, setActiveCategory] = React.useState<VehicleProductGroup| null>(null);
  const [activeParent, setParentCategory] = React.useState<VehicleProductGroup| null>(null);
  const [dynamicUrl, setDynamicUrl] = React.useState('');

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const linkageTargetType = urlParams.get('linkageTargetType');
      const linkageTargetId = urlParams.get('linkageTargetId');
      const categoryIdParam = urlParams.get("category");
      const articleNumber = urlParams.get("keyword");
      const brandIdParam = urlParams.get("brand");
    const assemblyGroupNodeIdsParam = urlParams.get("assemblyGroupNodeIds");

    if (linkageTargetId) {
      const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
      setLinkageTargetId(linkageTargetIdUrlParam);
    }

    if (linkageTargetType) {
      setLinkageTargetType(linkageTargetType);
    }

    if (categoryIdParam) {
      const categoryIdUrlParam = parseInt(categoryIdParam, 10);
      setCategoryId(categoryIdUrlParam);
    }

    if (articleNumber) {
      setArticleNumber(articleNumber);
    }

    if (brandIdParam) {
      const brandIdUrlParam = parseInt(brandIdParam, 10);
      setBrandId(brandIdUrlParam);
    }


    if (assemblyGroupNodeIdsParam) {
      const assemblyGroupNodeIdsUrlParam = parseInt(assemblyGroupNodeIdsParam, 10);
      setAssemblyGroupNodeIds(assemblyGroupNodeIdsUrlParam);

      if (assemblyGroupFacets) {
        const activeCategoryFilter = assemblyGroupFacets.filter(
          (facet: any) => facet.assemblyGroupNodeId === assemblyGroupNodeIds);
        setActiveCategory(activeCategoryFilter[0]);

        if(activeCategory) {
          const parentCategory = assemblyGroupFacets.filter(
            (facet:any) => facet.assemblyGroupNodeId === activeCategory.parentNodeId
          )

          setParentCategory(parentCategory[0]);
          if (linkageTargetType && linkageTargetId){
          setDynamicUrl(`/search-products?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${brandId ? `&brand=${brandId}` : ''}&assemblyGroupNodeIds=${activeParent?.assemblyGroupNodeId}`);
          } else {
            setDynamicUrl(`/search-products?${brandId ? `&brand=${brandId}` : ''}&assemblyGroupNodeIds=${activeParent?.assemblyGroupNodeId}`);
          }
        }
      
      }
    }
    
  },[activeCategory, activeParent, assemblyGroupFacets, assemblyGroupNodeIds, brandId])

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      sx={{
        color: theme.palette.text.primary,
        ml:1,
        fontWeight: 400,
        fontSize: "15px",
        fontFamily: "Inter",
      }}
      href={dynamicUrl}
    >
      {activeParent?.assemblyGroupName}
    </Link>,
    <Typography key="3"
      sx={{
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: "15px",
        fontFamily: "Inter",
      }}
    >
      {activeCategory?.assemblyGroupName}
    </Typography>
  ];

  return (
    <Stack spacing={2} sx={{paddingTop: '3px', paddingLeft: '5px'}}>
      <Breadcrumbs
        separator={
          activeParent ? (
            <NavigateNextIcon sx={{color: 'black'}} fontSize="small" />
          ): (
            <></>
          )}
          
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default CustomBreadcrumbs;
