import * as React from "react";
import Header from "../../components/common/header/Header";
import {
  Box,
  Grid,
  Stack,
  Link,
  Typography,
  Breadcrumbs,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../theme";
import dayjs, { Dayjs } from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import ClientSideMenu from "./ClientSideMenu";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReturnItem } from "../../interfaces/core/ReturnItem";
import { ReturnAndClaim } from "../../interfaces/core/ReturnAndClaim";
import {
  createReturnItem,
  createReturnRequest,
  getOrderItemByOrderId,
  getOrdersId,
  getReturnRequests,
} from "../../services/core-api";
import { ReadOrderItem } from "../../interfaces/core/ReadOrderItem";
import Footer from "../../components/common/Footer/Footer";
import { ReturnAndClaimClientResponse } from "../../interfaces/core/ReturnAndClaimClientResponse";

const ReturnsAndClaimsPage = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [returnItems, setReturnItems] = React.useState<ReturnItem[] | []>([]);
  const [returnAndClaims, setReturnAndClaims] = React.useState<
    ReturnAndClaimClientResponse[] | []
  >([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(
    null
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(
    null
  );
  const rowsPerPage = 10;

  const [articleNumbers, setArticleNumbers] = React.useState<
    ReadOrderItem[] | []
  >([]);
  const [articleNumber, setArticleNumber] = React.useState("");
  const [mfrName, setMfrName] = React.useState("");
  const [documentNos, setDocumentNos] = React.useState<Number[] | []>([]);
  const [selectedDocumentNo, setSelectedDocumentNo] = React.useState("");
  const [quantity, setQuantity] = React.useState(0);
  const [maxQuantity, setMaxQuantity] = React.useState(0);
  const [unitPrice, setUnitPrice] = React.useState(0);
  const [selectedReason, setSelectedReason] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [currency, setCurrency] = React.useState('');

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
        } else {
          setCurrency('EUR');
        }
    }
}, []);

  // Function to fetch data from the API
  const fetchArticleNumbers = React.useCallback(async (orderId: number) => {
    try {
      // Replace with your actual API call
      const response = await getOrderItemByOrderId(orderId);
      if (response) {
        setArticleNumbers(response);
      }
    } catch (error) {}
  }, []);

  const fetchOrdersId = React.useCallback(async () => {
    try {
      const response = await getOrdersId();
      if (response) {
        setDocumentNos(response.ordersId);
      }
    } catch (error) {}
  }, []);

  const fetchReturnRequests = React.useCallback(async () => {
    try {
      const options = {
        from_date: selectedFromDate
          ? selectedFromDate.format("YYYY-MM-DD")
          : "",
        to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "",
        page_number: page,
      };

      const response = await getReturnRequests(options);
      if (response) {
        setReturnAndClaims(response.returnAndClaims);
        setCount(response.total);
        setReturnItems(response.returnItems);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setReturnAndClaims([]);
      setCount(0);
      setReturnItems([]);
    }
  }, [page, selectedFromDate, selectedToDate]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchReturnRequests();
      fetchOrdersId();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchOrdersId, fetchReturnRequests]);

  const handleSubmitReturnItem = async () => {
    try {
      const returnItemData = {
        articleNumber: articleNumber,
        mfrName: mfrName,
        document_no: Number(selectedDocumentNo),
        quantity: quantity,
        unit_price: unitPrice,
        currency: currency,
        user_id: 1, // Example user_id
        return_and_claim_id: 1, // Example return_and_claim_id, replace with actual ID if needed
      };

      const response = await createReturnItem(returnItemData);
      if (response) {
        // Update the returnItems state with the new return item
        setReturnItems((prevReturnItems) => [
          ...prevReturnItems,
          {
            id: response.data.id, // Assuming the API returns the new item with an id
            articleNumber: articleNumber,
            mfrName: mfrName,
            document_no: Number(selectedDocumentNo),
            quantity: quantity,
            unit_price: unitPrice,
            currency: currency,
            user_id: response.data.user_id,
            return_and_claim_id: 1,
          },
        ]);

        // Optionally reset the form fields after submission
        setArticleNumber("");
        setMfrName("");
        setSelectedDocumentNo("");
        setQuantity(0);
        setUnitPrice(0);
      }
    } catch (error) {}
  };

  const autocompleteFields = async (articleNumber: any) => {
    const foundItem = articleNumbers.find(
      (item) => item.articleNumber === articleNumber
    );
    if (foundItem) {
      setArticleNumber(foundItem.articleNumber);
      setSelectedDocumentNo(selectedDocumentNo);
      setMfrName(foundItem.mfrName);
      setQuantity(foundItem.quantity);
      setMaxQuantity(foundItem.quantity);
      setUnitPrice(foundItem.price);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Set the selectedReason to the value of the checked checkbox
    if (value === selectedReason) {
      setSelectedReason("");
    } else {
      setSelectedReason(value);
    }
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmitReturnRequest = async () => {
    try {
      const returnRequestData = {
        document_no: returnItems[0].document_no,
        reason: selectedReason,
        user_id: 1,
        comment: comment,
        status: "PENDING",
        currency: currency,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        visited: false
      };
      const response = await createReturnRequest(returnRequestData);
      if (response) {
        // Update the returnAndClaims state with the new return request
        setReturnAndClaims((prevReturnAndClaims) => [
          ...prevReturnAndClaims,
          {
            id: response.data.id, // Assuming the API returns the new request with an id
            document_no: response.data.document_no,
            reason: response.data.reason,
            user_id: response.data.user_id, // Example user_id, replace with actual if needed
            comment: response.data.comment,
            status: response.data.status,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at,
            visited: response.data.visited
          },
        ]);

        // Optionally reset form fields after submission
        setSelectedReason("");
        setComment("");
        setReturnItems([]); // Clear the return items if needed
      }
    } catch (error) {}
  };

  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleExportPdf = (id: number) => {
    const url = `${baseURL}/return-claim/${id}/export`;
    window.open(url);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <Header />
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ClientSideMenu />
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{ paddingTop: "3px", paddingLeft: "5px" }}
                    >
                      <Breadcrumbs
                        separator={
                          <NavigateNextIcon
                            sx={{ color: "black" }}
                            fontSize="small"
                          />
                        }
                        aria-label="breadcrumb"
                      >
                        <Link
                          underline="hover"
                          key="2"
                          sx={{
                            color: theme.palette.text.primary,
                            ml: 1,
                            fontWeight: 400,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                          href="/"
                        >
                          Home
                        </Link>
                        ,
                        <Typography
                          key="3"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                        >
                          Returns and Claims
                        </Typography>
                      </Breadcrumbs>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: 1,
                      }}
                    >
                      <Box>
                        <DatePicker
                          format="MM/DD/YYYY"
                          value={selectedFromDate}
                          onChange={handleFromDateChange}
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                          defaultValue={dayjs("2024-06-12")}
                        />
                        <DatePicker
                          format="MM/DD/YYYY"
                          value={selectedToDate}
                          onChange={handleToDateChange}
                          sx={{
                            bgcolor: "#fff",
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                          defaultValue={dayjs("2024-06-12")}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%", mt: 1 }}>
                <Accordion
                  defaultExpanded
                  sx={{
                    borderRadius: 0,
                    boxShadow: "none",
                    border: "1px solid #f1f1f1",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ bgcolor: "#f1f1f1" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      New Returns
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={2.5} sx={{ p: 1 }}>
                        <Autocomplete
                          freeSolo
                          id="autocomplete-2"
                          sx={{
                            height: "40px",
                            flexGrow: 1,
                            minWidth: 0,
                          }}
                          options={documentNos.map(String)}
                          getOptionLabel={(option) => option}
                          ListboxProps={{
                            sx: {
                              maxHeight: "200px",
                              overflowY: "auto",
                            },
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <Typography sx={{ fontSize: "12px" }}>
                                {option}
                              </Typography>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Document No"
                              InputLabelProps={{
                                sx: {
                                  color: theme.palette.text.primary,
                                  borderRadius: 0,
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  height: "40px",
                                  top: "-6px",
                                  transform:
                                    "translate(14px, 1px), scale(1) !important",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  height: "38px",
                                  padding: "0px !important",
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  borderRadius: 0,
                                },
                              }}
                            />
                          )}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setSelectedDocumentNo(value);
                              const orderId = Number(value);
                              fetchArticleNumbers(orderId);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2.5} sx={{ p: 1 }}>
                        <Autocomplete
                          freeSolo
                          id="autocomplete-1"
                          sx={{
                            height: "40px",
                            flexGrow: 1,
                            minWidth: 0,
                          }}
                          value={articleNumber}
                          options={articleNumbers}
                          getOptionLabel={(option) => {
                            // Check if option is a string or an object
                            if (typeof option === "string") {
                              return option;
                            } else {
                              return option.articleNumber;
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <Typography sx={{ fontSize: "12px" }}>
                                {option.articleNumber}
                              </Typography>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Article number"
                              InputLabelProps={{
                                sx: {
                                  color: theme.palette.text.primary,
                                  borderRadius: 0,
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  height: "40px",
                                  top: "-6px",
                                  transform:
                                    "translate(14px, 1px), scale(1) !important",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  height: "38px",
                                  padding: "0px !important",
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  borderRadius: 0,
                                },
                              }}
                            />
                          )}
                          onChange={(event, value) => {
                            if (typeof value === "string") {
                              autocompleteFields(value);
                            } else if (value) {
                              autocompleteFields(value.articleNumber);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2.5} sx={{ p: 1 }}>
                        <TextField
                          placeholder="Manufacturer"
                          value={mfrName}
                          sx={{
                            width: "100%",
                          }}
                          InputLabelProps={{
                            sx: {
                              color: theme.palette.text.primary,
                              borderRadius: 0,
                              fontSize: "13px",
                              fontFamily: "Inter",
                              height: "40px",
                              top: "-6px",
                              transform:
                                "translate(14px, 1px), scale(1) !important",
                            },
                          }}
                          InputProps={{
                            sx: {
                              height: "38px",
                              padding: "0px !important",
                              fontSize: "13px",
                              fontFamily: "Inter",
                              borderRadius: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2.5} sx={{ p: 1 }}>
                        <TextField
                          placeholder="Quantity"
                          value={quantity}
                          sx={{
                            width: "100%",
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "") {
                              // Handle empty input if needed
                              setQuantity(1); // Example: Setting to 1 as default
                            } else {
                              const numericValue = Number(value);
                              if (!isNaN(numericValue)) {
                                // Ensure numeric value is within the valid range
                                if (numericValue < 1) {
                                  setQuantity(1); // Set to the minimum allowed value
                                } else if (numericValue > maxQuantity) {
                                  setQuantity(maxQuantity); // Set to the maximum allowed value
                                } else {
                                  setQuantity(numericValue); // Set to the valid value
                                }
                              }
                            }
                          }}
                          InputLabelProps={{
                            sx: {
                              color: theme.palette.text.primary,
                              borderRadius: 0,
                              fontSize: "13px",
                              fontFamily: "Inter",
                              height: "40px",
                              top: "-6px",
                              transform:
                                "translate(14px, 1px), scale(1) !important",
                            },
                          }}
                          InputProps={{
                            sx: {
                              height: "38px",
                              padding: "0px !important",
                              fontSize: "13px",
                              fontFamily: "Inter",
                              borderRadius: 0,
                            },
                            inputProps: {
                              min: 1, // Minimum value
                              max: maxQuantity, // Maximum value
                              type: "number", // Ensure the input is numeric
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} sx={{ p: 1 }}>
                        <Button
                          disabled={
                            !selectedDocumentNo ||
                            !articleNumber ||
                            !mfrName ||
                            !quantity
                          }
                          fullWidth
                          sx={{
                            height: "40px",
                            bgcolor: theme.palette.primary.dark,
                            color: theme.palette.text.secondary,
                            borderRadius: 0,
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            "&:hover": {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }}
                          onClick={handleSubmitReturnItem}
                        >
                          Add
                        </Button>
                      </Grid>

                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: "none", m: 1 }}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ bgcolor: "#f1f1f1" }}>
                            <TableRow>
                              <TableCell sx={{ pb: 0, fontWeight: 600 }}>
                                Article Number
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ pb: 0, fontWeight: 600 }}
                              >
                                Manufacturer
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ pb: 0, fontWeight: 600 }}
                              >
                                Document No
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ pb: 0, fontWeight: 600 }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ pb: 0, fontWeight: 600 }}
                              >
                                Price
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {returnItems.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {row.articleNumber}
                                </TableCell>
                                <TableCell align="left">
                                  {row.mfrName}
                                </TableCell>
                                <TableCell align="left">
                                  {row.document_no}
                                </TableCell>
                                <TableCell align="left">
                                  {row.quantity}
                                </TableCell>
                                <TableCell align="left">
                                  {row.unit_price} {currency}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider sx={{ width: "100%" }} />
                      <Grid
                        container
                        spacing={0}
                        sx={{
                          mt: 1,
                        }}
                      >
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              textAlign: "left",
                              p: 1,
                            }}
                          >
                            Reason Return
                          </Typography>
                          <FormGroup sx={{ pl: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Customer resignations"
                                  checked={
                                    selectedReason === "Customer resignations"
                                  }
                                  onChange={handleCheckboxChange}
                                  disabled={
                                    selectedReason !== "" &&
                                    selectedReason !== "Customer resignations"
                                  }
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    "&.Mui-checked": {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  Customer resignations
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Wrong number"
                                  checked={selectedReason === "Wrong number"}
                                  onChange={handleCheckboxChange}
                                  disabled={
                                    selectedReason !== "" &&
                                    selectedReason !== "Wrong number"
                                  }
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    "&.Mui-checked": {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  Wrong number
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Seller's mistake"
                                  checked={
                                    selectedReason === "Seller's mistake"
                                  }
                                  onChange={handleCheckboxChange}
                                  disabled={
                                    selectedReason !== "" &&
                                    selectedReason !== "Seller's mistake"
                                  }
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    "&.Mui-checked": {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  Seller's mistake
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Part damaged"
                                  checked={selectedReason === "Part damaged"}
                                  onChange={handleCheckboxChange}
                                  disabled={
                                    selectedReason !== "" &&
                                    selectedReason !== "Part damaged"
                                  }
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    "&.Mui-checked": {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  Part damaged
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Other"
                                  checked={selectedReason === "Other"}
                                  onChange={handleCheckboxChange}
                                  disabled={
                                    selectedReason !== "" &&
                                    selectedReason !== "Other"
                                  }
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    "&.Mui-checked": {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  Other
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              textAlign: "left",
                              p: 1,
                            }}
                          >
                            Comments
                          </Typography>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Enter Text"
                            multiline
                            rows={7}
                            variant="outlined"
                            sx={{ width: "100%", borderRadius: 0 }}
                            value={comment}
                            onChange={handleCommentChange}
                          />
                          <Button
                            disabled={
                              returnItems.length === 0 || selectedReason === ""
                            }
                            sx={{
                              float: "right",
                              height: "40px",
                              width: "15rem",
                              bgcolor: theme.palette.primary.dark,
                              color: theme.palette.text.secondary,
                              textTransform: "capitalize",
                              borderRadius: 0,
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              marginTop: 1,
                              "&:hover": {
                                bgcolor: theme.palette.primary.dark,
                              },
                            }}
                            onClick={handleSubmitReturnRequest}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box sx={{ width: "100%" }}>
                {/* Table */}
                <Grid
                  container
                  spacing={0}
                  sx={{
                    mt: 1,

                    border: "1px solid #f1f1f1",
                  }}
                >
                  <Grid item xs={12}>
                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none", borderRadius: 0 }}
                    >
                      <Table sx={{ boxShadow: 0 }} aria-label="simple table">
                        <TableHead sx={{ bgcolor: "#f1f1f1" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                pb: 0,
                              }}
                            >
                              Document No
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                pb: 0,
                              }}
                            >
                              Date & Time
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                pb: 0,
                              }}
                            >
                              Reason
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                pb: 0,
                              }}
                            >
                              Document
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                pb: 0,
                              }}
                            >
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {returnAndClaims &&
                            returnAndClaims.map((row) => {
                              const date = new Date(row.created_at);
                              const formattedDate = date.toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              );
                              const formattedTime = date.toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              );

                              return (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "& td": { padding: "8px !important" },
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.id}
                                  </TableCell>
                                  <TableCell align="left">
                                    {`${formattedDate} ${formattedTime}`}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.reason}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Button
                                      sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: "13px",
                                        fontFamily: "Inter",
                                        border: "1px solid #f1f1f1",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => handleExportPdf(row.id)}
                                    >
                                      Export PDF
                                    </Button>
                                  </TableCell>
                                  {row.status === "Approved" ? (
                                    <TableCell
                                      align="right"
                                      sx={{ color: "#2ccb32" }}
                                    >
                                      {row.status}
                                    </TableCell>
                                  ) : row.status === "Pending" ? (
                                    <TableCell
                                      align="right"
                                      sx={{ color: "#FFB122" }}
                                    >
                                      {row.status}
                                    </TableCell>
                                  ) : (
                                    <TableCell align="right">
                                      {row.status}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Stack spacing={2}>
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handleChangePage}
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </>
    </LocalizationProvider>
  );
};

export default ReturnsAndClaimsPage;
