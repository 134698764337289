import * as React from 'react';
import Box from '@mui/material/Box';
import theme from '../../theme';
import { Autocomplete, CircularProgress, Popper, TextField } from '@mui/material';
import { fetchBrandsLogo } from '../../services/tecdocService';
import { Brand } from '../../interfaces/Brand';

const ProductBrands = (props: any) => {
    const { dataSupplierFacets } = props;
    const [openBrands, setOpenBrands] = React.useState(false);
    const [selectBrand, setSelectBrand] = React.useState<Brand | null>(null);
    const [brandsLogo, setBrandsLogo] = React.useState<Brand[]>([]);
    const loadingBrands = openBrands && dataSupplierFacets.counts.length === 0;
    const autocompleteRef = React.useRef<HTMLDivElement>(null);
    const [popperWidth, setPopperWidth] = React.useState<number | "auto">("auto");

    // Use useMemo to memoize brands and dataSupplierIds
    const brands = React.useMemo(() => {
        return dataSupplierFacets ? dataSupplierFacets.counts : [];
    }, [dataSupplierFacets]);

    const dataSupplierIds = React.useMemo(() => {
        return brands.map((brand: any) => brand.dataSupplierId);
    }, [brands]);

    const handleBrands = async (event: React.ChangeEvent<{}>, brand: Brand | null) => {
        if (brand) {
        setSelectBrand(brand);
        const brandId = brand?.dataSupplierId;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('brand');
        if (!brandId) return;
        urlParams.set('brand', brandId.toString());
        // Construct the new URL without the 'category' parameter
        const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
        // Update the browser's history with the new URL
        window.history.replaceState({}, '', newUrl);
        window.location.href = newUrl;     
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete('brand');
            const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
            // Update the browser's history with the new URL
            window.history.replaceState({}, '', newUrl);
            window.location.href = newUrl;     
        }   
    };

    React.useEffect(() => {
                // Parse URL parameters to get brand ID
      const urlParams = new URLSearchParams(window.location.search);
      const brandIdParam = urlParams.get('brand');

        if (!brandIdParam) return;
        const brandId = parseInt(brandIdParam, 10);
        const findBrandById = (brandsLogo: Brand[], id: number): Brand | undefined => {
            return brands.find((brand: Brand) => brand.dataSupplierId === id);
        };

        const foundBrand = findBrandById(brands, brandId);
        if(!foundBrand) return;
        setSelectBrand(foundBrand);
    }, [brands])

    React.useEffect(() => {

        if (brands.length > 0 && dataSupplierIds.length > 0) {
            (async () => {
                try {
                    // Fetch articles brands with logo
                    const response = await fetchBrandsLogo(dataSupplierIds);
                    if (!response) return;
                    setBrandsLogo(response);
                } catch (error) {
                    console.log('error ', error);
                }
            })();
        }
    }, [brands.length, dataSupplierIds]); // Dependency array now only includes dataSupplierIds

    React.useEffect(() => {
        if (autocompleteRef.current) {
            setPopperWidth(autocompleteRef.current.offsetWidth);
        }
    }, [openBrands]);

    return (
        <Box sx={{ width: '100%' }}>
            <Autocomplete
                disablePortal
                id="brands"
                ref={autocompleteRef}
                open={openBrands}
                value={selectBrand}
                onOpen={() => {
                    setOpenBrands(true);
                }}
                onClose={() => {
                    setOpenBrands(false);
                }}
                ListboxProps={{
                    style: { maxHeight: 200, overflowY: "auto" },
                }}
                PaperComponent={(props) => (
                    <Popper
                        {...props}
                        open={openBrands}
                        anchorEl={autocompleteRef.current}
                        placement="bottom-start"
                        modifiers={[
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 0], // Adjust this value to move the Popper up or down
                                },
                            },
                        ]}
                        style={{
                            zIndex: 9999,
                            fontSize: '13px',
                            fontFamily: "Inter",
                            border: "1px solid #f1f1f1",
                            boxShadow: "2px 3px 3px #ccc",
                            position: "relative",
                            background: "#fff",
                            width: popperWidth,
                        }}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.mfrName === value.mfrName}
                getOptionLabel={(option) => option.mfrName}
                options={brandsLogo}
                loading={loadingBrands}
                sx={{
                    width: {
                        xs: '310px',
                        sm: '200px'
                    },
                    backgroundColor: "#fff",
                    ".MuiInputBase-input": {
                        height: "30px",
                        marginBottom: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    },
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="30"
                            srcSet={`${option.dataSupplierLogo?.imageURL200}`}
                            src={`${option.dataSupplierLogo?.imageURL200}`}
                            alt=""
                        />
                        {option.mfrName}
                    </Box>
                )}
                onChange={handleBrands}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select Manufacturer"
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            sx: {
                                color: theme.palette.text.primary,
                                fontSize: "13px",
                                fontFamily: "Inter",
                                height: "100%",
                                top: "-10px",
                                transform: "translate(24px, 12px), scale(1) !important",
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: 0, // Adjust the height as needed
                                height: "30px",
                                fontSize: '13px',
                                fontFamily: "Inter",
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {loadingBrands ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={15}
                                            sx={{ marginTop: "-15px" }}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default ProductBrands;
