import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import { CircularProgress, Popper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../../theme";
import Button from "../../ui/Button";
import { useRef, useState } from "react";
import { Manufacturer } from "../../../interfaces/Manufacturer";
import {
  fetchManufacturers,
  fetchModels,
  getLinkageTargets,
} from "../../../services/tecdocService";
import { ManufacturerModel } from "../../../interfaces/ManufacturerModels";
import { VehicleDetail } from "../../../interfaces/VehicleDetail";
import { LinkingTargetState } from "../../../store/reducers/linkingTargetReducer";
import RefreshIcon from "@mui/icons-material/Refresh";
import { storeSearchVehicle } from "../../../services/core-api";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none !important",
  borderRadius: 0,
  backgroundColor: "#F1F1F1 !important",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));


const ManualSearch = () => {
  const [refresh, setRefresh] = useState(false);

  const linkingTargetType = useSelector(
    (state: { linkingTarget: LinkingTargetState }) =>
      state.linkingTarget.linkingTargetType
  );

  const [openMarker, setOpenMarker] = React.useState(false);
  const [manufacturers, setManufacturers] = React.useState<
    readonly Manufacturer[]
  >([]);
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<Manufacturer | null>(null);
  const loadingMarker = openMarker && manufacturers.length === 0;
  const [errorManufacturer, setErrorManufacturer] = React.useState<
    string | null
  >(null);

  const [manufacturerModels, setManufacturerModels] = React.useState<
    ManufacturerModel[]
  >([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [isLoadingModel, setIsLoadingModel] = React.useState(false);
  const [selectedManufacturerModel, setSelectedManufacturerModel] =
    useState<ManufacturerModel | null>(null);
  const loadingModel = openModel && manufacturerModels.length === 0;
  const modelInputRef = useRef<HTMLInputElement>(null);

  const [openMotor, setOpenMotor] = React.useState(false);
  const [motors, setMotors] = React.useState<VehicleDetail[]>([]);
  const [selectedMotor, setSelectedMotor] = useState<VehicleDetail | null>(
    null
  );
  const [isLoadingCar, setIsLoadingCar] = React.useState(false);
  const loadingMotor = openMotor && motors.length === 0;
  const motorInputRef = useRef<HTMLInputElement>(null);

  // Autocomplete for Manufacture
  React.useEffect(() => {
    let activeMarker = true;

    if (!loadingMarker) {
      return undefined;
    }

    (async () => {
      try {
        if (activeMarker && linkingTargetType) {
          const data = await fetchManufacturers(linkingTargetType);
          setManufacturers(data);
          setErrorManufacturer(null);
        } else {
          setErrorManufacturer(
            "Please select one of categories: Passenger car, Van, Truck or Motorcyctle"
          );
        }
      } catch (error) {
        setErrorManufacturer(
          "Not able to fetch manufacturers. Please try again."
        );
      }
    })();

    return () => {
      activeMarker = false;
    };
  }, [linkingTargetType, loadingMarker, manufacturers]);

  React.useEffect(() => {
    if (!openMarker) {
      setManufacturers([]);
    }
  }, [openMarker]);

  // Inside the Autocomplete component for Manufacturer
  const handleManufacturerChange = async (
    event: React.ChangeEvent<{}>,
    manufacturer: Manufacturer | null
  ) => {
    setSelectedManufacturer(manufacturer);
    setOpenModel(true);

    if (manufacturer) {
      try {
        setIsLoadingModel(true);
        const models = await fetchModels(
          manufacturer.manuId,
          linkingTargetType
        );
        setManufacturerModels(models);
      } catch (error) {
      } finally {
        setIsLoadingModel(false);
      }
    }
  };

  const handleModelChange = async (
    event: React.ChangeEvent<{}>,
    model: ManufacturerModel | null
  ) => {
    setSelectedManufacturerModel(model);
    setOpenMotor(true);

    if (model && selectedManufacturer) {
      try {
        const manufacturerId = selectedManufacturer.manuId;
        setIsLoadingCar(true);

        const motors = await getLinkageTargets(
          manufacturerId,
          model.modelId,
          linkingTargetType
        );
        setMotors(motors);
      } catch (error) {
      } finally {
        setIsLoadingCar(false);
      }
    }
  };

  const handleMotorChange = async (
    event: React.ChangeEvent<{}>,
    motor: VehicleDetail | null
  ) => {
    setSelectedMotor(motor);
    setIsLoadingCar(true);
    const linkageTargetId = motor?.linkageTargetId;
    const linkageTargetType = motor?.linkageTargetType;
    if (linkageTargetId && linkageTargetType) {
      const authUser = localStorage.getItem("user");
      if (authUser) {
        const user = JSON.parse(authUser);
        const searchedVehicle = {
          user_id: user.id,
          tec_doc_type_no: motor.linkageTargetId,
          linkage_target_type: linkageTargetType,
          vehicle_name: `${motor.mfrName} ${
            motor.vehicleModelSeriesName
          } ${motor.description ?? ""}`,
          vehicle_model_year: `${motor.beginYearMonth ?? ""} ${
            motor.endYearMonth ?? ""
          }`,
          performance: `${motor.kiloWattsTo}kW / ${motor.horsePowerTo}HP`,
          capacity: `${motor.capacityCC}`,
          engine_code:
          motor.engines &&
          motor.engines.length > 0 &&
          motor.engines[0].code
              ? motor.engines[0].code
              : "",
          created_at: new Date().toISOString(),
        };

        try {
          await storeSearchVehicle(searchedVehicle);
          setIsLoadingCar(false);
        } catch (error) {}
      }

      const url = `/assemblies?linkageTargetId=${linkageTargetId}&linkageTargetType=${linkageTargetType}`;
      window.location.href = url;
    }
  };

  // Add useEffect hook to focus on the model input when a manufacturer is selected
  React.useEffect(() => {
    if (selectedManufacturer && modelInputRef.current) {
      modelInputRef.current.focus();
    }

    if (selectedManufacturerModel && motorInputRef.current) {
      motorInputRef.current.focus();
    }

    const handleResize = () => {
      if (openMarker) {
        setOpenMarker(false);
        setTimeout(() => setOpenMarker(true), 0); // Reopen to recalculate position
      }

      if (openModel) {
        setOpenModel(false);
        setTimeout(() => setOpenModel(true), 0); // Reopen to recalculate position
      }

      if (openMotor) {
        setOpenMotor(false);
        setTimeout(() => setOpenMotor(true), 0); // Reopen to recalculate position
      }
    };
    window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
  }, [selectedManufacturer, selectedManufacturerModel, openMarker, openModel, openMotor]);

  const handleSubmitCarData = async () => {
    setIsLoadingCar(true);
    const linkageTargetId = selectedMotor?.linkageTargetId;
    const linkageTargetType = selectedMotor?.linkageTargetType;
    if (linkageTargetId && linkageTargetType) {
      const authUser = localStorage.getItem("user");
      if (authUser) {
        const user = JSON.parse(authUser);
        const searchedVehicle = {
          user_id: user.id,
          tec_doc_type_no: selectedMotor.linkageTargetId,
          linkage_target_type: linkageTargetType,
          vehicle_name: `${selectedMotor.mfrName} ${
            selectedMotor.vehicleModelSeriesName
          } ${selectedMotor.description ?? ""}`,
          vehicle_model_year: `${selectedMotor.beginYearMonth ?? ""} ${
            selectedMotor.endYearMonth ?? ""
          }`,
          performance: `${selectedMotor.kiloWattsTo}kW / ${selectedMotor.horsePowerTo}HP`,
          capacity: `${selectedMotor.capacityCC}`,
          engine_code:
            selectedMotor.engines &&
            selectedMotor.engines.length > 0 &&
            selectedMotor.engines[0].code
              ? selectedMotor.engines[0].code
              : "",
          created_at: new Date().toISOString(),
        };

        try {
          await storeSearchVehicle(searchedVehicle);
          setIsLoadingCar(false);
        } catch (error) {}
      }

      const url = `/assemblies?linkageTargetId=${linkageTargetId}&linkageTargetType=${linkageTargetType}`;
      window.location.href = url;
    }
  };

  const removeData = () => {
    setManufacturers([]);
    setManufacturerModels([]);
    setMotors([]);
    setSelectedManufacturer(null);
    setSelectedManufacturerModel(null);
    setSelectedMotor(null);
    setIsLoadingCar(false);
    setRefresh(!refresh);
  };

  const isAndroidDevice = (): boolean => {
    const userAgent = navigator.userAgent;
    return /android/i.test(userAgent);
  };

  const isAndroid = isAndroidDevice();

  return (
    <div>
      <Item
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <img
          src="/car.svg"
          alt="Search car icon"
          style={{ width: "20px", marginRight: "8px" }}
        />
        <div style={{ flexGrow: 1 }}>
          {" "}
          {/* Container to hold the text */}
          <Typography
            variant="body1"
            align="left"
            sx={{
              fontSize: "13px",
              fontFamily: "Inter",
            }}
          >
            {linkingTargetType === "P"
              ? "Search by Car"
              : linkingTargetType === "L"
              ? "Search by Van"
              : linkingTargetType === "C"
              ? "Search by Truck"
              : linkingTargetType === "B"
              ? "Search by Motorcycle"
              : "Search by Car"}
          </Typography>
        </div>
        <Button
          startIcon={<RefreshIcon />}
          sx={{
            marginLeft: "auto", // Move the button to the right
            backgroundColor: "transparent", // Set initial background color to transparent
            "&:hover": {
              backgroundColor: "transparent", // Set background color to transparent on hover
            },
          }}
          onClick={removeData}
        ></Button>
      </Item>

    
        <>
         <Item sx={{ display: "flex", alignItems: "center", paddingTop: 2 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            width: "40px",
            fontSize: "1.2rem",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          1
        </Box>
        <Autocomplete
          disablePortal
          id="marker"
          open={openMarker}
          onOpen={() => {
            setOpenMarker(true);
          }}
          onClose={() => {
            setOpenMarker(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorEl = document.getElementById("marker");
            if (!anchorEl) return null; // Avoid rendering if anchorEl is not ready
            const width = anchorEl ? `${anchorEl.offsetWidth + 45}px` : "auto";

            return (
              <Popper
                {...props}
                open={openMarker}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "viewport",
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10],
                    },
                  },
                ]}
                style={{
                  zIndex: 9999,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: 'fixed',
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          value={selectedManufacturer}
          isOptionEqualToValue={(option, value) =>
            option.manuName === value.manuName
          }
          getOptionLabel={(option) => option.manuName}
          options={manufacturers}
          loading={loadingMarker}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleManufacturerChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Manufacturer"
              autoFocus
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {loadingMarker ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        sx={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Item>

      {errorManufacturer && (
        <Item sx={{ display: "flex", paddingTop: 0 }}>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              color="error"
              sx={{ textAlign: "left" }}
            >
              {errorManufacturer}
            </Typography>
          </Box>
        </Item>
      )}

      <Item sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            backgroundColor: selectedManufacturer
              ? theme.palette.secondary.main
              : theme.palette.secondary.light,
            width: "40px",
            fontSize: "1.2rem",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          2
        </Box>
        <Autocomplete
          disablePortal
          id="model"
          open={openModel && !!selectedManufacturer}
          onOpen={() => {
            setOpenModel(true);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorEl = document.getElementById("model");
            if (!anchorEl) return null; // Avoid rendering if anchorEl is not ready
            const width = anchorEl ? `${anchorEl.offsetWidth + 45}px` : "auto";

            return (
              <Popper
                {...props}
                open={openModel}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "viewport",
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10],
                    },
                  },
                ]}
                style={{
                  zIndex: 9,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: 'fixed',
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          value={selectedManufacturerModel}
          isOptionEqualToValue={(option, value) =>
            option.modelname === value.modelname
          }
          renderOption={(props, option) => {
            const fromYear = option.yearOfConstrFrom
              ? `${option.yearOfConstrFrom}`.slice(4) +
                `.` +
                `${option.yearOfConstrFrom}`.slice(0, 4)
              : "";
            const toYear = option.yearOfConstrTo
              ? `${option.yearOfConstrTo}`.slice(4) +
                `.` +
                `${option.yearOfConstrTo}`.slice(0, 4)
              : "";

            // Instead of destructuring `key`, just pass `props` and handle `key` separately
            return (
              <li {...props} key={option.modelId}>
                {option.modelname} {fromYear} - {toYear}
              </li>
            );
          }}
          getOptionLabel={(option) => {
            // Format the label as desired
            const fromYear = option.yearOfConstrFrom
              ? `${option.yearOfConstrFrom}`.slice(4) +
                `.` +
                `${option.yearOfConstrFrom}`.slice(0, 4)
              : "";
            const toYear = option.yearOfConstrTo
              ? `${option.yearOfConstrTo}`.slice(4) +
                `.` +
                `${option.yearOfConstrTo}`.slice(0, 4)
              : "";
            return `${option.modelname} ${fromYear} - ${toYear}`;
          }}
          options={manufacturerModels}
          loading={isLoadingModel}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleModelChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={modelInputRef}
              label="Models"
              autoFocus={!!selectedManufacturer}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {isLoadingModel ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        style={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Item>
      <Item sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            backgroundColor: selectedManufacturerModel
              ? theme.palette.secondary.main
              : theme.palette.secondary.light,
            width: "40px",
            fontSize: "1.2rem",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          3
        </Box>
        <Autocomplete
          disablePortal
          id="motor"
          open={openMotor && !!selectedManufacturerModel}
          onOpen={() => {
            setOpenMotor(true);
          }}
          onClose={() => {
            setOpenMotor(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorElMotor = document.getElementById("motor");
            if (!anchorElMotor) return null; // Avoid rendering if anchorEl is not ready
            const width = anchorElMotor ? `${anchorElMotor.offsetWidth + 45}px` : "auto";

            return (
              <Popper
                {...props}
                open={openMotor}
                anchorEl={anchorElMotor}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "viewport",
                      tether: false, // Prevents snapping dropdown back to anchor
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10], // Adjust dropdown positioning
                    },
                  },
                  {
                    name: "flip",
                    options: {
                      fallbackPlacements: ["bottom-start", "top-start"],
                    },
                  },
                ]}
                style={{
                  zIndex: 99999,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: 'fixed',
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          renderOption={(props, option) => {
            // Parse the year strings and format them as "MM.YYYY"
            const beginYearMonth = option.beginYearMonth
              ? `${option.beginYearMonth.slice(
                  5
                )}.${option.beginYearMonth.slice(0, 4)}`
              : "";
            const endYearMonth = option.endYearMonth
              ? `${option.endYearMonth.slice(5)}.${option.endYearMonth.slice(
                  0,
                  4
                )}`
              : "";

            return (
              <li {...props} key={option.linkageTargetId}>
                {option.description} ({option.kiloWattsFrom} kW /{" "}
                {option.horsePowerFrom} hp) ({beginYearMonth} - {endYearMonth})
              </li>
            );
          }}
          value={selectedMotor}
          isOptionEqualToValue={(option, value) =>
            option.linkageTargetId === value.linkageTargetId
          }
          getOptionLabel={(option) => {
            const beginYearMonth = option.beginYearMonth
              ? `${option.beginYearMonth.slice(
                  5
                )}.${option.beginYearMonth.slice(0, 4)}`
              : "";
            const endYearMonth = option.endYearMonth
              ? `${option.endYearMonth.slice(5)}.${option.endYearMonth.slice(
                  0,
                  4
                )}`
              : "";

            // Return the formatted string
            return `${option.description} (${option.kiloWattsFrom} kW / ${option.horsePowerFrom} hp) (${beginYearMonth} - ${endYearMonth})`;
          }}
          options={motors.map((motor) => ({
            ...motor,
            key: motor.linkageTargetId,
          }))}
          loading={loadingMotor}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleMotorChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={motorInputRef}
              autoFocus={!!selectedManufacturerModel}
              label="Motors"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {loadingMotor ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        style={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Item>
        </>
    
     
      <Item sx={{ display: "flex", alignItems: "center" }}>
        {isLoadingCar ? (
          <LoadingButton
            loading
            variant="contained"
            sx={{
              height: "38px",
              borderRadius: 0,
              width: "100%",
              alignItems: "center", // Center items vertically
              justifyContent: "flex-end",
            }}
          >
            Submit
          </LoadingButton>
        ) : (
          <>
            <Button
              sx={{
                width: "100%",
                borderRadius: 0,
                textTransform: "capitalize",
                backgroundColor: "secondary.main",
                ":hover": {
                  backgroundColor: "secondary.main",
                },
                color: "text.secondary",
              }}
              onClick={handleSubmitCarData}
              disabled={!selectedMotor}
            >
              Search
            </Button>
          </>
        )}
      </Item>
    </div>
  );
};

export default ManualSearch;
