import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/common/header/Header";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import {
  Breadcrumbs,
  Button,
  Grid,
  Skeleton,
  Link,
  Pagination,
  Stack,
} from "@mui/material";
import ClientSideMenu from "./ClientSideMenu";
import theme from "../../theme";
import {
  addProductToBasket,
  deleteFavoriteItems,
  getFavoriteProducts,
} from "../../services/core-api";
import { FavoriteItem } from "../../interfaces/core/FavoriteItem";
import { BasketItem } from "../../interfaces/core/BasketItem";
import { useSnackbar } from "../../components/common/SnackbarContext";
import { addToCart } from "../../store/reducers/basket";
import Footer from "../../components/common/Footer/Footer";
const defaultImage = "./no-image.png";

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

interface Data {
  id: number;
  image: string;
  name: string;
  manufacturer: string;
  description: string;
  carbs: number;
  fat: number;
  action: string;
  protein: number;
  total: number;
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Article Number",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "manufacturer",
    numeric: false,
    disablePadding: false,
    label: "Manufacturer",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descriptions",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{
              pb: "4px",
              color: theme.palette.secondary.main,
              "&.Mui-checked": {
                color: theme.palette.secondary.main,
              },
              "&.MuiCheckbox-indeterminate": {
                color: theme.palette.secondary.main,
              },
            }}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              fontWeight: 600,
              pb: "4px",
              pl: index === 0 ? 0 : "inherit",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  favoriteIds: number[];
}

const handleDeleteFavoriteItems = async (favoriteIds: any) => {
  try {
    await deleteFavoriteItems(favoriteIds);
    window.location.reload();
  } catch (error) {}
};

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, favoriteIds } = props;

  return (
    <Toolbar
      sx={{
        minHeight: "40px !important",
        pl: { sm: 0.5 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteFavoriteItems(favoriteIds)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Delete {numSelected} items selected
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}

const FavoriteProductsPrage = () => {
  const [favoriteArticles, setFavoriteArticles] = React.useState<
    FavoriteItem[] | []
  >([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const [favoriteIds, setFavoriteIds] = React.useState<number[]>([]);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("description");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [basketItem, setBasketItem] = React.useState<BasketItem | null>(null);
  const [value, setValue] = React.useState(1);
  const [isloadingStock, setIsLoadingStock] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const rowsPerPage = 10;
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();

  const [currency, setCurrency] = React.useState('');

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
        } else {
          setCurrency('EUR');
        }
    }
}, []);

  const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      (async () => {
        try {
          const options = {
            page_number: page,
          };
          const response = await getFavoriteProducts(options);
          if (response) {
            setFavoriteArticles(response.items);
            setIsLoadingStock(false);
            setPage(response.page_number);
            setTotalItems(response.total_items);
            setCount(Math.ceil(response.total_items / rowsPerPage));
          }
        } catch (error) {}
      })();
    }
  }, [page, rowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = favoriteArticles?.map((n) => n.id);
      if (newSelected) setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setFavoriteIds(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Add article to basket
  const addToBasket = (
    article: any,
    newStateBasketNotification: SnackbarOrigin
  ) => {
    console.log(article);
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);

    const item: BasketItem = {
      productId: article.id ?? 0,
      erp_id: article.erp_id,
      articleNumber: article.articleNumber,
      mfrName: article.mfrName,
      dataSupplierId: article.dataSupplierId,
      image: article.image ?? defaultImage,
      description: article.description,
      price: article.stock.price,
      quantity: 1,
      totalPrice: parseFloat((article.stock.price * value).toFixed(2)),
      available: true,
    };

    setBasketItem(item);
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const user_id = parsedUser.id;
      (async () => {
        try {
          await addProductToBasket(user_id, item);
          dispatch(addToCart(item));
          showSnackbar("Product added to basket successfully!", "success");
        } catch (error) {
          showSnackbar("Failed to add product to basket.", "error");
        }
      })();
    }
  };

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
          minHeight: '71vh'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ClientSideMenu />
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box
              sx={{
                bgcolor: "#f1f1f1",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: 1,
                borderTop: `2px solid ${theme.palette.primary.dark}`,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "15px" }}
                  >
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          sx={{ color: "black" }}
                          fontSize="small"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          ml: 1,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >
                        Home
                      </Link>
                      ,
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Favorite Products
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
                <TableContainer>
                  {isloadingStock ? (
                    <Table>
                      <TableBody>
                        {[...Array(5)].map((_, index) => (
                          <TableRow key={index}>
                            {/* Skeleton placeholders for each column */}
                            <TableCell padding="checkbox">
                              <Skeleton
                                variant="rectangular"
                                width={20}
                                height={20}
                              />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="text" width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton
                                variant="rectangular"
                                width={80}
                                height={40}
                              />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="text" width={80} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="text" width={120} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="text" width={50} />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="rectangular"
                                width={40}
                                height={40}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="rectangular"
                                width={40}
                                height={40}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={favoriteArticles.length}
                      />
                      <TableBody>
                        {favoriteArticles &&
                          favoriteArticles.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      handleClick(event, row.id)
                                    }
                                    sx={{
                                      color: theme.palette.secondary.main,
                                      "&.Mui-checked": {
                                        color: theme.palette.secondary.main,
                                      },
                                    }}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: "left",
                                        fontSize: "13px",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {row.articleNumber}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <img
                                    style={{
                                      width: "80px",
                                      marginRight: "10px",
                                    }}
                                    src={row.image || defaultImage}
                                    alt="Product"
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                  }}
                                >
                                  {row.mfrName}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: row.description,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                  }}
                                >
                                  {row.stock.price
                                    ? Number(row.stock.price).toFixed(2)
                                    : "0.00"}{" "}
                                  {currency}
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    startIcon={
                                      <img
                                        style={{
                                          width: "27px",
                                          height: "27px",
                                        }}
                                        src="./available_status.svg"
                                        alt="product status icon"
                                      />
                                    }
                                    sx={{
                                      padding: "4px 2px !important",
                                      background:
                                        row.stock.qty < 1 ? "#F33" : "#0C0",
                                      "& .css-1d6wzja-MuiButton-startIcon": {
                                        marginRight: "0px !important",
                                        marginLeft: "0px !important",
                                      },
                                      borderRadius: 0,
                                    }}
                                  ></Button>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    disabled={!row.stock.qty}
                                    aria-label="basket"
                                    size="medium"
                                    sx={{
                                      background: theme.palette.secondary.main,
                                      borderRadius: 0,
                                      ":hover": {
                                        backgroundColor:
                                          theme.palette.secondary.main,
                                      },
                                    }}
                                    onClick={() =>
                                      addToBasket(row, {
                                        vertical: "top",
                                        horizontal: "right",
                                      })
                                    }
                                  >
                                    <ShoppingCartOutlinedIcon
                                      sx={{ color: "#fff" }}
                                      fontSize="medium"
                                    />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
                <EnhancedTableToolbar
                  favoriteIds={favoriteIds}
                  numSelected={selected.length}
                />
              </Paper>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={count}
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default FavoriteProductsPrage;
