const initialState = {
   retailPrice: false,
 };
 
 const priceReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case 'TOGGLE_RETAIL_PRICE':
       return {
         ...state,
         retailPrice: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default priceReducer;
 