import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Fade,
  MenuItem,
  MenuList,
  Popover,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarOrigin,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { fetchArticle } from "../../../../services/tecdocService";
import theme from "../../../../theme";
import { TransitionProps } from "@mui/material/transitions";
import { Article } from "../../../../interfaces/Article";
import Skeleton from "@mui/material/Skeleton";
import { addProductToBasket, getLiveArticleState } from "../../../../services/core-api";
import { LoadingButton } from "@mui/lab";
import { BasketItem } from "../../../../interfaces/core/BasketItem";
import { useSnackbar } from "../../../common/SnackbarContext";
import { addToCart } from "../../../../store/reducers/basket";
import { Warehouse } from "../../../../interfaces/core/Warehouse";
const defaultImage = "./no-image.png";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.primary,
    padding: "5px !important",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "5px !important",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface PriceState {
  retailPrice: boolean;
}


interface RootState {
  priceReducer: PriceState;
}

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
const ProductItemPartList = (props: any) => {
  const { articleNumber, dataSupplierId } = props;
  const [article, setArticle] = React.useState<Article | null>(null);
  const [erpProduct, setErpProduct] = React.useState<any | null>(null);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [basketItem, setBasketItem] = React.useState<BasketItem | null>(null);
  const [hasData, setHasData] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [price_2, setPrice_2] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [warehouses, setWerhauses] = React.useState<Warehouse[] | []>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [isloadingStock, setIsLoadingStock] = React.useState(true);
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const retailPrice = useSelector(
    (state: RootState) => state.priceReducer.retailPrice
  );
  const [currency, setCurrency] = React.useState('');
  const [currency_2, setCurrency_2] = React.useState('');

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    // Check if the input value is greater than the maximum number
    if (event.target.value !== "" && parseInt(event.target.value) > 100) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(200);
    } else if (parseInt(event.target.value) < 1) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(1);
    } else {
      // Otherwise, update the value normally
      setValue(event.target.value);
    }
  };

  const addToBasket = (
    article: Article,
    newStateBasketNotification: SnackbarOrigin
  ) => {
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);

    let description = "";
    let articleCriteria = article.articleCriteria || [];
    articleCriteria.forEach((criteria: any) => {
      if (criteria?.criteriaDescription && criteria?.formattedValue) {
        description += `<b style="font-weight: 500">${criteria.criteriaDescription}</b>: ${criteria.formattedValue}; `;
      }
    });

    const item: BasketItem = {
      productId: article.productId ?? 0,
      erp_id: article.erp_id,
      articleNumber: article.articleNumber,
      mfrName: article.mfrName,
      dataSupplierId: article.dataSupplierId,
      image: article?.images?.[0]?.imageURL200 ?? defaultImage,
      description: description,
      price: price,
      quantity: value,
      totalPrice: parseFloat((price * value).toFixed(2)),
      available: true,
    };

    setBasketItem(item);
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const user_id = parsedUser.id;
      (async () => {
        try {
          await addProductToBasket(user_id, item);
          dispatch(addToCart(item));
          showSnackbar("Product added to basket successfully!", "success");
        } catch (error) {
          showSnackbar("Failed to add product to basket.", "error");
        }
      })();
    }
  };

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
          setCurrency_2('LEK');
        } else {
          setCurrency('EUR');
          setCurrency_2('LEK');
        }
    }
}, []);

  React.useEffect(() => {
    if (articleNumber && dataSupplierId) {
      (async () => {
        try {
          const response = await fetchArticle(articleNumber);
          if (response){
            setHasData(true);
            setArticle(response);
            const erpId = response.erp_id;
            if (erpId){
              const erpArticle = await getLiveArticleState(erpId);
              if (erpArticle) {
                setPrice(erpArticle.price);
                setPrice_2(erpArticle.retail_price);
                setWerhauses(erpArticle.magazina);
                setErpProduct(erpArticle);
                const total_quantity = erpArticle.qty;
                setQuantity(total_quantity);
                if (total_quantity > 0) {
                  setValue(1);
                } else {
                  setValue(0);
                }
              }
            }
            setIsLoadingStock(false);
          }
          
        } catch (error) {
          setIsLoadingStock(false);
          setQuantity(-1);
          setPrice(0.0);
          setPrice_2(0.0);
          setValue(0);
          setError(
            "Not able to fetch partlist for this article. Please try again later."
          );
        }
      })();
    }
  }, []);

  return (
    <>
      {hasData && article ? (
        <StyledTableRow>
          <StyledTableCell align="left">
            {article.articleNumber || ""}
          </StyledTableCell>
          <StyledTableCell align="left">
            <img
              style={{ height: "26px" }}
              src={
                article.images && article.images.length > 0
                  ? article.images[0].imageURL1600
                  : defaultImage
              } // Conditional check
              alt="Partlist product"
            />
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {article.mfrName || ""},{" "}
            {article.genericArticles?.[0]?.genericArticleDescription || ""}
          </StyledTableCell>
          <StyledTableCell align="left">
            {article.misc?.quantityPerPackage || ""}
          </StyledTableCell>
          <StyledTableCell align="right">
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "flex-end",
              }}
            >
              {retailPrice ? (
                <>
                  <Typography
                sx={{
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  display: "flex",
                  alignItems: "center",
                  mr: 2,
                }}
              >
                {(price_2 ?? 0).toFixed(2)} {currency_2}
              </Typography>
                </>
              ): (
                <>
                  <Typography
                sx={{
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  display: "flex",
                  alignItems: "center",
                  mr: 2,
                }}
              >
                {(price ?? 0).toFixed(2)} {currency}
              </Typography>
                </>
              )}
              {isloadingStock ? (
                <Stack direction="row" spacing={2} sx={{ width: "25px", height: '25px', mr: 4 }}>
                  <LoadingButton
                    loading
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              ) : quantity >= 0 ? (
                <Button
                  startIcon={
                    <img
                      style={{ width: "17px", height: "17px" }}
                      src="./available_status.svg"
                      alt="product status icon"
                    />
                  }
                  sx={{
                    minWidth: "40px",
                    padding: "0 !important",
                    height: "25px !important",
                    background: quantity < 1 ? "#F33" : "#0C0",
                    "& .css-1d6wzja-MuiButton-startIcon": {
                      marginRight: "0px !important",
                      marginLeft: "0px !important",
                    },
                    borderRadius: 0,
                    ":hover": {
                      background: quantity < 1 ? "#F33" : "#0C0",
                    },
                  }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                ></Button>
              ) : (
                <></>
              )}
              {erpProduct && quantity !== -1 && (
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <MenuList
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {warehouses.map((warehouse, index) => (
                    <MenuItem>
                      <span
                        style={{
                          background:
                          warehouse.situation < 1 ? "#F33" : "#0C0",
                          width: "10px",
                          height: "10px",
                          borderRadius: "15px",
                        }}
                      ></span>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          ml: 1,
                        }}
                      >
                        {warehouse.serviceUnitCode}
                      </Typography>
                    </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              )}
              <TextField
                disabled={quantity < 1}
                id="standard-number"
                size="medium"
                variant="standard"
                value={value}
                onChange={handleChange}
                sx={{
                  width: "40px",
                  height: "26px",
                  background: "#fff",
                  marginLeft: 1,
                  marginRight: 2,
                  "& .MuiInput-underline:before": {
                    borderBottom: "none", // Remove the bottom border before hover
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "none", // Remove the bottom border after clicking
                  },
                }}
                InputProps={{
                  sx: {
                    textAlign: "center",
                    border: "1px solid #d4d4d4",
                    height: "25px",
                    fontSize: "12px",
                    fontFamily: "Inter",
                    paddingLeft: ".9rem",
                    width: "100%",
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none", // Remove the bottom border on hover
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none", // Remove the bottom border after clicking
                    },
                  },
                }}
              />
              <Button
                disabled={quantity < 1 || !value }
                aria-label="basket"
                size="small"
                sx={{
                  minWidth: "40px",
                  padding: "0 !important",
                  height: "25px !important",
                  background: theme.palette.secondary.main,
                  borderRadius: 0,
                  ":hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                onClick={() =>
                  article &&
                  addToBasket(article, { vertical: "top", horizontal: "right" })
                }
              >
                <ShoppingCartOutlinedIcon
                  sx={{ color: "#fff", height: "13px", width: "40px" }}
                />
              </Button>
            </Box>
          </StyledTableCell>
          <Snackbar
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
            open={openNotification}
            autoHideDuration={1500}
            onClose={hideBasketNotification}
          >
            <Alert
              onClose={hideBasketNotification}
              severity="success"
              sx={{
                bgcolor: "#fff",
                width: "100%",
                boxShadow: "4px 5px 5px #cfcfcf",
              }}
            >
              <Card sx={{ display: "flex", boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  sx={{ width: 120 }}
                  src={
                    article?.images && article.images.length > 0
                      ? article.images[0]?.imageURL800
                      : defaultImage
                  }
                  alt="Live from space album cover"
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="subtitle1"
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      {article?.mfrName || ""},{" "}
                      {article?.genericArticles?.[0]
                        ?.genericArticleDescription || ""}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{
                        textAlign: "left",
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Quantity x {value}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{
                        textAlign: "left",
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      
                      Price: {price} {currency}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Alert>
          </Snackbar>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Skeleton animation="wave" sx={{ width: "40px", height: "16px" }} />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Skeleton animation="wave" sx={{ width: "40px", height: "40px" }} />
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            <Skeleton animation="wave" sx={{ width: "40px", height: "16px" }} />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Skeleton animation="wave" sx={{ width: "10px", height: "16px" }} />
          </StyledTableCell>
          <StyledTableCell align="right">
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "flex-end",
              }}
            >
              <Skeleton
                animation="wave"
                sx={{
                  width: "80px",
                  height: "16px",
                  marginTop: "12px",
                  marginRight: 1,
                }}
              />
              <Skeleton
                animation="wave"
                sx={{ width: "40px", height: "40px", marginRight: 1 }}
              />
              <Skeleton
                animation="wave"
                sx={{ width: "40px", height: "40px", marginRight: 1 }}
              />
              <Skeleton
                animation="wave"
                sx={{ width: "40px", height: "40px", marginRight: 1 }}
              />
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
};

export default ProductItemPartList;
