import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useRef } from "react";
import theme from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getBanners,
  uploadBanner,
  deleteBanner,
} from "../../../services/core-api"; // Import deleteBanner function
import { ReadBanner } from "../../../interfaces/core/ReadBanner";

interface CustomFileUploadProps {
  onFileChange: (file: File) => void;
}

const CustomFileUpload: React.FC<CustomFileUploadProps> = ({
  onFileChange,
}) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      onFileChange(file);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        border: "1px dashed #d5d5d5",
        bgcolor: "#edf9ff",
        p: 3,
      }}
    >
      {selectedFile ? (
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: 500,
            mb: 2,
          }}
        >
          Selected File: {selectedFile.name}
        </Typography>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              fontFamily: "Inter",
              fontWeight: 500,
              mb: 2,
            }}
          >
            Drag and drop Banner image here
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              my: 2,
              width: "200px",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px solid #d5d5d5",
              }}
            />
            <Typography
              sx={{
                mx: 2,
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 400,
              }}
            >
              OR
            </Typography>
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px solid #d5d5d5",
              }}
            />
          </Box>
          <input
            type="file"
            id="image-upload-input"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CloudUploadIcon />}
            sx={{
              fontSize: "13px",
              fontFamily: "Inter",
              textTransform: "capitalize",
            }}
            onClick={handleButtonClick}
          >
            Upload Image
          </Button>
          <Typography
            variant="body2"
            sx={{
              fontSize: "11px",
              fontFamily: "Inter",
              fontWeight: 300,
              mt: 3,
            }}
          >
            Upload 1000*530 image
          </Typography>
        </>
      )}
    </Box>
  );
};

const Banner = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [banners, setBanners] = React.useState<ReadBanner[] | []>([]);

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await uploadBanner(formData);
      if (response && response.data) {
        // Ensure response contains the expected data
        setBanners((prevBanners) => [...prevBanners, response.data]); // Append the banner data
        window.location.reload();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteBanner(id);
      setBanners((prevBanners) =>
        prevBanners.filter((banner) => banner.id !== id)
      ); // Update state after deletion
    } catch (error) {
    }
  };

  React.useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await getBanners();
        if (response) {
          setBanners(response);
        }
      } catch (error) {
      }
    };
    fetchBanners();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              float: "left",
              fontFamily: "Inter",
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 3,
            }}
          >
            Banners
          </Typography>
        </Grid>
      </Grid>
      <Accordion
        defaultExpanded
        sx={{ boxShadow: "none", border: "1px solid #f1f1f1", mt: 2, mb: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: 600,
              color: theme.palette.text.primary,
            }}
          >
            Add new banner
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    justifyContent: "center",
                    border: "1px dashed #d5d5d5",
                    bgcolor: "#edf9ff",
                  }}
                >
                  <CustomFileUpload onFileChange={handleFileChange} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  sx={{
                    width: "250px",
                    float: "left",
                    bgcolor: theme.palette.primary.dark,
                    color: theme.palette.text.secondary,
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: theme.palette.primary.dark,
                    }
                  }}
                  onClick={handleUpload}
                >
                  Add New Banner
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", border: "1px solid #f1f1f1", p: 2 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#f1f1f1" }}>
            <TableRow>
              <TableCell sx={{ pb: 0, fontWeight: 600 }}>Image</TableCell>
              <TableCell align="right" sx={{ pb: 0, fontWeight: 600 }}>
                Created Date
              </TableCell>
              <TableCell align="right" sx={{ pb: 0, fontWeight: 600 }}>
                Updated Date
              </TableCell>
              <TableCell align="right" sx={{ pb: 0, fontWeight: 600 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners.map((row, index) => {
              // Format the start_date and end_date
              const startDate = new Date(row.start_date);
              const formattedStartDate = startDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              const formattedStartTime = startDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });

              const endDate = new Date(row.end_date);
              const formattedEndDate = endDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              const formattedEndTime = endDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });

              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <img
                      src={`${baseURL}/${row.image}`}
                      alt="banner"
                      style={{ width: "150px" }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {formattedStartDate} {formattedStartTime}
                  </TableCell>
                  <TableCell align="right">
                    {formattedEndDate} {formattedEndTime}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Banner;
