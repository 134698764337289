import * as React from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Link,
  CardMedia,
  Checkbox,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Header from "../../components/common/header/Header";
import ClientSideMenu from "./ClientSideMenu";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { TransitionProps } from "@mui/material/transitions";
import ProductTabs from "../../components/products/productTabs/ProductTabs";
import theme from "../../theme";
import { Article } from "../../interfaces/Article";

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const CustomIcon = styled(ContentCopyOutlinedIcon)({
  marginLeft: 10,
  marginRight: 0,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#000",
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const PromotionsPage = () => {
  const [article, setArticle] = React.useState<Article | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [value, setValue] = React.useState(0);
  const defaultImage = "./no-image.png";

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Add article to basket
  const addToBasket = (
    article: Article,
    newStateBasketNotification: SnackbarOrigin
  ) => {
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);
  };

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  const handleChange = (event: any) => {
    // Check if the input value is greater than the maximum number
    if (event.target.value !== "" && parseInt(event.target.value) > 100) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(200);
    } else if (parseInt(event.target.value) < 1) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(1);
    } else {
      // Otherwise, update the value normally
      setValue(event.target.value);
    }
  };

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClick =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
    () => {
      setState({
        open: true,
        Transition,
      });
    };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ClientSideMenu />
            <Box sx={{ width: "100%", border: "4px solid #f1f1f1", mt: 2 }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ width: "100%", boxShadow: "none", textAlign: "left" }}
                  >
                    <MenuList sx={{ ml: 1, mr: 1 }}>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Basket pads (1)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Brake disc (11)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Stabilizer (3)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box
              sx={{
                bgcolor: "#f1f1f1",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: 1,
                borderTop: `2px solid ${theme.palette.primary.dark}`,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "5px" }}
                  >
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          sx={{ color: "black" }}
                          fontSize="small"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          ml: 1,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >
                        Home
                      </Link>
                      ,
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Promotions
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      pr: 1,
                    }}
                  >
                    <Box></Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={0}></Grid>
              <Snackbar
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                key={state.Transition.name}
                autoHideDuration={1200}
              >
                <Alert
                  onClose={handleClose}
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  {/* {article.articleNumber} copied! */}
                  22546 copied!
                </Alert>
              </Snackbar>
              <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={openNotification}
                autoHideDuration={1500}
                onClose={hideBasketNotification}
              >
                <Alert
                  onClose={hideBasketNotification}
                  severity="success"
                  sx={{
                    bgcolor: "#fff",
                    width: "100%",
                    boxShadow: "4px 5px 5px #cfcfcf",
                  }}
                >
                  <Card sx={{ display: "flex", boxShadow: "none" }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 120 }}
                      image={defaultImage}
                      alt="Live from space album cover"
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography
                          component="div"
                          variant="subtitle1"
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {/* {article.mfrName} */}
                          FEBI BILSTEIN
                          {/* {" "} */}
                          {/* {article.genericArticles[0]?.genericArticleDescription} */}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          Quantity x 2
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          Price: 27.50 EUR
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default PromotionsPage;
