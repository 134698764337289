import * as React from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Alert } from '@mui/material';
import { VehicleProductGroup } from '../../../interfaces/VehicleProductGroup';
import ChildAssemblyTree from './ChildAssemblyTree';
import Link from '@mui/material/Link';

interface AssemblyTreeProps {
  group: VehicleProductGroup[];
}

const AssemblyTree: React.FC<AssemblyTreeProps> = ({ group }) => {
  const [loading, setLoading] = React.useState(true);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
  const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null); 
  const [brandId, setBrandId] = React.useState<number | null>(null);

  React.useEffect(() => {
    // Parse URL parameters to get criteria IDs
    const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get('linkageTargetType');
      const linkageTargetId = urlParams.get('linkageTargetId');
      const brandIdParam = urlParams.get("brand");

      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }
  
      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }

      if (brandIdParam) {
        const brandIdUrlParam = parseInt(brandIdParam, 10);
        setBrandId(brandIdUrlParam);
      }

    if (group && group.length > 0) {
      setLoading(false);
    }
  }, [group]);

  const generateUniqueKey = (baseKey: string) => {
    return `${baseKey}-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <Box>
      {loading ? (
        <Alert severity="info">Loading...</Alert>
      ) : (
        <SimpleTreeView>
          {group.map((vehicleProductGroup: VehicleProductGroup, index) => {
            const baseKey = vehicleProductGroup.assemblyGroupNodeId.toString();
            const uniqueKey = generateUniqueKey(baseKey);
            const dynamicUrl = `/search-products?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}${brandId ? `&brand=${brandId}` : ''}&assemblyGroupNodeIds=${vehicleProductGroup.assemblyGroupNodeId}`;

            return (
              
              <TreeItem
                key={uniqueKey}
                itemId={uniqueKey}
                label={
                  <Link href={dynamicUrl}>
                    <span style={{ 
                      textAlign: 'left', 
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      fontSize: '13px'
                    }}>
                      {vehicleProductGroup.assemblyGroupName}
                    </span>
                  </Link>
                }
                style={{ 
                  textAlign: 'left', 
                  borderBottom: '1px solid #f1f1f1' 
                }}
              >
                {vehicleProductGroup.children ? (
                  <ChildAssemblyTree key={generateUniqueKey(`${vehicleProductGroup.assemblyGroupNodeId}-${vehicleProductGroup.parentNodeId}-${vehicleProductGroup.assemblyGroupName}`)} vehicleProductGroupId={vehicleProductGroup.assemblyGroupNodeId} />

                
                ) : (
                  <TreeItem
                    key={generateUniqueKey(`${vehicleProductGroup.assemblyGroupNodeId}-${index}-picker`)}
                    itemId={generateUniqueKey(`${vehicleProductGroup.assemblyGroupNodeId}-${index}-picker`)}
                    label={
                      <Link href={dynamicUrl}>
                    <span style={{ 
                      textAlign: 'left', 
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      fontSize: '13px'
                    }}>
                      {vehicleProductGroup.assemblyGroupName}
                    </span>
                  </Link>
                    }
                    style={{ 
                      textAlign: 'left', 
                      borderBottom: '1px solid #f1f1f1'
                   }}
                  />
                )}
              </TreeItem>
          
            );
          })}
        </SimpleTreeView>
      )}
    </Box>
  );
};

export default AssemblyTree;
