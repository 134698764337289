import { combineReducers } from "redux";
import linkingTargetReducer from "./linkingTargetReducer";
import cart from "./basket";
import priceReducer from "./priceReducer";

const rootReducer = combineReducers({
   linkingTarget: linkingTargetReducer,
   cart: cart,
   priceReducer
});

export default rootReducer;